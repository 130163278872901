import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  flex: {
    display: 'flex',
    width: '100vw',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },

    [theme.breakpoints.up('md')]: {
      paddingRight: '3rem',
    },
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',

    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },

    [theme.breakpoints.down('md')]: {
      padding: '4em',
    },
  },
}));
