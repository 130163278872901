import { Box, Button, Fade, Grid, Typography } from '@material-ui/core';
import MediaCard from 'components/common/Card';
import Emoji from 'components/common/Emoji';
import { timeouts } from 'constants/theme';
import { ANALYTICS_ACTIONS, useAnalytics, useShowIntercom } from 'core/constants';
import { getPerks } from 'http/perks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Perk } from 'store/dashboard/types';
import usePerkStyles from './Perks.styles';

const Perks: React.FC = () => {
  const [perks, setPerks] = useState<Perk[]>([]);

  const { t } = useTranslation();
  const classes = usePerkStyles();

  // const [expanded, setExpanded] = React.useState(false);

  const analytics = useAnalytics();
  const showIntercomNewMessage = useShowIntercom();

  useEffect(() => {
    (async () => {
      try {
        const perks = await getPerks();

        if (perks.length > 0) {
          setPerks(perks);
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    })();
  }, []);

  return (
    <Fade in timeout={timeouts.fade}>
      <div>
        <Grid container justify="flex-start" alignContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom component="div">
              <Typography variant="h1" display="inline">
                <Emoji variant="perks" />{' '}
              </Typography>
              <Typography variant="h1" display="inline">
                {t('pages.perks.header')}
              </Typography>
            </Typography>

            <Typography variant="h3" className={classes.heroText}>
              {t('pages.perks.details1')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3} className={classes.mediaCardGrid}>
            <MediaCard
              title="Let's make a deal"
              description={
                <>
                  <span>{t('pages.perks.offerMessage')}</span>
                  <br />
                  <Button variant="text" color="primary" onClick={() => showIntercomNewMessage('')}>
                    {t('pages.perks.offerButton')}
                  </Button>
                </>
              }
              mediaClassName={classes.media}
              mediaContainerClassName={classes.avatar}
              className={classes.mediaCardCental}
            />
          </Grid>
          {perks.length > 0 &&
            perks.map((perk: Perk) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={perk.id} className={classes.mediaCardGrid}>
                <MediaCard
                  title={perk.name || ''}
                  description={perk.description || ''}
                  media={perk.media || ''}
                  className={classes.mediaCard}
                  mediaClassName={classes.media}
                  mediaContainerClassName={classes.avatar}
                  mediaContainerStyle={{ backgroundColor: perk?.colors?.secondary }}
                  style={{ backgroundColor: perk?.colors?.primary }}
                  content={
                    <Box className={classes.bottom}>
                      <Typography variant="caption">{perk.offer}</Typography>
                      <Typography variant="h5">{perk.value}</Typography>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={() => {
                          analytics.track(ANALYTICS_ACTIONS.PERK_VISIT, {
                            $org: perk.name,
                            $url: perk.url,
                            $value: perk.offer,
                          });
                          window.open(perk.url, '_blank');
                        }}
                      >
                        {t('pages.perks.button')}
                      </Button>
                    </Box>
                  }
                />
              </Grid>
            ))}
        </Grid>
      </div>
    </Fade>
  );
};

export default Perks;
