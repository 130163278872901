import { Fade, Grid, Typography } from '@material-ui/core';
import Emoji from 'components/common/Emoji';
import {
  PasswordCard,
  PictureCard,
  UserDetails,
  UserAccountsList,
  SendEmailVerification,
} from 'components/dashboard/userProfile';
import { timeouts } from 'constants/theme';
import { User } from 'entities/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, providers } from 'store/firebase/selectors';

interface UserProfiles {
  currentUser: User;
}

const UserProfile: React.FC<UserProfiles> = () => {
  const auth = useAuth();
  const currentUserProviderData = auth.providerData;
  const currentUserData = useAuth();
  const { t } = useTranslation();

  const { emailVerified } = currentUserData;

  return (
    <Fade in timeout={timeouts.fade}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1" gutterBottom>
            <Emoji symbol="😀" label="Documents" /> {t('pages.profile.title')}
          </Typography>
          <Typography variant="h3" gutterBottom>
            {t('pages.profile.subtitle')}
          </Typography>
        </Grid>
        {!emailVerified && (
          <Grid item xs={12}>
            <SendEmailVerification />
          </Grid>
        )}
        <Grid item md={4} sm={12}>
          <PictureCard user={currentUserData} />
          <UserAccountsList user={currentUserData} currentUserProviderData={currentUserProviderData} />
        </Grid>
        <Grid item md={8} sm={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UserDetails user={currentUserData} />
            </Grid>
            {currentUserProviderData &&
              !currentUserProviderData.map((res) => res.providerId).includes(providers.google) && (
                <Grid item xs={12}>
                  <PasswordCard email={currentUserData.email} />
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  );
};

export default UserProfile;
