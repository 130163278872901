import React, { forwardRef } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { noop } from 'utils';

export enum Status {
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface UserAlertProps {
  open: boolean;
  message?: string;
  status?: Status;
  handleClose?: () => void;
}

const Alert = forwardRef((props: AlertProps, ref) => <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />);

const UserAlert: React.FC<UserAlertProps> = ({ open, status, message, handleClose }) => (
  <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert onClose={handleClose} severity={status}>
      {message}
    </Alert>
  </Snackbar>
);

UserAlert.defaultProps = {
  message: '',
  status: Status.ERROR,
  handleClose: noop,
};

export default UserAlert;
