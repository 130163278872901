import { DocumentsState, DocumentActionTypes, DocumentActions } from './types';

export const initialState: DocumentsState = {
  items: [],
};

export const documentsReducer = (state = initialState, action: DocumentActionTypes): DocumentsState => {
  switch (action.type) {
    case DocumentActions.GetDocuments:
      return {
        ...state,
        items: action.payload.map((doc: { name: string }) => {
          const name = doc.name.split('/');
          return {
            id: name[name.length - 1] + Math.random() * 10000,
            name: name[name.length - 1],
          };
        }),
      };
    case DocumentActions.AddDocuments:
      return {
        ...state,
        items: [
          ...state.items.map(({ name }) => ({
            id: name + Math.random() * 10000,
            name,
          })),
          ...action.payload,
        ],
      };
    case DocumentActions.DeleteDocument:
      return {
        ...state,
        items: state.items.filter((res: { id: string; name: string }) => res.id !== action.id),
      };
    default:
      return state;
  }
};

export default documentsReducer;
