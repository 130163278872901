import { List, ListItem, ListItemIcon, Typography, Box, Button } from '@material-ui/core';
import { Google as GoogleIcon, Mail as MailIcon } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { providers } from 'store/firebase/selectors';
import { User } from 'entities/user';
import firebase from 'firebase/app';
import useStyles from './UserProfile.styles';

const UserAccountsList: React.FC<{
  user: User;
  currentUserProviderData: firebase.UserInfo[] | null;
}> = ({ user, currentUserProviderData }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box paddingTop={2}>
      <Typography variant="caption" gutterBottom>
        {t('pages.profile.accountList.title')}
      </Typography>
      <List className={classes.providerList} disablePadding component="nav" aria-label="User accounts" dense>
        {currentUserProviderData &&
          currentUserProviderData.map((provider) => (
            <ListItem
              alignItems="flex-start"
              key={provider.providerId}
              disableGutters
              className={classes.listItem}
              button
            >
              <ListItemIcon>
                {provider.providerId === providers.google ? (
                  <Button
                    disabled
                    variant="outlined"
                    color="default"
                    startIcon={<GoogleIcon className={classes.icons} />}
                    className={classes.accountListButton}
                  >
                    {user.email}
                  </Button>
                ) : (
                  <Button
                    disabled
                    variant="outlined"
                    color="default"
                    startIcon={<MailIcon className={classes.icons} />}
                    className={classes.accountListButton}
                  >
                    {user.email}
                  </Button>
                )}
              </ListItemIcon>
            </ListItem>
          ))}
      </List>
    </Box>
  );
};

export default UserAccountsList;
