import React, { FC, SVGProps } from 'react';

import { Badge, createStyles, makeStyles, Theme } from '@material-ui/core';
import { ReactComponent as FullLogo } from 'assets/icon/fullLogo.svg';
import { ReactComponent as FullLogoWhite } from 'assets/icon/fullLogoWhite.svg';
import { useThemeMode } from 'store/user/selectors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badge: {
      color: theme.palette.secondary.main,
    },
  }),
);

const Logo: FC<SVGProps<SVGSVGElement>> = (props) => {
  const classes = useStyles();
  const mode = useThemeMode();
  const ModeLogo = mode === 'dark' ? FullLogo : FullLogoWhite;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <Badge
      badgeContent="beta"
      className={classes.badge}
      color="secondary"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <ModeLogo {...props} />
    </Badge>
  );
};

export default Logo;
