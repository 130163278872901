import { useEffect, useRef, useState } from 'react';

type Listener = () => void;

const loadScript = (id: string, src: string, onLoad?: Listener) => {
  const d = document;
  const existingScript = document.getElementById(id);
  if (!existingScript) {
    const script = d.createElement('script');
    script.id = id;
    script.type = 'text/javascript';
    script.async = true;
    script.src = src;
    script.onload = () => {
      onLoad?.();
    };
    d.getElementsByTagName('head')[0].appendChild(script);
  }
};

const unloadScript = (id: string, onUnload?: Listener) => () => {
  onUnload?.();
  const existingScript = document.getElementById(id);
  if (existingScript) {
    existingScript.remove();
  }
};

export const useScript = (id: string, src: string, onLoad?: Listener, onUnload?: Listener): void => {
  useEffect(() => {
    loadScript(id, src, onLoad);
    return unloadScript(id, onUnload);
  }, [id, src, onLoad, onUnload]);
};

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
