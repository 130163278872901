import { Box, CircularProgress } from '@material-ui/core';
import React, { FC } from 'react';

const Loader: FC = () => (
  <Box
    style={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <CircularProgress />
  </Box>
);

export default Loader;
