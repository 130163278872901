import { createSlice } from '@reduxjs/toolkit';
import { getIntegrationHistoricalBalanceReducers } from './actions/getIntegrationHistoricalBalance';
import { getIntegrationsReducers } from './actions/getIntegrations';
import { getIntegrationStatusReducers } from './actions/getIntegrationStatus';
import { getIntegrationTransactionsReducers } from './actions/getIntegrationTransactions';
import { IntegrationState } from './integration.types';

export const initialState = {
  error: null,
  status: {},
  integrations: null,
  balance_history: {},
  transactions: {},
  loading: false,
} as IntegrationState;

const integrationStore = createSlice({
  name: 'integration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getIntegrationsReducers(builder);
    getIntegrationStatusReducers(builder);
    getIntegrationTransactionsReducers(builder);
    getIntegrationHistoricalBalanceReducers(builder);
  },
});

export default integrationStore.reducer;
