import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { ResetState } from 'store/dashboard/actions';

const Logout: FC = () => {
  const dispatch = useDispatch();
  const firebase = useFirebase();
  useEffect(() => {
    firebase.logout().then(() => {
      dispatch(ResetState());
    });
  }, [dispatch, firebase]);
  return null;
};

export default Logout;
