import { GlobalState, GlobalActionTypes, SET_GLOBAL_ERROR } from './types';

export const initialState: GlobalState = {
  error: false,
};

export const globalReducer = (state = initialState, action: GlobalActionTypes): GlobalState => {
  switch (action.type) {
    case SET_GLOBAL_ERROR:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default globalReducer;
