import thunk from 'redux-thunk';
import { configureStore, EnhancedStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { actionTypes } from 'react-redux-firebase';
import rootReducer from './reducers';
import { Actions, RootState } from './types';

function configureAppStore(preloadedState?: RootState): EnhancedStore<RootState, Actions> {
  const devMiddlewares = [];

  if (process.env.NODE_ENV === `development`) {
    // eslint-disable-next-line
    const { logger } = require(`redux-logger`);

    devMiddlewares.push(logger);
  }

  const store = configureStore({
    reducer: rootReducer,
    middleware: [
      ...devMiddlewares,
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [actionTypes.LOGIN, actionTypes.LOGIN_ERROR],
        },
      }),
      thunk,
    ],
    preloadedState,
    enhancers: [],
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
}

export const store = configureAppStore();
