import { Company } from 'entities/dashboard';
import { savePipedriveUserApi } from 'http/auth';
import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from 'store/firebase/selectors';
import { pipedriveUserUpdated } from 'store/user/actions';
import { useScript } from './hook-utils';

type Intercom = typeof window.Intercom;

const onUnload = () => {
  window.Intercom?.('shutdown');
};

export const useIntercom = (appId: string, company: Company): Intercom | undefined => {
  const auth = useAuth();

  const onLoad = useCallback(() => {
    if (auth.isLoaded) {
      const userCreatedAt = auth.createdAt ? Number(auth.createdAt) / 1000 : undefined;

      const companyCreatedAt = company.createdAt ? new Date(company.createdAt as string).getTime() / 1000 : undefined;

      const { booted } = window.Intercom;
      const command = booted ? 'update' : 'boot';

      window.Intercom(command, {
        app_id: appId || '',
        user_id: auth.uid || '',
        email: auth.email || '',
        name: auth.displayName || '',
        created_at: userCreatedAt,
        phone: auth.phoneNumber || '',
        company: {
          id: company.id || '',
          name: company.registeredName || '',
          created_at: companyCreatedAt,
          website: company.website || '',
        },
      });
    }
  }, [appId, auth, company]);

  useScript('intercom-script', `https://widget.intercom.io/widget/${appId}`, onLoad, onUnload);
  return window.Intercom;
};

export const useShowIntercom = (): ((message: string) => void) => {
  const showIntercomNewMessage = useCallback((message: string) => {
    window.Intercom?.('showNewMessage', message);
  }, []);

  return showIntercomNewMessage;
};

export const usePipedriveSync = (environment: string): boolean => {
  const { displayName, email } = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    const onLoad = async () => {
      try {
        await savePipedriveUserApi({
          name: displayName || '',
          email: email || '',
        });
        dispatch(pipedriveUserUpdated());
      } catch (error) {
        // console.log('error', error);
      }
    };

    if (environment === 'production') {
      onLoad();
    }
  }, [displayName, email, dispatch, environment]);

  return true;
};

export const ANALYTICS_ACTIONS = {
  EMAIL_LOGIN: 'Email login successful',
  GOOGLE_LOGIN: 'Google login successful',
  EMAIL_LOGIN_FAIL: 'Email login fail',
  GOOGLE_LOGIN_FAIL: 'Google login fail',
  EMAIL_SIGNUP: 'Email signup successful',
  GOOGLE_SIGNUP: 'Google signup successful',
  EMAIL_SIGNUP_FAIL: 'Email signup fail',
  GOOGLE_SIGNUP_FAIL: 'Google signup fail',
  PERK_VISIT: 'Perk visited',
  GRANT_VISIT: 'Grant visited',
} as const;

export const useAnalytics = (): {
  init: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track: (name: string, props?: any) => void;
  identify: (id: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  people: { set: (props: any) => void };
} => {
  const environment = process.env.REACT_APP_ENV as string;
  const analyticsID = process.env.REACT_APP_ANALYTICS_ID as string;

  const env_check = environment === 'production';

  const actions = {
    init: () => {
      mixpanel.init(analyticsID);
    },
    identify: (id: string) => {
      if (env_check) mixpanel.identify(id);
    },
    alias: (id: string) => {
      if (env_check) mixpanel.alias(id);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    track: (name: string, props?: any) => {
      if (env_check) mixpanel.track(name, props);
    },
    people: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      set: (props: any) => {
        if (env_check) mixpanel.people.set(props);
      },
    },
  };

  return actions;
};
