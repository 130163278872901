import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    errorContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      color: theme.palette.grey[700],
      marginTop: 40,
      marginBottom: 10,
    },
    message: {
      color: theme.palette.grey[700],
    },
    teamwork: {
      marginTop: '3rem',
      width: '100%',
      maxWidth: '414px',
    },
  }),
);
