import {
  getCompany,
  getCompanyFinancialInformation,
  getOnboarding,
  patchCompany as patchCompanyApi,
  submitCompany as submitCompanyApi,
  patchApplicant as patchApplicantApi,
} from 'http/dashboard';
import { calculateBusinessMetrics } from 'services/businessMetrics';
import { AppThunk, RootState } from 'store/types';
import {
  DashboardActionTypes,
  POST_COMPANY,
  GENERATE_COMPANY_PROFILE,
  BusinessProfile,
  Company,
  RESET_STATE,
  FETCH_COMPANY_START,
  FETCH_COMPANY_SUCCESS,
  PATCH_COMPANY_START,
  PATCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
  PATCH_COMPANY_ERROR,
  SUBMIT_COMPANY_START,
  SUBMIT_COMPANY_SUCCESS,
  SUBMIT_COMPANY_ERROR,
  GET_PERKS,
  Perks,
  PATCH_APPLICANT_START,
  PATCH_APPLICANT_ERROR,
  PATCH_APPLICANT_SUCCESS,
  CompanyDirector,
  CompanyDirectorPatch,
} from './types';

export * from './types';

const fetchCompanyStart = (): DashboardActionTypes => ({
  type: FETCH_COMPANY_START,
});

const fetchCompanySuccess = (company: Partial<Company>): DashboardActionTypes => ({
  type: FETCH_COMPANY_SUCCESS,
  payload: company,
});

const fetchCompanyError = (): DashboardActionTypes => ({
  type: FETCH_COMPANY_ERROR,
});

const patchCompanyStart = (): DashboardActionTypes => ({
  type: PATCH_COMPANY_START,
});

// In the final step we want to keep patching set to true
// Might want to consider using a global loading state, rather
// than one for patching and one for submitting
const patchCompanySuccess = (
  data: Partial<Company>,
  finalStep: boolean,
  directors: CompanyDirector[] | null,
): DashboardActionTypes => ({
  type: PATCH_COMPANY_SUCCESS,
  payload: { data, patching: finalStep, directors },
});

const patchCompanyError = (): DashboardActionTypes => ({
  type: PATCH_COMPANY_ERROR,
});

export const generateCompanyProfile = (businessProfile: BusinessProfile): DashboardActionTypes => ({
  type: GENERATE_COMPANY_PROFILE,
  payload: businessProfile,
});

export const synchronizeCompany = (): AppThunk => async (dispatch) => {
  dispatch(fetchCompanyStart());
  try {
    let payload: Partial<Company> = {};
    const company = await getCompany();
    if (company) {
      payload = {
        ...company,
      };
      const financialInfo = await getCompanyFinancialInformation(new Date(company.createdAt as string));
      if (financialInfo) {
        payload = {
          ...payload,
          ...financialInfo,
        };
      }
      if (!company.active) {
        const onboarding = await getOnboarding();
        if (onboarding) {
          payload = {
            ...payload,
            ...onboarding,
          };
        }
      }
    }
    dispatch(fetchCompanySuccess(payload));

    dispatch(
      generateCompanyProfile(
        calculateBusinessMetrics(
          payload.startYear || 0,
          payload.cashBalance || 0,
          payload.yearToDateRevenue || 0,
          payload.monthlyRevenue || 0,
        ),
      ),
    );
  } catch (error) {
    dispatch(fetchCompanyError());
    throw error;
  }
};

const submitCompanyStart = () => ({
  type: SUBMIT_COMPANY_START,
});

const submitCompanySuccess = () => ({
  type: SUBMIT_COMPANY_SUCCESS,
});

const submitCompanyError = () => ({
  type: SUBMIT_COMPANY_ERROR,
});

export const submitCompany = (): AppThunk => async (dispatch, getState: () => RootState) => {
  dispatch(submitCompanyStart());
  try {
    await submitCompanyApi();
    dispatch(submitCompanySuccess());
    // We're not dispatching synchronizeCompany action because we
    // don't want to toggle the dashboard.synchronizing state - AppRouter.tsx
    const {
      dashboard: { company },
    } = getState();
    dispatch(
      generateCompanyProfile(
        calculateBusinessMetrics(
          company.startYear || 0,
          company.cashBalance || 0,
          company.yearToDateRevenue || 0,
          company.monthlyRevenue || 0,
        ),
      ),
    );
  } catch (error) {
    dispatch(submitCompanyError());
    throw error;
  }
};

export const patchCompany = (payload: Partial<Company>, finalStep = false): AppThunk => async (dispatch) => {
  dispatch(patchCompanyStart());
  try {
    const data = await patchCompanyApi(payload);
    dispatch(patchCompanySuccess(payload, finalStep, data));

    if (finalStep) {
      dispatch(submitCompany());
    }
  } catch (error) {
    dispatch(patchCompanyError());
  }
};

export const CreateCompany = (company: Company): DashboardActionTypes => ({
  type: POST_COMPANY,
  payload: company,
});

export const ResetState = (): DashboardActionTypes => ({
  type: RESET_STATE,
});

export const getCompanyPerks = (perks: Perks): DashboardActionTypes => ({
  type: GET_PERKS,
  payload: perks,
});

const patchApplicantStart = (): DashboardActionTypes => ({
  type: PATCH_APPLICANT_START,
});

const patchApplicantSuccess = (data: CompanyDirector[]): DashboardActionTypes => ({
  type: PATCH_APPLICANT_SUCCESS,
  payload: data,
});

const patchApplicantError = (): DashboardActionTypes => ({
  type: PATCH_APPLICANT_ERROR,
});

export const patchApplicant = (payload: CompanyDirectorPatch): AppThunk => async (
  dispatch,
  getState: () => RootState,
) => {
  dispatch(patchApplicantStart());
  try {
    const directors = await patchApplicantApi(payload);
    dispatch(patchApplicantSuccess(directors));
  } catch (error) {
    dispatch(patchApplicantError());
  }
};
