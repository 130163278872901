import { Address, Company as CompanyEntity, CompanyDirector as CompanyDirectorEntity } from 'entities/dashboard';

export const POST_COMPANY = 'POST_COMPANY';
export const SUBMIT_COMPANY_START = 'SUBMIT_COMPANY_START';
export const SUBMIT_COMPANY_SUCCESS = 'SUBMIT_COMPANY_SUCCESS';
export const SUBMIT_COMPANY_ERROR = 'SUBMIT_COMPANY_ERROR';
export const GENERATE_COMPANY_PROFILE = 'GENERATE_COMPANY_PROFILE';
export const RESET_STATE = 'RESET_STATE';
export const FETCH_COMPANY_START = 'FETCH_COMPANY_START';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_ERROR = 'FETCH_COMPANY_ERROR';
export const PATCH_COMPANY_START = 'PATCH_COMPANY_START';
export const PATCH_COMPANY_SUCCESS = 'PATCH_COMPANY_SUCCESS';
export const PATCH_COMPANY_ERROR = 'PATCH_COMPANY_ERROR';
export const GET_PERKS = 'GET_PERKS';
export const PATCH_APPLICANT_START = 'PATCH_APPLICANT_START';
export const PATCH_APPLICANT_SUCCESS = 'PATCH_APPLICANT_SUCCESS';
export const PATCH_APPLICANT_ERROR = 'PATCH_APPLICANT_ERROR';

interface CompanyAction {
  type: typeof POST_COMPANY;
  payload: Company;
}
interface SubmitCompanyStartAction {
  type: typeof SUBMIT_COMPANY_START;
}

interface SubmitCompanySuccessAction {
  type: typeof SUBMIT_COMPANY_SUCCESS;
}

interface SubmitCompanyErrorAction {
  type: typeof SUBMIT_COMPANY_ERROR;
  payload?: string;
}

interface ResetStateAction {
  type: typeof RESET_STATE;
}

interface CompanyProfileGenerateAction {
  type: typeof GENERATE_COMPANY_PROFILE;
  payload: BusinessProfile;
}

interface FetchCompanyStartAction {
  type: typeof FETCH_COMPANY_START;
}

interface FetchCompanySuccessAction {
  type: typeof FETCH_COMPANY_SUCCESS;
  payload: Partial<Company>;
}

interface FetchCompanyErrorAction {
  type: typeof FETCH_COMPANY_ERROR;
  payload?: Company;
}

interface PatchCompanyStartAction {
  type: typeof PATCH_COMPANY_START;
}

interface PatchCompanyErrorAction {
  type: typeof PATCH_COMPANY_ERROR;
  payload?: string;
}

interface PatchCompanySuccessAction {
  type: typeof PATCH_COMPANY_SUCCESS;
  payload: { data: Partial<Company>; patching: boolean; directors: CompanyDirector[] | null };
}

interface GetCompanyPerks {
  type: typeof GET_PERKS;
  payload: Perks;
}

interface PatchApplicantStartAction {
  type: typeof PATCH_APPLICANT_START;
}

interface PatchApplicantSuccessAction {
  type: typeof PATCH_APPLICANT_SUCCESS;
  payload: CompanyDirector[];
}

interface PatchApplicantErrorAction {
  type: typeof PATCH_APPLICANT_ERROR;
  payload?: string;
}

export type DashboardActionTypes =
  | CompanyAction
  | CompanyProfileGenerateAction
  | SubmitCompanyStartAction
  | SubmitCompanySuccessAction
  | SubmitCompanyErrorAction
  | ResetStateAction
  | FetchCompanyStartAction
  | FetchCompanySuccessAction
  | FetchCompanyErrorAction
  | PatchCompanyStartAction
  | PatchCompanySuccessAction
  | PatchCompanyErrorAction
  | GetCompanyPerks
  | PatchApplicantStartAction
  | PatchApplicantSuccessAction
  | PatchApplicantErrorAction;

export interface BusinessProfile {
  businessValuation: number;
  businessCreditLimit: number;
  businessCreditScore: number;
}

export type Company = CompanyEntity;

export type CompanyDirector = CompanyDirectorEntity;

export interface CompanyDirectorPatch extends Partial<Omit<CompanyDirector, 'residentialAddress'>> {
  residentialAddress: Partial<Address>[];
}

export interface DashboardState {
  onBoardingComplete: boolean;
  businessProfile: BusinessProfile;
  company: Company;
  synchronizing?: boolean;
  patching: boolean;
  submitting: boolean;
  error: string | boolean;
}

export interface Perk {
  id?: number;
  name?: string;
  url?: string;
  description?: string;
  valid?: boolean;
  media?: string;
  category?: string;
  offer?: string;
  value?: string;
  colors?: {
    primary: string;
    secondary: string;
  };
}

export type Perks = [Perk];

export interface OnboardLoadingErrorStateHookReturn {
  loading: boolean;
  error: string | boolean;
  patchCompany: (payload: Partial<Company>, finalStep?: boolean) => void;
  patchApplicant: (payload: CompanyDirectorPatch) => void;
}

export interface CompanySearchResult {
  title: string;
  companyNumber: string;
  incorporatedOn: string;
  companyAddress: Address | null;
}
