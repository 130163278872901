import { CompanyDirector } from 'store/dashboard/types';

export const GET_ADMIN_COMPANIES_START = 'GET_ADMIN_COMPANIES_START';
export const GET_ADMIN_COMPANIES_SUCCESS = 'GET_ADMIN_COMPANIES_SUCCESS';
export const GET_ADMIN_COMPANIES_ERROR = 'GET_ADMIN_COMPANIES_ERROR';

export const GET_ADMIN_COMPANY_ACTION = 'GET_ADMIN_COMPANY_ACTION';
export const GET_ADMIN_COMPANY_SUCCESS = 'GET_ADMIN_COMPANY_SUCCESS';
export const GET_ADMIN_COMPANY_ERROR = 'GET_ADMIN_COMPANY_ERROR';

// export const GET_ADMIN_COMPANY_FINANCIAL_START =
//   'GET_ADMIN_COMPANY_FINANCIAL_START';
export const GET_ADMIN_COMPANY_FINANCIAL_SUCCESS = 'GET_ADMIN_COMPANY_FINANCIAL_SUCCESS';
// export const GET_ADMIN_COMPANY_FINANCIAL_ERROR =
//   'GET_ADMIN_COMPANY_FINANCIAL_ERROR';

export const GET_ADMIN_COMPANY_DOCUMENTS_START = 'GET_ADMIN_COMPANY_DOCUMENTS_START';
export const GET_ADMIN_COMPANY_DOCUMENTS_SUCCESS = 'GET_ADMIN_COMPANY_DOCUMENTS_SUCCESS';
export const GET_ADMIN_COMPANY_DOCUMENTS_ERROR = 'GET_ADMIN_COMPANY_DOCUMENTS_ERROR';

export const GET_ADMIN_COMPANY_DOCUMENT_START = 'GET_ADMIN_COMPANY_DOCUMENT_START';
export const GET_ADMIN_COMPANY_DOCUMENT_SUCCESS = 'GET_ADMIN_COMPANY_DOCUMENT_SUCCESS';
export const GET_ADMIN_COMPANY_DOCUMENT_ERROR = 'GET_ADMIN_COMPANY_DOCUMENT_ERROR';

interface GetAdminCompaniesStartAction {
  type: typeof GET_ADMIN_COMPANIES_START;
}

interface GetAdminCompaniesSuccessAction {
  type: typeof GET_ADMIN_COMPANIES_SUCCESS;
  payload: AdminCompanies;
}

interface GetAdminCompaniesErrorAction {
  type: typeof GET_ADMIN_COMPANIES_ERROR;
}

interface GetAdminCompanyStartAction {
  type: typeof GET_ADMIN_COMPANY_ACTION;
}

interface GetAdminCompanySuccessAction {
  type: typeof GET_ADMIN_COMPANY_SUCCESS;
  payload: AdminCompany;
}

interface GetAdminCompanyErrorAction {
  type: typeof GET_ADMIN_COMPANY_ERROR;
}

// interface GetAdminCompanyFinancialStartAction {
//   type: typeof GET_ADMIN_COMPANY_FINANCIAL_START;
// }

interface GetAdminCompanyFinancialSuccessAction {
  type: typeof GET_ADMIN_COMPANY_FINANCIAL_SUCCESS;
  payload: AdminCompany;
}

// interface GetAdminCompanyFinancialErrorAction {
//   type: typeof GET_ADMIN_COMPANY_FINANCIAL_ERROR;
// }

interface GetAdminDocumentsStartAction {
  type: typeof GET_ADMIN_COMPANY_DOCUMENTS_START;
}

interface GetAdminDocumentsSuccessAction {
  type: typeof GET_ADMIN_COMPANY_DOCUMENTS_SUCCESS;
  payload: [Document];
}

interface GetAdminDocumentsErrorAction {
  type: typeof GET_ADMIN_COMPANY_DOCUMENTS_ERROR;
}

interface GetAdminDocumentStartAction {
  type: typeof GET_ADMIN_COMPANY_DOCUMENT_START;
}

interface GetAdminDocumentSuccessAction {
  type: typeof GET_ADMIN_COMPANY_DOCUMENT_SUCCESS;
}

interface GetAdminDocumentErrorAction {
  type: typeof GET_ADMIN_COMPANY_DOCUMENT_ERROR;
}

export type AdminActionTypes =
  | GetAdminCompaniesStartAction
  | GetAdminCompaniesSuccessAction
  | GetAdminCompaniesErrorAction
  | GetAdminCompanyStartAction
  | GetAdminCompanySuccessAction
  | GetAdminCompanyErrorAction
  // | GetAdminCompanyFinancialStartAction
  | GetAdminCompanyFinancialSuccessAction
  // | GetAdminCompanyFinancialErrorAction
  | GetAdminDocumentsStartAction
  | GetAdminDocumentsSuccessAction
  | GetAdminDocumentsErrorAction
  | GetAdminDocumentStartAction
  | GetAdminDocumentSuccessAction
  | GetAdminDocumentErrorAction;

export interface BusinessProfile {
  businessValuation: number;
  businessCreditLimit: number;
  businessCreditScore: number;
}

export enum AdminColumnNames {
  active = 'active',
  registeredName = 'registeredName',
  lastYearRevenue = 'lastYearRevenue',
  fundingTime = 'fundingTime',
  fundingAmount = 'fundingAmount',
  website = 'website',
  lastUpdated = 'cmpny_updated_at',
  id = 'id',
}
export interface AdminCompany {
  id: string | null;
  createdAt: string | null;
  ownerId: string | null;
  businessType: string | null;
  incorporatedRegion: string | null;
  tradingName: string | null;
  registeredName: string | null;
  registeredNumber: string | null;
  website: string | null;
  incorporatedOn: Date | string | null;
  startYear: Date | number | null;
  onboardingStep: number;
  active: boolean;
  yearToDateRevenue: number | null;
  monthlyRevenue: number | null;
  fundingAmount: number | null;
  fundingTime: string | null;
  fundingReasons: string[] | null;
  fundingUnpaidInvoices: false;
  fundingOwedInvoices: number | null;
  lastYearRevenue: number | null;
  grossMarginPercentage: number | null;
  recurringRevenuePercentage: number | null;
  businessCustomerRevenuePercentage: number | null;
  cashBalance: number | null;
  debitBalance: number | null;
  inventoryBalance: number | null;
  receivableBalance: number | null;
  debtBalance: number | null;
  repeatCustomersRevenuePercentage: number | null;
  shopifySalesPercentage: number | null;
  amazonSalesPercentage: number | null;
  ebaySalesPercentage: number | null;
  wooCommerceSalesPercentage: number | null;
  offlineSalesPercentage: number | null;
  otherSalesPercentage: number | null;
  directors: CompanyDirector[] | null;
}

export interface AdminCompanies {
  count: number;
  limit: number;
  offset: number;
  rows: [AdminCompany];
}

export interface AdminState {
  loading: boolean;
  companies: AdminCompanies;
  company: {
    details: AdminCompany;
    documents?: [Document];
    loading: boolean;
  };
}

export interface FetchDocumentsResponse {
  name: string;
  id: string;
  contentType: string;
  prefix: string;
}

export interface UploadDocumentResponse {
  name: string;
}

export interface DeleteDocumentResponse {
  name: string;
}
