import { Button } from '@material-ui/core';
import { CalendarTodayTwoTone as CalendarIcon } from '@material-ui/icons';
import { CalendlyPopupProps } from 'entities/calendly';
import React from 'react';
import { openPopupWidget, CalendlyEventListener as CalendlyEventListenerCalendly } from 'react-calendly';

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noRefCheck() {}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const openCalendly = (openCalendlySettings: CalendlyPopupProps) =>
  openPopupWidget({
    ...openCalendlySettings,
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CalendlyEventListener = () => (
  <CalendlyEventListenerCalendly
    onDateAndTimeSelected={noRefCheck}
    onEventScheduled={noRefCheck}
    onEventTypeViewed={noRefCheck}
    onProfilePageViewed={noRefCheck}
  />
);

const CalendlyPopup: React.FC<CalendlyPopupProps> = ({ url, prefill, pageSettings, utm }) => (
  <Button
    onClick={() => openPopupWidget({ url, prefill, pageSettings, utm })}
    variant="contained"
    color="default"
    startIcon={<CalendarIcon />}
  >
    Book some time in
  </Button>
);

export default CalendlyPopup;
