import { CompanyDirector, Company, CompanySearchResult, CompanyDirectorPatch } from 'store/dashboard/types';
import { accept20x, accept4xx } from 'http/utils';
import { http } from '../index';

const SERVER_URL = process.env.REACT_APP_DOMAIN;

export const getCompany = async (): Promise<Partial<Company> | false> => {
  const { status, data } = await http.get(`${SERVER_URL}/company`);
  if (accept20x(status) && data) {
    return data;
  }
  return false;
};

export const getCompanyFinancialInformation = async (effectiveDate: Date): Promise<Partial<Company> | false> => {
  const { status, data } = await http.get(
    `${SERVER_URL}/company-financial-information?effectiveDate=${effectiveDate.getTime()}`,
    {
      validateStatus: accept4xx,
    },
  );
  if (accept20x(status) && data) {
    return data;
  }
  return false;
};

export const postCompany = async (): Promise<Company> => {
  const { status, data } = await http.post(`${SERVER_URL}/company`);
  if (accept20x(status) && data) {
    return data;
  }
  throw new Error();
};

export const patchCompany = async (onboarding: Partial<Company>): Promise<CompanyDirector[] | null> => {
  const { status, data } = await http.patch(`${SERVER_URL}/onboarding`, onboarding);
  if (accept20x(status)) {
    return data || null;
  }
  throw new Error();
};

export const submitCompany = async (): Promise<number> => {
  const { status } = await http.post(`${SERVER_URL}/onboarding/submit`);
  if (accept20x(status)) {
    return status;
  }
  throw new Error();
};

export const getOnboarding = async (): Promise<Partial<Company>> => {
  const { status, data } = await http.get(`${SERVER_URL}/onboarding`, {
    validateStatus: accept4xx,
  });
  if (accept20x(status) && data) {
    return data;
  }
  throw new Error();
};

export const patchApplicant = async (applicant: CompanyDirectorPatch): Promise<CompanyDirector[]> => {
  const { status, data } = await http.patch(`${SERVER_URL}/onboarding/applicant`, applicant);
  if (accept20x(status)) {
    return data;
  }
  throw new Error();
};

export const searchCompanyName = async (searchTerm: string): Promise<CompanySearchResult[]> => {
  const { status, data } = await http.get(`${SERVER_URL}/onboarding/searchCompanyName`, { params: { searchTerm } });
  if (accept20x(status)) {
    return data;
  }
  throw new Error();
};
