import { Avatar, Box, Card, CardContent, CircularProgress, Typography, CardActions } from '@material-ui/core';
import UserAlert, { Status, UserAlertProps } from 'components/common/UserAlert';
import { User as UserEntity } from 'entities/user';
import React, { useCallback, useState } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useFirebase } from 'react-redux-firebase';
import { useProfile } from 'store/firebase/selectors';
import LoadingButton from 'components/common/LoadingButton';
import useStyles from './UserProfile.styles';

interface User extends UserEntity {
  uid: string;
}
const allowedFileTypes = [
  {
    mimeType: 'image/png',
    name: 'PNG Image',
    extension: '.png',
  },
  {
    mimeType: 'image/jpeg',
    name: 'JPEG Image',
    extension: '.jpg',
  },
];

const PictureCard: React.FC<{ user: User }> = ({ user }) => {
  const { photoURL, uid } = user;
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = React.useState<UserAlertProps>({
    open: false,
    message: '',
    status: Status.ERROR,
  });
  const { open, message, status } = openAlert;
  const maxFileSize = 2000000;
  const classes = useStyles();
  const firebase = useFirebase();
  const profile = useProfile();
  const { t } = useTranslation();
  const handleClose = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert({ open: false });
  };
  const userPhoto = photoURL || '';
  const onDrop = useCallback(
    (file: File[], fileRejections: FileRejection[]) => {
      setLoading(true);
      const imageAsFile = file[0];
      if (fileRejections.length > 0 || imageAsFile.size > maxFileSize) {
        setOpenAlert({
          open: true,
          message: `${t('pages.profile.photo.validation.rejected')}`,
          status: Status.ERROR,
        });
      } else {
        const uploadTask = firebase.storage().ref(`/images/${uid}`).put(imageAsFile);
        // initiates the firebase side uploading
        uploadTask.on(
          'state_changed',
          () => {
            // console.log(snapShot);
          },
          (err) => {
            setOpenAlert({
              open: true,
              message: `${err.message}`,
              status: Status.ERROR,
            });
          },
          () => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            uid &&
              firebase
                .storage()
                .ref('images')
                .child(uid)
                .getDownloadURL()
                .then((fireBaseUrl) => {
                  firebase.updateProfile({ ...profile, photoURL: fireBaseUrl });
                  setLoading(false);
                });
          },
        );
      }
    },
    [firebase, profile, t, uid],
  );

  return (
    <>
      <Card className={classes.root} elevation={0}>
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="flex-start" textAlign="left">
            <Typography variant="h5" gutterBottom>
              {t('pages.profile.photo.title')}
            </Typography>

            <Dropzone
              onDrop={(acceptedFiles, fileRejections) => onDrop(acceptedFiles, fileRejections)}
              accept={allowedFileTypes.map((fileType) => fileType.mimeType)}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={classes.avatarContainer}>
                  <input {...getInputProps()} />
                  <Avatar
                    className={classes.avatar}
                    src={`${!loading && (profile.photoURL || userPhoto)}`}
                    component="a"
                  >
                    <>{loading && <CircularProgress size={20} />}</>
                  </Avatar>
                </div>
              )}
            </Dropzone>

            <CardActions disableSpacing>
              <Dropzone
                onDrop={(acceptedFiles, fileRejections) => onDrop(acceptedFiles, fileRejections)}
                accept={allowedFileTypes.map((fileType) => fileType.mimeType)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <LoadingButton loading={loading} color="default" fullWidth variant="outlined">
                        {t('pages.profile.photo.button')}
                      </LoadingButton>
                    </div>
                  </section>
                )}
              </Dropzone>
            </CardActions>
          </Box>
        </CardContent>
      </Card>
      <UserAlert open={open} status={status} message={message} handleClose={handleClose} />
    </>
  );
};

export default PictureCard;
