import moment from 'moment';

/**
 * Patches items in a collection.
 * @param collection to find the to-be patched items
 * @param patch to apply to items found
 * @param predicate to match the to-be patched items
 */
export const patchItems = <T>(
  collection: T[],
  patch: Partial<T> | ((item: T) => Partial<T>),
  predicate?: (item: T) => boolean,
): T[] =>
  collection.map((item) =>
    !predicate || predicate(item)
      ? {
          ...item,
          ...(typeof patch === 'function' ? patch(item) : patch),
        }
      : item,
  );

/**
 * No operation function
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = (): void => {};

/**
 *
 * @param timeMs delay in miliseconds
 */
export const waitFor = (timeMs: number): Promise<void> =>
  new Promise<void>((resolve) => setTimeout(() => resolve(), timeMs));

/**
 *
 * @returns timeDiff
 */
export const timeDiffCalc = (date1: string | Date, date2: string | Date, diff: string): number => {
  const d1 = typeof date1 === 'string' ? moment(date1) : moment(date1.toISOString());
  const d2 = typeof date2 === 'string' ? moment(date2) : moment(date2.toISOString());
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return d1.diff(d2, diff);
};
