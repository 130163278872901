import { Avatar, Card, Divider } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import User from '@material-ui/icons/AccountCircle';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 350,
    background: theme.palette.background.light,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  flexDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 0 5px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  bold: {
    color: theme.palette.text.secondary,
  },
  divider: {
    border: `1px solid ${theme.palette.text.primary}`,
  },
}));

const CompanyCard: React.FC<{
  name: string | null;
  img: string | null;
  valuation: string;
  creditLimit: string;
  businessScore: string;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { name, img, valuation, creditLimit, businessScore } = props;

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <div className={classes.flexDiv}>
          <Typography variant="h6" className={classes.bold}>
            {name}
          </Typography>
          {img && <Avatar alt={name || ''} src={img || ''} />}
          {!img && <User />}
        </div>

        <Divider className={classes.divider} />
        <div className={classes.flexDiv}>
          <Typography variant="body1" className={classes.bold}>
            {t('general.titles.valuation')}
          </Typography>
          <Typography>{valuation}</Typography>
        </div>
        <div className={classes.flexDiv}>
          <Typography variant="body1" className={classes.bold}>
            {t('general.titles.creditLimit')}
          </Typography>
          <Typography>{creditLimit}</Typography>
        </div>
        <div className={classes.flexDiv}>
          <Typography variant="body1" className={classes.bold}>
            {t('general.titles.creditScore')}
          </Typography>
          <Typography>{businessScore}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};
export default CompanyCard;
