import { http } from '../index';
import * as formatters from './formatters';

const SERVER_URL = process.env.REACT_APP_DOMAIN;

type Email = {
  email: string;
};

type Password = {
  password: string;
};

type PipedriveUser = {
  email: string;
  name: string;
};

export const login = (credentials: Email & Password): ReturnType<typeof http.post> =>
  http.post('/api/v1/auth/login', credentials).then(formatters.login);

export const savePipedriveUserApi = async (
  user: PipedriveUser,
): Promise<{ status: boolean; message: string } | boolean> => {
  const { data } = await http.post(`${SERVER_URL}/pipedrive`, user);
  if (data) {
    return data;
  }
  return false;
};

export default login;
