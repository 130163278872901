import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
  },
  divider: {
    backgroundColor: theme.palette.text.secondary,
  },
  button: {},
  providerList: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    margin: 3,
    width: 20,
    display: 'flex',
  },
  avatarContainer: {
    margin: '10px 0 10px',

    outline: 'none',

    '&:focus': {
      outline: 'none',
    },
  },
  avatar: {
    height: 150,
    width: 150,
    border: '1px solid #e2e2ea',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  icons: {
    color: theme.palette.text.primary,
  },
  accountListButton: {
    border: '1px solid #e2e2ea',
    margin: 0,
  },
}));
