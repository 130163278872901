import { combineReducers } from '@reduxjs/toolkit';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import { adminReducer } from './admin/reducers';
import { dashboardReducer } from './dashboard/reducers';
import { documentsReducer } from './documents/reducers';
import { globalReducer } from './global';
import { integrationReducer } from './integration';
import lendersReducer from './lenders/reducers';
import { userReducer } from './user/reducers';

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  user: userReducer,
  dashboard: dashboardReducer,
  lenders: lendersReducer,
  admin: adminReducer,
  documents: documentsReducer,
  integration: integrationReducer,
  global: globalReducer,
});

export default rootReducer;
