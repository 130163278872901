const allowedFileTypes = [
  {
    mimeType: 'application/msword',
    name: 'Microsoft Word',
    extension: '.doc',
  },
  {
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessing',
    name: 'Microsoft Word (OpenXML)',
    extension: '.docx',
  },
  {
    mimeType: 'image/png',
    name: 'PNG Image',
    extension: '.png',
  },
  {
    mimeType: 'image/jpeg',
    name: 'JPEG Image',
    extension: '.jpg',
  },
  {
    mimeType: 'text/csv',
    name: 'Comma-separated values (CSV)',
    extension: '.csv',
  },
  {
    mimeType: 'application/pdf',
    name: 'Adobe Portable Document Format (PDF)',
    extension: ' .pdf',
  },
];
export default allowedFileTypes;
