import { Badge, BadgeProps } from '@material-ui/core';
import React from 'react';
import useStyles from './StyledBadge.style';

const StyledBadge: React.FC<BadgeProps> = (props) => {
  const classes = useStyles();

  return <Badge classes={classes} {...props} />;
};

export default StyledBadge;
