import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 250,
      [theme.breakpoints.down('sm')]: {
        width: 155,
      },
    },
    input: {
      width: 42,
    },
    slider: {
      [theme.breakpoints.up('sm')]: {
        marginTop: '8px',
      },
    },
  }),
);
