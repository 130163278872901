import React, { FC } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import LoadingButton from 'components/common/LoadingButton';
import { useTranslation } from 'react-i18next';

import useOnboardingStyles from './form.styles';

interface SubmitButtonsProp {
  onClickSecondary?: () => void;
  loading: boolean;
  primaryButtonTitle?: string;
}

const SubmitButtons: FC<SubmitButtonsProp> = ({ onClickSecondary, loading, primaryButtonTitle }) => {
  const classes = useOnboardingStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.btnDiv}>
      <Grid container spacing={3}>
        {!!onClickSecondary && (
          <Grid item xs={12} md={6}>
            <Button variant="outlined" color="default" onClick={onClickSecondary} className={classes.btn_sec} fullWidth>
              {t('pages.onBoarding.backButton')}
            </Button>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <LoadingButton
            loading={loading}
            className={classes.btn_pri}
            color="primary"
            fullWidth
            variant="contained"
            type="submit"
          >
            {primaryButtonTitle ? primaryButtonTitle : t('pages.onBoarding.forwardButton')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SubmitButtons;
