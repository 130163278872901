import { useSelector } from 'react-redux';

import { RootState } from 'store/types';
import { LenderCategory } from 'entities/lender';
import { Provider } from 'entities/provider';
import { HttpError } from 'http/error';

import { LendersState } from './types';

export const useLendersState = (): LendersState => useSelector((state: RootState) => state.lenders);

export const useLenderCategories = (): LenderCategory[] => useLendersState().lenderCategories;

export const useProviders = (): Provider[] => useLendersState().providers;

export const useLendersBusy = (): boolean => useLendersState().busy;

export const useLendersError = (): HttpError | undefined => useLendersState().error;

export const useSelectedLenderCategory = (): LenderCategory | undefined => useLendersState().selectedLenderCategory;

export const useApplyingToLenderCategory = (): boolean => useLendersState().applying;
