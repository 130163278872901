import Modal from 'components/common/Modal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import routes from 'router/routes';
import { useAuth } from 'store/firebase/selectors';

interface VerifyModalState {
  open: boolean;
  title?: string;
  description?: string;
}

const EmailVerification: React.FC<{ code: string }> = ({ code }) => {
  const { t } = useTranslation();
  const firebase = useFirebase();
  const history = useHistory();
  const { emailVerified } = useAuth();
  const [verifyModalState, setVerifyModalState] = useState<VerifyModalState>({
    open: false,
    title: '',
    description: '',
  });
  const { open: verifyModalOpen, title, description } = verifyModalState;

  const handleModalClose = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setVerifyModalState({ open: false });
      history.push(`/${routes.home}`);
    },
    [history],
  );

  useEffect(() => {
    if (code && !emailVerified) {
      (async () => {
        try {
          await firebase.auth().applyActionCode(code);
          setVerifyModalState({
            open: true,
            title: t('pages.verification.email.title'),
            description: t('pages.verification.email.description'),
          });
        } catch (error) {
          setVerifyModalState({
            open: true,
            title: t('pages.verification.email.errorTitle'),
            description: t('pages.verification.email.error'),
          });
        }
      })();
    }

    if (emailVerified) {
      setVerifyModalState({
        open: true,
        title: t('pages.verification.email.alreadyTitle'),
        description: t('pages.verification.email.alreadyDescription'),
      });
    }
  }, [code, firebase, t, emailVerified]);

  return (
    <Modal
      open={verifyModalOpen}
      handleClose={handleModalClose}
      title={title || ''}
      description={description || ''}
      actionButton={t('general.titles.continue')}
      onClickPrimary={() => {
        handleModalClose();
      }}
    />
  );
};

export default EmailVerification;
