import { Box, Button, Divider, Grid, InputAdornment, Link, Snackbar, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import {
  Google as GoogleIcon,
  Person as PersonIcon,
  Lock as LockIcon,
  MailOutline as MailOutlineIcon,
} from '@material-ui/icons';
import { Field, Form, Formik } from 'formik';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import FormikTextField from 'components/common/FormikTextField';
import LoginBase, { useLoginBaseStyles } from 'components/login-base';
import { useRegisterAuthErrorMessage } from 'store/firebase/selectors';
import { useAnalytics, ANALYTICS_ACTIONS } from 'core/constants';
import useRegisterStyles from './Register.styles';

type RegisterForm = {
  fullName: string;
  email: string;
  password: string;
};

const Register: FC = () => {
  const baseClasses = useLoginBaseStyles();
  const classes = useRegisterStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const firebase = useFirebase();
  const errorMessage = useRegisterAuthErrorMessage();

  const analytics = useAnalytics();

  const goLogin = () => {
    history.push('/login');
  };

  const registerWithGoogle = () =>
    firebase
      .login({
        provider: 'google',
        type: 'popup',
      })
      .then(() => {
        analytics.track(ANALYTICS_ACTIONS.GOOGLE_SIGNUP);
      })
      .catch(() => {
        // console.log(t('general.validation.error'));
        analytics.track(ANALYTICS_ACTIONS.GOOGLE_SIGNUP_FAIL);
      });

  const register = async ({ fullName, email, password }: RegisterForm) => {
    await firebase
      .createUser({
        email,
        password,
      })
      .then(() => {
        analytics.track(ANALYTICS_ACTIONS.EMAIL_SIGNUP);
      })
      .catch(() => {
        // console.log(t('general.validation.error'));
        analytics.track(ANALYTICS_ACTIONS.EMAIL_SIGNUP_FAIL);
      });
    await firebase.auth().currentUser?.updateProfile({
      displayName: fullName,
    });
    await firebase.auth().currentUser?.sendEmailVerification();
  };

  const loginButton = (
    <Button variant="contained" className={baseClasses.leftPanelButton} disableElevation onClick={goLogin}>
      <Trans i18nKey="pages.register.loginButton" />
    </Button>
  );

  const schema = yup.object({
    fullName: yup
      .string()
      .required(t('pages.register.validation.fullName.required'))
      .min(4, t('pages.register.validation.fullName.length')),
    email: yup
      .string()
      .required(t('pages.register.validation.email.required'))
      .email(t('pages.register.validation.email.format')),
    password: yup
      .string()
      .required(t('pages.register.validation.password.required'))
      .matches(/^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/, t('pages.register.validation.password.pattern')),
    passwordConfirm: yup
      .string()
      .required(t('pages.register.validation.passwordConfirm.required'))
      .oneOf([yup.ref('password')], t('pages.register.validation.passwordConfirm.match')),
  });

  return (
    <LoginBase leftPanelButton={loginButton} i18nBaseKey="pages.register">
      <Formik
        initialValues={{
          fullName: '',
          email: '',
          password: '',
          passwordConfirm: '',
        }}
        onSubmit={register}
        validationSchema={schema}
      >
        {({ handleChange, values }) => (
          <Form>
            <Grid container item direction="column" spacing={5}>
              <Grid item>
                <Field
                  name="fullName"
                  component={FormikTextField}
                  label={t('pages.register.fullNameLabel')}
                  value={values.fullName}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <Field
                  name="email"
                  type="email"
                  component={FormikTextField}
                  value={values.email}
                  label={t('pages.register.emailLabel')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <Field
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  component={FormikTextField}
                  label={t('pages.register.passwordLabel')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <Field
                  name="passwordConfirm"
                  type="password"
                  value={values.passwordConfirm}
                  onChange={handleChange}
                  component={FormikTextField}
                  label={t('pages.register.passwordConfirmLabel')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  disableElevation
                  variant="contained"
                  color="primary"
                  className={baseClasses.mainButton}
                >
                  <Trans i18nKey="pages.register.registerButton" />
                </Button>
              </Grid>

              <Grid item className={classes.divider}>
                <Divider />
                <Typography className={classes.instantLogin} variant="overline">
                  <Trans i18nKey="pages.register.instantRegister" />
                </Typography>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={firebase.logout}>
        <Alert elevation={6} variant="filled" onClose={firebase.logout} severity="warning">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Grid container item direction="column" spacing={3}>
        <Grid item>
          <Button
            fullWidth
            disableElevation
            variant="contained"
            color="secondary"
            startIcon={<GoogleIcon />}
            onClick={registerWithGoogle}
            className={baseClasses.googleButton}
          >
            <Trans i18nKey="pages.login.googleButton" />
          </Button>
        </Grid>
        <Grid item>
          <Box display="flex" justifyContent="center">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link href="#" align="center" onClick={goLogin}>
              <Trans i18nKey="pages.register.mobile.loginLink" />
            </Link>
          </Box>
        </Grid>
      </Grid>
    </LoginBase>
  );
};

export default Register;
