import { Backdrop, Button, Grid, InputAdornment, Modal, TextField, Typography } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import clsx from 'clsx';
import UserAlert, { Status, UserAlertProps } from 'components/common/UserAlert';
import { useLoginBaseStyles } from 'components/login-base';
import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFirebase } from 'react-redux-firebase';
import * as yup from 'yup';
import useResetStyles from './ResetModal.styles';

interface ResetModalProps {
  open: boolean;
  onClose: () => void;
}

const ResetModal: FC<ResetModalProps> = ({ open, onClose }) => {
  const classes = useResetStyles();
  const { t } = useTranslation();
  const baseClasses = useLoginBaseStyles();
  const firebase = useFirebase();

  const [alertState, setAlertState] = React.useState<UserAlertProps>({
    open: false,
    message: '',
    status: Status.ERROR,
  });
  const [messageSentTo, setMessageSentTo] = React.useState('');

  const { open: visible, message, status } = alertState;

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertState({ open: false });
  };

  const resetSchema = yup.object({
    email: yup
      .string()
      .required(t('pages.login.validation.email.required'))
      .email(t('pages.login.validation.email.format')),
  });

  const initialValues = { email: '' };

  const onSubmit = async ({ email }: typeof initialValues) => {
    try {
      await firebase.resetPassword(email);
      // setAlertState({
      //   open: true,
      //   message: t('pages.login.forgotPassword.success'),
      //   status: Status.SUCCESS,
      // });
      setMessageSentTo(email);
    } catch (error) {
      setAlertState({
        open: true,
        message: error.message || t('general.validation.error'),
        status: Status.ERROR,
      });
    }
  };

  const onCloseModal = () => {
    setMessageSentTo('');
    onClose();
  };

  return (
    <>
      <Modal
        className={classes.modal}
        open={open}
        onClose={onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {messageSentTo ? (
          <div className={clsx([classes.modalContent, classes.sentContent])}>
            <Typography variant="h6" className={classes.sentHeader}>
              <Trans i18nKey="pages.login.messageSent.header" />
            </Typography>

            <Typography className={classes.sentMessage}>
              <Trans i18nKey="pages.login.messageSent.message1" /> {messageSentTo}.
            </Typography>

            <Typography className={classes.sentMessage}>
              <Trans i18nKey="pages.login.messageSent.message2" />
            </Typography>

            <Button
              disableElevation
              variant="contained"
              color="primary"
              className={clsx([baseClasses.mainButton, classes.continueButton])}
              onClick={onCloseModal}
            >
              <Trans i18nKey="pages.login.messageSent.button" />
            </Button>
          </div>
        ) : (
          <div className={classes.modalContent}>
            <Typography variant="h6">
              <Trans i18nKey="pages.login.forgotPassword.header" />
            </Typography>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={resetSchema}>
              {({ handleChange, values, errors, touched }) => (
                <Form noValidate>
                  <Grid container item direction="column" spacing={4}>
                    <Grid item>
                      <TextField
                        fullWidth
                        name="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        label={t('pages.login.emailLabel')}
                        error={touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <MailOutlineIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        fullWidth
                        disableElevation
                        variant="contained"
                        color="primary"
                        className={baseClasses.mainButton}
                      >
                        <Trans i18nKey="pages.login.sendResetEmailButton" />
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </Modal>

      <UserAlert open={visible} status={status} message={message} handleClose={handleClose} />
    </>
  );
};

export default ResetModal;
