import {
  DashboardActionTypes,
  DashboardState,
  FETCH_COMPANY_ERROR,
  FETCH_COMPANY_START,
  FETCH_COMPANY_SUCCESS,
  GENERATE_COMPANY_PROFILE,
  PATCH_APPLICANT_ERROR,
  PATCH_APPLICANT_START,
  PATCH_APPLICANT_SUCCESS,
  PATCH_COMPANY_ERROR,
  PATCH_COMPANY_START,
  PATCH_COMPANY_SUCCESS,
  POST_COMPANY,
  RESET_STATE,
  SUBMIT_COMPANY_ERROR,
  SUBMIT_COMPANY_START,
  SUBMIT_COMPANY_SUCCESS,
} from './types';

export const initialState: DashboardState = {
  error: false,
  patching: false,
  submitting: false,
  onBoardingComplete: false,
  synchronizing: undefined,
  businessProfile: {
    businessValuation: 0,
    businessCreditLimit: 0,
    businessCreditScore: 0,
  },
  company: {
    id: null,
    createdAt: null,
    ownerId: '',
    businessType: null,
    incorporatedRegion: null,
    tradingName: null,
    registeredName: null,
    registeredNumber: null,
    website: null,
    incorporatedOn: null,
    startYear: null,
    onboardingStep: 0,
    yearToDateRevenue: null,
    active: false,
    monthlyRevenue: null,
    fundingAmount: null,
    fundingTime: null,
    fundingReasons: null,
    fundingUnpaidInvoices: false,
    fundingOwedInvoices: null,
    lastYearRevenue: null,
    grossMarginPercentage: null,
    recurringRevenuePercentage: null,
    businessCustomerRevenuePercentage: null,
    cashBalance: null,
    debitBalance: null,
    inventoryBalance: null,
    receivableBalance: null,
    debtBalance: null,
    repeatCustomersRevenuePercentage: null,
    shopifySalesPercentage: null,
    amazonSalesPercentage: null,
    ebaySalesPercentage: null,
    wooCommerceSalesPercentage: null,
    offlineSalesPercentage: null,
    otherSalesPercentage: null,
    directors: null,
  },
};

export const dashboardReducer = (state = initialState, action: DashboardActionTypes): DashboardState => {
  switch (action.type) {
    case POST_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case SUBMIT_COMPANY_START:
      return {
        ...state,
        submitting: true,
        error: false,
        // Make sure patching is set to false
        patching: false,
      };
    case SUBMIT_COMPANY_SUCCESS:
      return {
        ...state,
        company: { ...state.company, active: true },
        submitting: false,
        onBoardingComplete: true,
        error: false,
        // Make sure patching is set to false
        patching: false,
      };
    case SUBMIT_COMPANY_ERROR:
      return {
        ...state,
        submitting: false,
        error: action.payload || true,
      };
    case GENERATE_COMPANY_PROFILE:
      return {
        ...state,
        businessProfile: action.payload,
      };
    case RESET_STATE:
      return initialState;
    case FETCH_COMPANY_START:
      return {
        ...state,
        synchronizing: true,
      };
    case FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          ...action.payload,
        },
        onBoardingComplete: action.payload.active ?? state.onBoardingComplete,
        synchronizing: false,
      };
    case FETCH_COMPANY_ERROR:
      return {
        ...state,
        synchronizing: false,
      };
    case PATCH_COMPANY_START:
    case PATCH_APPLICANT_START:
      return {
        ...state,
        patching: true,
        error: false,
      };
    case PATCH_COMPANY_SUCCESS:
      return {
        ...state,
        // In the final step we want to keep patching set to true
        // Might want to consider using a global loading state, rather
        // than one for patching and one for submitting
        patching: action.payload.patching,
        company: {
          ...state.company,
          ...action.payload.data,
          directors: action.payload.directors || state.company.directors,
        },
        error: false,
      };
    case PATCH_COMPANY_ERROR:
    case PATCH_APPLICANT_ERROR:
      return {
        ...state,
        patching: false,
        error: action.payload || true,
      };
    case PATCH_APPLICANT_SUCCESS:
      return {
        ...state,
        company: { ...state.company, directors: action.payload },
        patching: false,
        error: false,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
