import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  colouredGrid: {
    backgroundColor: theme.palette.background.light,
    marginTop: '3rem',
    marginBottom: '3rem',
  },
  noPadding: { paddingBottom: '0px!important', margin: 0 },
  spacer: { paddingBottom: 2 },
  marginBottom: { marginBottom: '20px' },
}));
