import { Button, Grid } from '@material-ui/core';
import Modal from 'components/common/Modal';
import FormikTextField from 'components/common/FormikTextField';
import UserAlert, { Status, UserAlertProps } from 'components/common/UserAlert';
import LoginBase, { useLoginBaseStyles } from 'components/login-base';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';
import * as yup from 'yup';
import routes from 'router/routes';

const PasswordReset: React.FC<{ code: string }> = ({ code }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = React.useState<UserAlertProps>({
    open: false,
    message: '',
    status: Status.ERROR,
  });
  const { open, message, status } = openAlert;
  const firebase = useFirebase();
  const { t } = useTranslation();
  const baseClasses = useLoginBaseStyles();
  const history = useHistory();

  const handleModalClose = React.useCallback((event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenModal(false);
  }, []);
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert({ open: false });
  };
  const confirmPasswordReset = async (
    value: { password: string; confirmPassword: string },
    // eslint-disable-next-line no-empty-pattern
  ) => {
    try {
      await firebase.auth().confirmPasswordReset(code, value.password);
      setOpenModal(true);
    } catch (error) {
      setOpenAlert({
        open: true,
        message: `${t('pages.verification.password.error')}`,
        status: Status.ERROR,
      });
    }
  };
  const updateProfileSchema = yup.object({
    password: yup
      .string()
      .required(t('pages.register.validation.password.required'))
      .matches(/^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/, t('pages.register.validation.password.pattern')),
    confirmPassword: yup
      .string()
      .required(t('pages.register.validation.passwordConfirm.required'))
      .oneOf([yup.ref('password')], t('pages.register.validation.passwordConfirm.match')),
  });

  const loginButton = (
    <Button
      variant="contained"
      className={baseClasses.leftPanelButton}
      disableElevation
      // onClick={goLogin}
    >
      <Trans i18nKey="pages.register.loginButton" />
    </Button>
  );
  return (
    <LoginBase leftPanelButton={loginButton} i18nBaseKey="pages.verification.password">
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        onSubmit={confirmPasswordReset}
        validationSchema={updateProfileSchema}
      >
        {({ handleChange, values, isValid }) => (
          <Form noValidate>
            <Grid container item direction="column" spacing={5}>
              <Grid item>
                <Field
                  fullWidth
                  label={t('pages.verification.password.newPasswordLabel')}
                  margin="normal"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  component={FormikTextField}
                />
              </Grid>
              <Grid item>
                <Field
                  fullWidth
                  label={t('pages.register.passwordConfirmLabel')}
                  margin="normal"
                  name="confirmPassword"
                  onChange={handleChange}
                  type="password"
                  value={values.confirmPassword}
                  component={FormikTextField}
                />
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  disableElevation
                  variant="contained"
                  color="primary"
                  className={baseClasses.mainButton}
                  disabled={!isValid}
                >
                  <Trans i18nKey="pages.verification.password.button" />
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Modal
        open={openModal}
        handleClose={handleModalClose}
        title={t('pages.verification.password.title')}
        description={t('pages.verification.password.description')}
        actionButton={t('pages.verification.password.actionButton')}
        onClickPrimary={() => {
          handleModalClose();
          history.push(routes.login);
        }}
      />
      {/* <RedirectModal open={openModal} /> */}
      <UserAlert open={open} status={status} message={message} handleClose={handleClose} />
    </LoginBase>
  );
};

export default PasswordReset;
