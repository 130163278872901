import { LenderCategory, LenderCategoryApplication } from 'entities/lender';
import { patchItems } from 'utils';
import {
  CLOSE_LENDER_POPUP,
  FETCH_LENDERS_ERROR,
  FETCH_LENDERS_START,
  FETCH_LENDERS_SUCCESS,
  FETCH_PROVIDERS_ERROR,
  FETCH_PROVIDERS_START,
  FETCH_PROVIDERS_SUCCESS,
  LendersActionTypes,
  LendersState,
  LENDER_CATEGORY_APPLY_ERROR,
  LENDER_CATEGORY_APPLY_START,
  LENDER_CATEGORY_APPLY_SUCCESS,
  SELECT_LENDER_POPUP,
  FETCH_LENDER_CATEGORY_APPLICATIONS_ERROR,
  FETCH_LENDER_CATEGORY_APPLICATIONS_START,
  FETCH_LENDER_CATEGORY_APPLICATIONS_SUCCESS,
} from './types';

export const initialState: LendersState = {
  busy: true, // start this with `true` becase it is fetch-before-page-load check
  lenderCategories: [],
  providers: [],
  error: undefined,
  applying: false,
};

const isApplied = (category: LenderCategory, applications: LenderCategoryApplication[]): boolean =>
  applications.findIndex((application) => application.lenderCategory === category.id) >= 0;

const lendersReducer = (state = initialState, action: LendersActionTypes): LendersState => {
  switch (action.type) {
    case FETCH_LENDERS_START:
      return {
        ...state,
        busy: true,
      };
    case FETCH_LENDERS_SUCCESS:
      return {
        ...state,
        lenderCategories: action.payload,
        busy: false,
      };
    case FETCH_LENDERS_ERROR:
      return {
        ...state,
        error: action.payload,
        busy: false,
      };
    case FETCH_PROVIDERS_START:
      return {
        ...state,
        // busy: true,
      };
    case FETCH_PROVIDERS_SUCCESS:
      return {
        ...state,
        providers: action.payload,
        // busy: false,
      };
    case FETCH_PROVIDERS_ERROR:
      return {
        ...state,
        error: action.payload,
        // busy: false,
      };
    case SELECT_LENDER_POPUP:
      return {
        ...state,
        selectedLenderCategory: action.payload,
      };
    case CLOSE_LENDER_POPUP:
      return {
        ...state,
        selectedLenderCategory: undefined,
      };
    case LENDER_CATEGORY_APPLY_START:
      return {
        ...state,
        applying: true,
      };
    case LENDER_CATEGORY_APPLY_SUCCESS:
      return {
        ...state,
        lenderCategories: patchItems(state.lenderCategories, { applied: true }, (item) => item.id === action.payload),
        applying: false,
        selectedLenderCategory: undefined,
      };
    case LENDER_CATEGORY_APPLY_ERROR:
      return {
        ...state,
        applying: false,
        selectedLenderCategory: undefined,
        error: action.payload,
      };
    case FETCH_LENDER_CATEGORY_APPLICATIONS_START: {
      return {
        ...state,
        busy: true,
      };
    }
    case FETCH_LENDER_CATEGORY_APPLICATIONS_SUCCESS: {
      return {
        ...state,
        lenderCategories: patchItems(state.lenderCategories, (item) => ({
          ...item,
          applied: isApplied(item, action.payload),
        })),
        busy: false,
      };
    }
    case FETCH_LENDER_CATEGORY_APPLICATIONS_ERROR: {
      return {
        ...state,
        busy: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export default lendersReducer;
