import { ActionReducerMapBuilder, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import http from '../../../http';
import { IntegrationState, IntegrationStatus, Providers } from '../integration.types';

const SERVER_URL = process.env.REACT_APP_DOMAIN;

export const getIntegrationAuthURL = (provider: Providers): string => `${SERVER_URL}/company/provider/${provider}/auth`;

export const fetchIntegrations = async (): Promise<IntegrationStatus[]> => {
  const { status, data } = await http.get(`${SERVER_URL}/company/provider/integrations`);

  if (status !== 200) {
    throw new Error('Failed to fetch provider status');
  }

  return data;
};

export const getIntegrations = createAsyncThunk(`integration/getIntegrations`, async () => {
  const integrations = await fetchIntegrations();
  return integrations;
});

export const resetIntegrations = createAction('integration/resetIntegrations');

export const getIntegrationsReducers = (builder: ActionReducerMapBuilder<IntegrationState>): void => {
  builder.addCase(getIntegrations.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getIntegrations.fulfilled, (state, { payload: integrations }) => {
    state.loading = false;
    state.integrations = integrations;
  });
  builder.addCase(getIntegrations.rejected, (state) => {
    state.loading = false;
  });
  builder.addCase(resetIntegrations, (state) => {
    state.integrations = [];
  });
};
