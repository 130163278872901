import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {},
  outer: {
    outline: '2px dashed #92b0b3',
    backgroundColor: theme.palette.background.light,
    padding: '100px 20px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inner: {},
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  upload: {
    height: 'rem',

    border: '1px solid blue',
    marginBottom: '2rem',
    borderRadius: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  p: {
    marginLeft: '10px',
  },
  flex: {
    display: 'flex',
  },
  disabled: {
    opacity: 0.33,
  },
}));
