import React from 'react';

export const symbols = {
  funding: '💰',
  documents: '📄',
  company: '💼',
  logout: '🚪',
  grants: '🤲',
  welcome: '👋',
  call: '🤙',
  calendar: '📅',
  dashboard: '📈',
  perks: '🎁',
  notification: '🔔',
  details: '🖊️',
  investors: '💸',
  integration: '🔧',
  lenders: '💳',
  home: '🏠',
  admin: '🖥️',
  profile: '😀',
} as const;

export interface EmojiProps {
  label?: string;
  symbol?: string;
  variant?:
    | 'documents'
    | 'funding'
    | 'company'
    | 'logout'
    | 'grants'
    | 'welcome'
    | 'call'
    | 'dashboard'
    | 'perks'
    | 'notification'
    | 'calendar'
    | 'details'
    | 'investors'
    | 'lenders'
    | 'integration'
    | 'home'
    | 'admin'
    | 'profile';
}

const Emoji: React.FC<EmojiProps> = ({ label, symbol, variant }) => (
  <span
    className="emoji"
    role="img"
    aria-label={variant || label || ''}
    aria-hidden={label || variant ? 'false' : 'true'}
    style={{ outline: 'none' }}
  >
    {variant ? symbols[variant] : symbol}
  </span>
);

export default Emoji;
