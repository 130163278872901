import { Box, Button, Grid, Typography } from '@material-ui/core';
import { ReactComponent as FinishImage } from 'assets/icon/rocket_launch.svg';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useProfile } from 'store/firebase/selectors';

import useFormStyles from '../form.styles';
import FormBase from '../FormBase';
import './Finish.css';

interface FinishProps {
  onClickPrimary: () => void;
}

const Finish: FC<FinishProps> = ({ onClickPrimary }) => {
  const classes = useFormStyles();
  const { t } = useTranslation();

  const auth = useAuth();
  const userProfile = useProfile();

  const currentUser = {
    email: auth.email,
    displayName: userProfile.displayName || auth.displayName || '',
    photoURL: auth.photoURL,
  };

  return (
    <FormBase>
      <div>
        <Typography variant="h6">
          {`${t('pages.onBoarding.finish.main')}
                ${currentUser.displayName && currentUser.displayName}`}
        </Typography>
        <Box m={1} />
        <Typography variant="h4">
          {t('pages.onBoarding.finish.subtitle')}
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            style={{
              height: 10,
              width: 10,
              borderRadius: 5,
              display: 'inline-block',
            }}
            marginLeft={1}
            component="span"
          />
        </Typography>
        <Box m={1} />
        <Box className={`levitate ${classes.welcomeContainer}`}>
          <FinishImage style={{ maxWidth: 250 }} className="levitate" />
        </Box>
        <Box m={1} />
      </div>
      <Box className={classes.btnDiv}>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} md={6}>
            <Button
              className={classes.btn_pri}
              color="primary"
              fullWidth
              variant="contained"
              type="submit"
              onClick={() => onClickPrimary()}
            >
              {t('pages.onBoarding.finish.button')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </FormBase>
  );
};

export default Finish;
