import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    imgContainer: {
      width: 48,
      height: 48,
      justifyContent: 'center',
    },
    img: {
      maxHeight: 45,
      borderRadius: 10,
    },
    button: {},
    NotFoundTitle: {
      marginBottom: 20,
    },
  }),
);
