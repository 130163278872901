import React, { FC, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import ChatIcon from '@material-ui/icons/Chat';
import IconButton from '@material-ui/core/IconButton';

interface IntercomChatButtonProps {
  companyId?: string;
}

const IntercomChatButton: FC<IntercomChatButtonProps> = ({ companyId }) => {
  const url = useMemo(() => {
    const baseUrl = `https://app.intercom.com/a/apps/${process.env.REACT_APP_INTERCOM_APP_ID}/users/segments/all-users`;
    if (companyId) {
      const query = {
        predicates: [
          {
            attribute: 'company.remote_company_id',
            comparison: 'eq',
            type: 'string',
            value: companyId,
          },
        ],
      };
      const base64 = btoa(JSON.stringify(query));
      return `${baseUrl}:${base64}`;
    }
    return baseUrl;
  }, [companyId]);

  const onClick = () => window.open(url, '_blank');

  if (companyId) {
    return (
      <IconButton onClick={onClick}>
        <ChatIcon />
      </IconButton>
    );
  }

  return (
    <Button startIcon={<ChatIcon />} onClick={onClick}>
      Chat with users
    </Button>
  );
};

IntercomChatButton.displayName = 'IntercomChatButton';

export default IntercomChatButton;
