import { Fade, Grid, Typography } from '@material-ui/core';
import Loader from 'components/common/Loader';
import { timeouts } from 'constants/theme';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useMetricsLink } from 'store/firebase/selectors';
import Emoji from 'components/common/Emoji';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Metrics: React.FC<any> = () => {
  const { t } = useTranslation();
  const currentUser = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const metricsLink: any = useMetricsLink(currentUser.uid);

  const [loader, setLoader] = useState(true);

  const defaultMetricsLink = 'https://app.databox.com/datawall/9220235c9b82bcfd7f2948aa8ec3472c05faa7109?i';

  const url = () => {
    if (metricsLink && metricsLink[`${currentUser.uid}`]) {
      return metricsLink[`${currentUser.uid}`].url;
    }
    return undefined;
  };

  const hideLoader = () => setLoader(false);
  return (
    <Fade in timeout={timeouts.fade}>
      <Grid container spacing={3}>
        <Grid item md={9} sm={12} xs={12}>
          <Typography gutterBottom component="div">
            <Typography variant="h1" display="inline">
              <Emoji variant="dashboard" />{' '}
            </Typography>
            <Typography variant="h1" display="inline">
              {t('pages.metrics.header')}
            </Typography>
          </Typography>
          <Typography variant="h3" gutterBottom>
            {t('pages.metrics.details1')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {loader && <Loader />}
          <div style={{ padding: '63% 0 0 0', position: 'relative' }}>
            <iframe
              title="Databox url"
              onLoad={hideLoader}
              src={`${url() || defaultMetricsLink}`}
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                border: 0,
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Fade>
  );
};

export default Metrics;
