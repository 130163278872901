import React, { FC, useCallback, useState, useEffect } from 'react';
import { Company, CompanySearchResult } from 'store/dashboard/actions';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { Grid, NativeSelect } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import UserAlert, { Status, UserAlertProps } from 'components/common/UserAlert';
import ToolTipTextField from 'components/common/ToolTipTextField';
import InputLabelComponent from 'components/common/ToolTipInputLabel';
import { useOnboardLoadingAndErrorState } from 'store/dashboard/selectors';
import FormBase from './FormBase';
import useFormStyles from './form.styles';
import { useDebounce } from 'core/constants';
import { searchCompanyName } from 'http/dashboard';
import SubmitButtons from './SubmitButtons';
import { noop } from 'utils';

interface LabelValue {
  value: string;
  label: string;
}

const businessTypeOptions: LabelValue[] = [
  { value: 'limitedCompany', label: 'Limited Company' },
  { value: 'limitedPartnership', label: 'Limited Partnership' },
  { value: 'ordinaryPartnership', label: 'Ordinary Partnership' },
  { value: 'publicLimitedCompany', label: 'Public Limited Company' },
  { value: 'soleTrader', label: 'Sole Trader' },
];

// Values as seen on https://www.iban.com/country-codes
const incorporatedRegionOptions: LabelValue[] = [
  { value: 'GB', label: 'United Kingdom' },
  { value: 'IE', label: 'Ireland' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'US', label: 'USA' },
  { value: 'OTHER', label: 'Other' },
];

const year = new Date().getFullYear();

const years = Array.from(new Array(20), (val, index) => year - index);

const searchDebounceDelay = 500; // miliseconds

interface BusinessDetailsFormProps {
  formValues: Partial<Company>;
  onClickPrimary?: () => void;
  onClickSecondary?: () => void;
  primaryButtonTitle?: string;
  updateMode?: boolean;
}

const BusinessDetailsForm: FC<BusinessDetailsFormProps> = ({
  formValues,
  onClickPrimary = noop,
  onClickSecondary,
  primaryButtonTitle,
  updateMode = false,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false);
  const [options, setOptions] = useState<CompanySearchResult[]>([]);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<CompanySearchResult | null>(null);
  const [openAlert, setOpenAlert] = React.useState<UserAlertProps>({
    open: false,
    message: '',
    status: Status.ERROR,
  });
  const { t } = useTranslation();
  const classes = useFormStyles();
  const debouncedSearchTerm = useDebounce(searchTerm, searchDebounceDelay);

  const onBoardingSchema = yup.object({
    businessType: yup.string().required(t('pages.onBoarding.companyTypeValidate')).nullable(),
    incorporatedRegion: yup.string().required(t('pages.onBoarding.companyIncorporatedValidate')).nullable(),
    tradingName: yup.string().required(t('pages.onBoarding.companyTradingNameValidate')).nullable(),
    registeredName: yup.string().required(t('pages.onBoarding.companyNameValidate')).nullable(),
    website: yup.string().nullable(),
    registeredNumber: yup.string().required(t('pages.onBoarding.companyRegisteredNumberValidate')).nullable(),
    incorporatedOn: yup.string().required(t('pages.onBoarding.companyIncorporatedOnValidate')).nullable(),
    startYear: yup
      .number()
      .min(years[years.length - 1])
      .max(year)
      .required(t('pages.onBoarding.TradingYearValidate'))
      .nullable(),
  });

  // Trading name is the value of the registered company name input field - conflict in DB names
  useEffect(() => {
    if (formValues.tradingName && formValues.registeredNumber && formValues.incorporatedOn) {
      setSelectedOption({
        title: formValues.tradingName,
        companyNumber: formValues.registeredNumber,
        incorporatedOn: formValues.incorporatedOn as string,
        companyAddress: null,
      });
    }
  }, [formValues.tradingName, formValues.registeredNumber, formValues.incorporatedOn]);

  useEffect(() => {
    if (!autoCompleteOpen) {
      setOptions([]);
    }
  }, [autoCompleteOpen]);

  const searchCompanies = useCallback(async () => {
    setLoadingOptions(true);
    try {
      const data = await searchCompanyName(debouncedSearchTerm);
      setOptions(data);
    } catch (error) {
      /** */
    }
    setLoadingOptions(false);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm !== '') {
      searchCompanies();
    } else {
      setOptions([]);
    }
  }, [debouncedSearchTerm, searchCompanies]);

  const { loading, patchCompany } = useOnboardLoadingAndErrorState(setOpenAlert, onClickPrimary);

  const { open, message, status } = openAlert;

  const handleClose = useCallback((event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert({ open: false });
  }, []);

  const initialValues = {
    businessType: formValues.businessType || businessTypeOptions[0].value,
    incorporatedRegion: formValues.incorporatedRegion || incorporatedRegionOptions[0].value,
    tradingName: formValues.tradingName,
    registeredName: formValues.registeredName,
    registeredNumber: formValues.registeredNumber,
    website: formValues.website,
    incorporatedOn: formValues.incorporatedOn,
    startYear: formValues.startYear || year,
    step: 2,
  };

  const onSubmit = (value: typeof initialValues) => {
    const payload = { ...value, companyAddress: selectedOption?.companyAddress };
    patchCompany({
      ...payload,
      startYear: Number(value.startYear),
    });
  };

  return (
    <FormBase>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={onBoardingSchema}>
        {({ handleChange, values, errors, touched, setFieldValue }) => (
          <Form noValidate>
            <Grid container item direction="column" spacing={4} className={classes.formGridContainer}>
              <Grid item>
                <InputLabelComponent
                  htmlFor="businessType"
                  id="businessTypeLabel"
                  label={t('pages.onBoarding.companyTypeLabel')}
                />
                <NativeSelect
                  fullWidth
                  value={values.businessType}
                  onChange={handleChange}
                  inputProps={{ name: 'businessType', id: 'businessType' }}
                  placeholder="e.g Limited Company"
                  name="businessTypeLabel"
                  error={touched.businessType && !!errors.businessType}
                >
                  {businessTypeOptions.map((bTOption, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <option key={`bTOption${index}`} value={bTOption.value}>
                      {bTOption.label}
                    </option>
                  ))}
                </NativeSelect>
              </Grid>

              <Grid item>
                <InputLabelComponent
                  htmlFor="incorporatedRegion"
                  id="incorporatedRegionLabel"
                  label={t('pages.onBoarding.companyIncorporatedLabel')}
                />
                <NativeSelect
                  fullWidth
                  value={values.incorporatedRegion}
                  onChange={handleChange}
                  inputProps={{ name: 'incorporatedRegion', id: 'incorporatedRegion' }}
                  placeholder="e.g Limited Company"
                  name="incorporatedRegionLabel"
                  error={touched.incorporatedRegion && !!errors.incorporatedRegion}
                >
                  {incorporatedRegionOptions.map((bTOption, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <option key={`bTOption${index}`} value={bTOption.value}>
                      {bTOption.label}
                    </option>
                  ))}
                </NativeSelect>
              </Grid>

              {/* Here values for registeredName and tradingName do not match the input
              titles which IS INTENTIONAL due to the correct names in DB  */}
              <Grid item>
                <Field
                  name="registeredName"
                  placeholder="e.g World Famous CupCakes ltd"
                  onChange={handleChange}
                  component={ToolTipTextField}
                  label={t('pages.onBoarding.companyTradingNameLabel')}
                  value={values.registeredName ? values.registeredName : ''}
                  error={touched.registeredName && !!errors.registeredName}
                  helperText={touched.registeredName && errors.registeredName}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <Autocomplete
                  id="tradingName"
                  value={selectedOption}
                  open={autoCompleteOpen}
                  onOpen={() => setAutoCompleteOpen(true)}
                  onClose={() => setAutoCompleteOpen(false)}
                  onChange={(e, value) => {
                    setSelectedOption(value);
                    if (value) {
                      setFieldValue('tradingName', value.title);
                      setFieldValue('registeredNumber', value.companyNumber);
                      setFieldValue('incorporatedOn', value.incorporatedOn);
                    }
                  }}
                  onInputChange={(e, value) => setSearchTerm(value)}
                  options={options}
                  loading={loadingOptions}
                  getOptionSelected={(option, value) => option.title === value.title}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <Field
                      {...params}
                      name="tradingName"
                      component={ToolTipTextField}
                      variant="standard"
                      label={t('pages.onBoarding.companyNameLabel')}
                      toolTipMessage={t('pages.onBoarding.companyNameLabelToolTip')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="e.g World Famous CupCakes ltd"
                      error={touched.tradingName && !!errors.tradingName}
                      helperText={touched.tradingName && errors.tradingName}
                    />
                  )}
                  className={classes.textInput}
                  disabled={updateMode && !!formValues.registeredNumber}
                />
              </Grid>

              <Grid item>
                <Field
                  toolTipMessage={t('pages.onBoarding.websiteLabelToolTip')}
                  name="website"
                  value={values.website ? values.website : ''}
                  component={ToolTipTextField}
                  onChange={handleChange}
                  title={t('pages.onBoarding.websiteLabel')}
                  error={touched.website && !!errors.website}
                  helperText={touched.website && errors.website}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  placeholder="worldfamouscupcakes.com"
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <Field
                  name="registeredNumber"
                  placeholder="e.g SC123456"
                  onChange={handleChange}
                  component={ToolTipTextField}
                  label={t('pages.onBoarding.companyRegisteredNumberLabel')}
                  value={values.registeredNumber ? values.registeredNumber : ''}
                  error={touched.registeredNumber && !!errors.registeredNumber}
                  helperText={touched.registeredNumber && errors.registeredNumber}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.textInput}
                  disabled
                />
              </Grid>

              <Grid item>
                <Field
                  name="incorporatedOn"
                  placeholder="e.g SC123456"
                  onChange={handleChange}
                  component={ToolTipTextField}
                  label={t('pages.onBoarding.companyIncorporatedOnLabel')}
                  value={values.incorporatedOn ? values.incorporatedOn : ''}
                  error={touched.incorporatedOn && !!errors.incorporatedOn}
                  helperText={touched.incorporatedOn && errors.incorporatedOn}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.textInput}
                  disabled
                />
              </Grid>

              <Grid item>
                <InputLabelComponent
                  htmlFor="startYear"
                  id="startYearLabel"
                  label={t('pages.onBoarding.TradingYearLabel')}
                  message={t('pages.onBoarding.TradingYearToolTip')}
                />
                <NativeSelect
                  fullWidth
                  value={values.startYear}
                  onChange={handleChange}
                  inputProps={{ name: 'startYear', id: 'startYear' }}
                  placeholder="e.g 2020"
                  name="startYearLabel"
                  error={touched.startYear && !!errors.startYear}
                >
                  {years.map((year, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <option key={`year${index}`} value={year}>
                      {year}
                    </option>
                  ))}
                </NativeSelect>
              </Grid>

              <SubmitButtons
                onClickSecondary={onClickSecondary}
                loading={loading}
                primaryButtonTitle={primaryButtonTitle}
              />
            </Grid>
          </Form>
        )}
      </Formik>

      <UserAlert open={open} status={status} message={message} handleClose={handleClose} />
    </FormBase>
  );
};

export default BusinessDetailsForm;
