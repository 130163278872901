import { LenderCategory, LenderCategoryCriteria } from 'entities/lender';
import { calculateAge } from 'services/businessMetrics';

export interface LenderEligibilityParams {
  startYear: number | Date;
  lastYearRevenue: number;
  monthlyRevenue: number;
  businessCustomerRevenuePercentage: number;
}

export const LAgeRequirementCriterion: string[] = [
  LenderCategoryCriteria.age3months,
  LenderCategoryCriteria.age6months,
  LenderCategoryCriteria.age12months,
];

export const LMonthRevenueCriterion: string[] = [
  LenderCategoryCriteria.monthlyRevenue5k,
  LenderCategoryCriteria.monthlyRevenue4k,
  LenderCategoryCriteria.monthlyRevenue3k,
  LenderCategoryCriteria.monthlyRevenue2k,
  LenderCategoryCriteria.monthlyRevenue500,
];

export const LAnnualRevenueCriterion: string[] = [LenderCategoryCriteria.revenue20k];

export const LBusinessCustomerRevenuePercentageCriterion: string[] = [LenderCategoryCriteria.b2bSales5];

const LAgeRequirements: {
  [k: string]: number;
} = {
  [LenderCategoryCriteria.age3months]: 3,
  [LenderCategoryCriteria.age6months]: 6,
  [LenderCategoryCriteria.age12months]: 12,
};

const LmonthlyRevenues: {
  [k: string]: number;
} = {
  [LenderCategoryCriteria.monthlyRevenue4k]: 4000,
  [LenderCategoryCriteria.monthlyRevenue5k]: 5000,
  [LenderCategoryCriteria.monthlyRevenue500]: 500,
  [LenderCategoryCriteria.monthlyRevenue2k]: 2000,
};

const LAnnualRevenues: {
  [k: string]: number;
} = {
  [LenderCategoryCriteria.revenue20k]: 20000,
};

const LBusinessCustomerRevenuePercentage: {
  [k: string]: number;
} = {
  [LenderCategoryCriteria.b2bSales5]: 0.05,
};

export const getUnmetCriterion = (lender: LenderCategory, params: LenderEligibilityParams): string[] => {
  const { startYear, lastYearRevenue, monthlyRevenue, businessCustomerRevenuePercentage } = params;

  const age = calculateAge(startYear);

  return lender.criteria.filter((criteria) => {
    if (LAgeRequirementCriterion.includes(criteria) && age >= LAgeRequirements[criteria]) {
      return false;
    }

    if (LMonthRevenueCriterion.includes(criteria) && monthlyRevenue >= LmonthlyRevenues[criteria]) {
      return false;
    }

    if (LAnnualRevenueCriterion.includes(criteria) && lastYearRevenue >= LAnnualRevenues[criteria]) {
      return false;
    }

    if (
      LBusinessCustomerRevenuePercentageCriterion.includes(criteria) &&
      businessCustomerRevenuePercentage >= LBusinessCustomerRevenuePercentage[criteria]
    ) {
      return false;
    }

    return true;
  });
};

export const getLenderEligibility = (lenders: LenderCategory[], params: LenderEligibilityParams): LenderCategory[] =>
  lenders.map((lenderInfo) => {
    const criterionFailed: string[] = getUnmetCriterion(lenderInfo, params);
    return {
      ...lenderInfo,
      eligibility: {
        eligible: criterionFailed.length === 0,
        missingCriterion: criterionFailed,
      },
    };
  });
