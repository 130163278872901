import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  demo: {},
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  upload: {
    height: '3rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid blue',
    marginBottom: '2rem',
    borderRadius: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  p: {
    marginLeft: '10px',
  },
  flex: {
    display: 'flex',
  },
  deleteAvatar: {
    height: '20px',
    width: '20px',
  },
  deleteIcon: {
    fontSize: '10px',
  },
}));

export default useStyles;
