export enum DocumentActions {
  UploadDocuments = 'UPLOAD_DOCUMENTS',
  GetDocuments = 'GET_DOCUMENTS',
  DeleteDocument = 'DELETE_DOCUMENT',
  AddDocuments = 'ADD_DOCUMENTS',
}

interface UploadDocumentsAction {
  type: DocumentActions.UploadDocuments;
  payload: Document[];
}

interface GetDocumentsAction {
  type: DocumentActions.GetDocuments;
  payload: Document[];
}

interface DeleteDocumentAction {
  type: DocumentActions.DeleteDocument;
  id: string;
}

interface AddDocumentsAction {
  type: DocumentActions.AddDocuments;
  payload: Document[];
}

export interface Document {
  id: string;
  name: string;
}

export type DocumentActionTypes =
  | UploadDocumentsAction
  | GetDocumentsAction
  | DeleteDocumentAction
  | AddDocumentsAction;

export interface DocumentsState {
  items: Document[];
}
