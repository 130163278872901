import { ActionReducerMapBuilder, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import http from '../../../http';
import { IntegrationState, IntegrationStatus, IntegrationProviderAccountID } from '../integration.types';
import { resolveProviderAccountID } from '../integration.utils';

const SERVER_URL = process.env.REACT_APP_DOMAIN;

export const fetchIntegrationStatus = async ({
  provider,
  accountID,
}: IntegrationProviderAccountID): Promise<IntegrationStatus> => {
  const { status, data } = await http.get(`${SERVER_URL}/company/provider/${provider}/${accountID}`);

  if (status !== 200) {
    throw new Error('Failed to fetch provider status');
  }

  return data;
};

export const getIntegrationStatus = createAsyncThunk(
  `integration/getIntegrationStatus`,
  async (account: IntegrationProviderAccountID) => {
    const status = await fetchIntegrationStatus(account);
    return { ...account, status };
  },
);

export const resetIntegrationStatus = createAction<IntegrationProviderAccountID>('integration/resetIntegrationStatus');

export const getIntegrationStatusReducers = (builder: ActionReducerMapBuilder<IntegrationState>): void => {
  builder.addCase(getIntegrationStatus.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getIntegrationStatus.fulfilled, (state, { payload: { provider, accountID, status } }) => {
    state.loading = false;
    state.status[resolveProviderAccountID(provider, accountID)] = status;
  });
  builder.addCase(resetIntegrationStatus, (state, { payload: { provider, accountID } }) => {
    delete state.status[resolveProviderAccountID(provider, accountID)];
  });
};
