import { Avatar, Box, IconButton, CircularProgress, Fade, Grid, TextField, Typography } from '@material-ui/core';
import { Visibility, Search } from '@material-ui/icons';
import debounce from 'lodash.debounce';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Table from 'components/common/Table';
import { timeouts } from 'constants/theme';
import { GBPFormatter } from 'services/currencyFormatter';
import { getAdminCompanies } from 'store/admin/actions';
import { useAdminState } from 'store/admin/selectors';
import { AdminColumnNames, AdminCompany } from 'store/admin/types';
import useAdminStyles from './AdminCompanyList.styles';
import { IntercomChatButton } from './components';

const Admin: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useAdminStyles();
  const { path } = useRouteMatch();

  const [searchString, setSearchString] = React.useState('');
  const [pagination, setPagination] = React.useState({});

  const history = useHistory();

  const { loading, companies } = useAdminState();

  const { count, rows, limit } = companies;

  const searchCompanyList = useMemo(
    () =>
      debounce((value: string) => {
        dispatch(getAdminCompanies(value, {}));
      }, 1000),
    [dispatch],
  );

  const columns = (companiesData: AdminCompany[]) => [
    {
      name: AdminColumnNames.website,
      label: '',
      options: {
        customBodyRender: (value: string) => (
          <Box className={classes.imgContainer}>
            <Avatar alt={value} src={`//logo.clearbit.com/${value}?size=80`} />
          </Box>
        ),
      },
    },
    {
      name: AdminColumnNames.active,
      label: 'Active',
      options: {
        customBodyRender: (value: string) => <Typography variant="body1">{value ? 'Yes' : 'No'}</Typography>,
      },
    },
    {
      name: AdminColumnNames.lastUpdated,
      label: 'Last updated',
      options: {
        customBodyRender: (value: string) => <Typography variant="body1">{value}</Typography>,
      },
    },
    {
      name: AdminColumnNames.registeredName,
      label: 'Name',
      options: {},
    },
    {
      name: AdminColumnNames.lastYearRevenue,
      label: 'Last Year Revenue',
      options: {
        customBodyRender: (value: string) => (
          <Typography variant="body1">
            {value ? GBPFormatter.format(parseFloat(value)) : GBPFormatter.format(0)}
          </Typography>
        ),
      },
    },
    {
      name: AdminColumnNames.fundingTime,
      label: 'Funding Time',
      options: {
        customBodyRender: (value: string) => <Typography variant="body1">{value}</Typography>,
      },
    },
    {
      name: AdminColumnNames.fundingAmount,
      label: 'Funding Amount',
      options: {
        customBodyRender: (value: string) => (
          <Typography variant="body1">
            {value ? GBPFormatter.format(parseFloat(value)) : GBPFormatter.format(0)}
          </Typography>
        ),
      },
    },
    {
      name: AdminColumnNames.id,
      label: 'View',
      options: {
        customBodyRender: (id: string) => {
          // need this long approach to access variables
          // https://github.com/gregnb/mui-datatables/issues/1038#issuecomment-569106293
          const companyData = companiesData.filter((company: AdminCompany) => company.id === id)[0];
          return (
            <IconButton
              color="primary"
              onClick={() =>
                history.push({
                  pathname: `${path}/${id}`,
                  state: {
                    companyId: id,
                    effectiveDate: companyData.createdAt,
                  },
                })
              }
              className={classes.button}
            >
              <Visibility />
            </IconButton>
          );
        },
      },
    },
    {
      name: AdminColumnNames.id,
      label: 'Chat',
      options: {
        customBodyRender: (id: string) => <IntercomChatButton companyId={id} />,
      },
    },
  ];

  useEffect(() => {
    dispatch(getAdminCompanies('', pagination));
  }, [dispatch, pagination]);

  const AdminTable = () => (
    <>
      {rows && columns && (
        <Table
          title=""
          columns={columns(rows)}
          data={rows}
          loading={loading}
          rowsPerPage={limit}
          options={{
            selectableRows: 'none',
            download: false,
            print: false,
            filterType: 'dropdown',
            rowsPerPage: count,
            filter: false,
            viewColumns: false,
            search: false,
            pagination: !searchString,
            onChangeRowsPerPage: (rowsValue) =>
              setPagination({
                ...pagination,
                rows: rowsValue,
              }),
          }}
        />
      )}
    </>
  );

  return (
    <Fade in timeout={timeouts.fade}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h1">Admin</Typography>
            <IntercomChatButton />
          </Box>
        </Grid>
        <Grid item md={9} sm={12} xs={12}>
          <TextField
            fullWidth
            autoFocus
            name="fundingAmount"
            value={searchString}
            onChange={({ target: { value } }) => {
              setSearchString(value || '');
              searchCompanyList(value);
            }}
            label="Search company records"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <Search />,
            }}
            placeholder="Company search"
          />
        </Grid>
        <Grid item xs={12}>
          {loading ? <CircularProgress size={20} /> : <AdminTable />}
        </Grid>
      </Grid>
    </Fade>
  );
};

export default Admin;
