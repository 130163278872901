import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/types';
import { GlobalError, GlobalErrorHookReturn } from './types';
import { setGlobalError as setGlobalErrorAction } from './actions';

const globalErrorSelector = (state: RootState): GlobalError => state.global.error;

export const useGlobalError = (): GlobalErrorHookReturn => {
  const globalError = useSelector(globalErrorSelector);
  const dispatch = useDispatch();

  const setGlobalError = useCallback(
    (error: GlobalError) => {
      dispatch(setGlobalErrorAction(error));
    },
    [dispatch],
  );

  return { globalError, setGlobalError };
};
