import React from 'react';
import NumberFormat from 'react-number-format';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

interface Values {
  value: string;
}

const NumberFormatCustom: React.FC<NumberFormatCustomProps> = ({ name, inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    displayType="input"
    onValueChange={(values: Values) => {
      onChange({
        target: {
          name,
          value: values.value,
        },
      });
    }}
    thousandSeparator
    isNumericString={false}
  />
);

export default NumberFormatCustom;
