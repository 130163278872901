import { Box, Button, Card, CardActions, CardContent, CardHeader, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Emoji from 'components/common/Emoji';
import { LenderCategory } from 'entities/lender';
import useStyles from './LenderCard.styles';

interface LenderProps {
  category: LenderCategory;
  apply: () => void;
  select: () => void;
}

const LenderCard: FC<LenderProps> = ({ category, apply, select }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader className={classes.header} />
      <CardContent>
        <div className={classes.title}>
          <Typography variant="h3">
            <Emoji symbol={category.symbol} label={category.name} /> {t(category.name)}
          </Typography>
          <Typography variant="h3" gutterBottom>
            {t('pages.lendersList.card.aka')} &quot;{t(category.alias)}&quot;
          </Typography>
        </div>
        <div className={classes.description}>
          <Typography variant="body1">{t(category.description)}</Typography>
        </div>
        <div className={classes.caption}>
          <Typography variant="caption">{t('pages.lendersList.card.caption')}</Typography>
        </div>
        <Box display="flex" flexWrap="wrap">
          {category.criteria.map((requirement) => (
            <span
              className={clsx([
                classes.requirement,
                !category.eligibility?.missingCriterion.includes(requirement)
                  ? classes.criteriaSuccess
                  : classes.criteriaFailed,
              ])}
              key={requirement}
            >
              {!category.eligibility?.missingCriterion.includes(requirement) ? (
                <CheckIcon className={classes.criteriaIcon} />
              ) : (
                <CloseIcon className={classes.criteriaIcon} />
              )}{' '}
              {t(`pages.lendersList.criteria.${requirement}`)}
            </span>
          ))}
        </Box>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Button className={classes.actionButton} color="default" variant="outlined" onClick={() => select()}>
            {t('pages.lendersList.card.learnMoreButton')}
          </Button>
          {category.appliable && (
            <Button
              className={clsx(classes.actionButton, {
                [classes.appliedButton]: category.applied,
              })}
              color="primary"
              variant="contained"
              disabled={!category.eligibility?.eligible || category.applied}
              onClick={() => {
                apply();
              }}
              startIcon={category.applied ? <CheckIcon className={classes.checkIcon} /> : undefined}
            >
              {t(`pages.lendersList.card.${category.applied ? 'appliedButton' : 'applyButton'}`)}
            </Button>
          )}
        </Box>
      </CardActions>
    </Card>
  );
};

export default LenderCard;
