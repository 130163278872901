import React, { useCallback, useMemo } from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Button } from '@material-ui/core';
import { useFirebase } from 'react-redux-firebase';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { useProfile } from 'store/firebase/selectors';
import { useTranslation } from 'react-i18next';
import FormikTextField from 'components/common/FormikTextField';
import UserAlert, { Status, UserAlertProps } from 'components/common/UserAlert';
import { User as UserEntity } from 'entities/user';
import useStyles from './UserProfile.styles';

interface User extends UserEntity {
  phoneNumber: string | null;
}
const ProfileDetails: React.FC<{ user: User }> = ({ user }) => {
  const { displayName, phoneNumber, email } = user;
  const profile = useProfile();
  const classes = useStyles();
  const [openAlert, setOpenAlert] = React.useState<UserAlertProps>({
    open: false,
    message: '',
    status: Status.ERROR,
  });
  const { open, message, status } = openAlert;
  const firebase = useFirebase();
  const { t } = useTranslation();
  const handleClose = useCallback((event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert({ open: false });
  }, []);

  const updateProfileSchema = useMemo(
    () =>
      yup.object({
        displayName: yup.string().required(t('pages.profile.displayName')),
        email: yup.string().required(t('pages.profile.email')),
        phoneNumber: yup.string().nullable(),
      }),
    [t],
  );

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          displayName: profile.displayName || displayName,
          email: profile.email || email,
          phoneNumber: profile.phoneNumber || phoneNumber || '',
        }}
        onSubmit={(value) => {
          firebase
            .updateProfile({ ...profile, ...value })
            .then(() => {
              firebase.auth().currentUser?.updateEmail(value.email);
              setOpenAlert({
                open: true,
                message: `${t('pages.profile.success')}`,
                status: Status.SUCCESS,
              });
            })
            .catch(() => {
              setOpenAlert({
                open: true,
                message: `${t('pages.profile.error')}`,
                status: Status.ERROR,
              });
            });
        }}
        validationSchema={updateProfileSchema}
      >
        {({ handleChange, values }) => (
          <Form noValidate>
            <Card className={classes.root} elevation={0}>
              <CardHeader title={t('pages.profile.details.title')} />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      label={t('pages.profile.details.email')}
                      name="email"
                      onChange={handleChange}
                      value={email}
                      component={FormikTextField}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      label={t('pages.profile.details.displayName')}
                      name="displayName"
                      onChange={handleChange}
                      value={values.displayName}
                      component={FormikTextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      label={t('pages.profile.details.phone')}
                      name="phoneNumber"
                      onChange={handleChange}
                      value={values.phoneNumber}
                      component={FormikTextField}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button color="default" variant="outlined" type="submit" className={classes.button}>
                  {t('pages.profile.save')}
                </Button>
              </Box>
            </Card>
          </Form>
        )}
      </Formik>
      <UserAlert open={open} status={status} message={message} handleClose={handleClose} />
    </>
  );
};

export default ProfileDetails;
