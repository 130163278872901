import React from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import routes from 'router/routes';
import { ConfirmPasswordReset, EmailVerification } from './components';

const authModes = {
  verifyEmail: 'verifyEmail',
  resetPassword: 'resetPassword',
} as const;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface AuthListenerContentProps {
  authMode: string;
  authOobCode: string;
}

const AuthListenerContent: React.FC<AuthListenerContentProps> = ({ authMode, authOobCode }) => (
  <>
    {authMode === authModes.verifyEmail && <EmailVerification code={authOobCode || ''} />}

    {authMode === authModes.resetPassword && <ConfirmPasswordReset code={authOobCode || ''} />}
  </>
);

const AuthModeListener: React.FC = () => {
  const query = useQuery();
  const authOobCode = query.get('oobCode');
  const authMode = query.get('mode');
  const { path } = useRouteMatch();

  return (
    <Switch>
      {(authMode === authModes.verifyEmail || authMode === authModes.resetPassword) && (
        <Route
          path={path}
          render={() => <AuthListenerContent authMode={authMode || ''} authOobCode={authOobCode || ''} />}
        />
      )}
      <Route>
        <Redirect to={`/${routes.home}`} />
      </Route>
    </Switch>
  );
};

export default AuthModeListener;
