import {
  AdminActionTypes,
  AdminState,
  GET_ADMIN_COMPANIES_ERROR,
  GET_ADMIN_COMPANIES_START,
  GET_ADMIN_COMPANIES_SUCCESS,
  GET_ADMIN_COMPANY_ACTION,
  GET_ADMIN_COMPANY_DOCUMENTS_SUCCESS,
  GET_ADMIN_COMPANY_ERROR,
  GET_ADMIN_COMPANY_FINANCIAL_SUCCESS,
  GET_ADMIN_COMPANY_SUCCESS,
} from './types';

export const initialState: AdminState = {
  loading: false,
  company: {
    loading: false,
    details: {
      id: null,
      createdAt: null,
      ownerId: '',
      businessType: null,
      incorporatedRegion: null,
      tradingName: null,
      registeredName: 'null firm',
      registeredNumber: null,
      website: null,
      incorporatedOn: null,
      startYear: null,
      onboardingStep: 0,
      yearToDateRevenue: null,
      active: false,
      monthlyRevenue: null,
      fundingAmount: null,
      fundingTime: null,
      fundingReasons: null,
      fundingUnpaidInvoices: false,
      fundingOwedInvoices: null,
      lastYearRevenue: null,
      grossMarginPercentage: null,
      recurringRevenuePercentage: null,
      businessCustomerRevenuePercentage: null,
      cashBalance: null,
      debitBalance: null,
      inventoryBalance: null,
      receivableBalance: null,
      debtBalance: null,
      repeatCustomersRevenuePercentage: null,
      shopifySalesPercentage: null,
      amazonSalesPercentage: null,
      ebaySalesPercentage: null,
      wooCommerceSalesPercentage: null,
      offlineSalesPercentage: null,
      otherSalesPercentage: null,
      directors: null,
    },
  },
  companies: {
    count: 0,
    limit: 0,
    offset: 0,
    rows: [
      {
        id: null,
        createdAt: null,
        ownerId: '',
        businessType: null,
        incorporatedRegion: null,
        tradingName: null,
        registeredName: null,
        registeredNumber: null,
        website: null,
        incorporatedOn: null,
        startYear: null,
        yearToDateRevenue: null,
        onboardingStep: 0,
        active: false,
        monthlyRevenue: null,
        fundingAmount: null,
        fundingTime: null,
        fundingReasons: null,
        fundingUnpaidInvoices: false,
        fundingOwedInvoices: null,
        lastYearRevenue: null,
        grossMarginPercentage: null,
        recurringRevenuePercentage: null,
        businessCustomerRevenuePercentage: null,
        cashBalance: null,
        debitBalance: null,
        inventoryBalance: null,
        receivableBalance: null,
        debtBalance: null,
        repeatCustomersRevenuePercentage: null,
        shopifySalesPercentage: null,
        amazonSalesPercentage: null,
        ebaySalesPercentage: null,
        wooCommerceSalesPercentage: null,
        offlineSalesPercentage: null,
        otherSalesPercentage: null,
        directors: null,
      },
    ],
  },
};

export const adminReducer = (state = initialState, action: AdminActionTypes): AdminState => {
  switch (action.type) {
    case GET_ADMIN_COMPANIES_START:
      return {
        ...state,
        loading: true,
      };
    case GET_ADMIN_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        loading: false,
      };
    case GET_ADMIN_COMPANIES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_ADMIN_COMPANY_ACTION:
      return {
        ...state,
        company: {
          ...state.company,
          loading: true,
        },
      };

    case GET_ADMIN_COMPANY_FINANCIAL_SUCCESS:
    case GET_ADMIN_COMPANY_SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          details: {
            ...state.company.details,
            ...action.payload,
          },
          loading: false,
        },
      };

    case GET_ADMIN_COMPANY_DOCUMENTS_SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          documents: action.payload,
          loading: false,
        },
      };

    case GET_ADMIN_COMPANY_ERROR:
      return {
        ...state,
        company: {
          ...state.company,
          loading: false,
        },
      };

    default:
      return state;
  }
};

export default adminReducer;
