import { PaletteType } from '@material-ui/core';

export const USER_CHANGE_THEME_MODE = 'USER_CHANGE_THEME_MODE';
export const USER_TOGGLE_THEME_MODE = 'USER_TOGGLE_THEME_MODE';
export const PIPEDRIVE_USER_UPDATED = 'PIPEDRIVE_USER_UPDATED';

interface UserChangeThemeModeAction {
  type: typeof USER_CHANGE_THEME_MODE;
  payload: PaletteType;
}

interface UserToggleThemeModeAction {
  type: typeof USER_TOGGLE_THEME_MODE;
}

interface UserPipedriveSyncComplete {
  type: typeof PIPEDRIVE_USER_UPDATED;
}

export type UserActionTypes = UserChangeThemeModeAction | UserToggleThemeModeAction | UserPipedriveSyncComplete;

export interface UserState {
  preferences: {
    themeMode: PaletteType;
  };
}
