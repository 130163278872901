import { Button, Paper, Typography } from '@material-ui/core';
import Tooltip from 'components/common/Tooltip';
import { ProfileCardInterface } from 'entities/dashboard';
import React from 'react';
import useStyles from './ProfileCard.styles';

const ProfileCard: React.FC<ProfileCardInterface> = ({
  title,
  // svg,
  h4,
  p,
  buttonText,
  // onBoardingComplete,
  // // currentUser,
  // timeout,
  cardEvent,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={0}>
      <div className={classes.details}>
        <Tooltip title={`${p}`}>
          <Typography variant="caption" gutterBottom style={{ textTransform: 'uppercase' }}>
            {title}
          </Typography>
        </Tooltip>
        {/* <Hidden smDown>
          <img src={svg} alt="title" className={classes.titleImg} />
        </Hidden> */}
        <Typography variant="h2" gutterBottom>
          {h4}
        </Typography>
        {/* <Typography variant="body1" gutterBottom>
          {p}
        </Typography>
        {p2 && <Typography variant="body1">{p2}</Typography>} */}

        {/* disable includes block for current release
          <div>
            <Typography variant="caption">
              <Box fontWeight="fontWeightBold" m={1}>
                {include}
              </Box>
            </Typography>
            <div className="includeSvg">
              <img src={includeSvg} alt="" height="55px" />
              {includeSvg2 && <img src={includeSvg2} alt="" height="55px" />}
            </div>
          </div> */}
        <div className={classes.btnDiv}>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            className={classes.button}
            onClick={() => cardEvent && cardEvent()}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default ProfileCard;
