import { Box, Typography } from '@material-ui/core';
import { PieChartTwoTone as AvatarIcon } from '@material-ui/icons';
import { PieChart } from 'components/common/Charts';
import MetricCardBase from 'components/dashboard/MetricCardBase';
import { timeouts } from 'constants/theme';
import React from 'react';
import CountTo from 'react-count-to';
import { useTranslation } from 'react-i18next';

const BusinessCreditScore: React.FC<{
  score: number;
  onBoardingComplete: boolean;
  mode?: string;
  small?: boolean;
}> = (props) => {
  const { score, onBoardingComplete, small, mode } = props;
  const { t } = useTranslation();

  const creditScore = (value: number) => <span>{value}</span>;

  const maxScore = 999;
  return (
    <MetricCardBase
      title={t('general.titles.creditScore')}
      buttonText={t('general.titles.learnMore')}
      small={small}
      empty={!onBoardingComplete}
      // tooltipTitle={t('general.titles.creditScore')}
      tooltipMessage={t('pages.dashboard.creditScore.caption2')}
      avatarIcon={<AvatarIcon />}
      onBoardingComplete={onBoardingComplete}
    >
      {onBoardingComplete ? (
        <div>
          <Box height="50px" display="flex" alignItems="center">
            <PieChart value={score} valueMax={maxScore - score} max={maxScore} mode={mode} />
            <Typography>
              <Typography
                variant="h1"
                component="span"
                style={{
                  marginLeft: 10,
                }}
              >
                <CountTo to={score} speed={timeouts.countTo}>
                  {creditScore}
                </CountTo>
              </Typography>
              <Typography variant="caption" component="span">
                /{maxScore}
              </Typography>
              {/* <Direction direction="down" percentage="1.5" /> */}
            </Typography>
          </Box>
        </div>
      ) : (
        <>
          <Typography variant="caption">{t('pages.dashboard.creditScore.caption2')}</Typography>
        </>
      )}
    </MetricCardBase>
  );
};

export default BusinessCreditScore;
