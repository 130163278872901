import { Avatar, Grid, SvgIconProps } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { InfoOutlined as TooltipIcon } from '@material-ui/icons';
import Tooltip from 'components/common/Tooltip';
import React from 'react';
import useStyles from './MetricCardBase.styles';

interface MetricCardBaseProps {
  onClick?: () => void;
  buttonText: string;
  title: string;
  children: React.ReactNode;
  small?: boolean;
  append?: React.ReactNode;
  empty?: boolean;
  tooltipTitle?: string;
  tooltipMessage?: string;
  avatarIcon?: React.ReactElement<SvgIconProps>;
  onBoardingComplete?: boolean;
}

const MetricCardBase: React.FC<MetricCardBaseProps> = ({
  children,
  title,
  small,
  append,
  tooltipTitle,
  tooltipMessage,
  avatarIcon,
  onBoardingComplete = false,
}) => {
  const classes = useStyles();

  return (
    <Card className={small ? classes.small : classes.root} elevation={0}>
      <CardContent>
        <>
          {/* <Tooltip
            heading={tooltipTitle}
            title={`${tooltipMessage}`}
            Icon={<TooltipIcon className={classes.icon} />}
          >
            <Typography
              variant="caption"
              className={`${small ? classes.smallTitle : classes.title}`}
              component="span"
            >
              {title}
            </Typography>
          </Tooltip>
          <Box className={`${classes.childrenContent}`}>
            {children}
            {append && !small && append}
          </Box> */}

          <Grid container justify="space-between" spacing={3}>
            <Grid item>
              <Tooltip
                heading={tooltipTitle}
                title={`${tooltipMessage}`}
                Icon={<TooltipIcon className={classes.icon} />}
              >
                <Typography
                  variant="caption"
                  className={`${small ? classes.smallTitle : classes.title}`}
                  component="span"
                >
                  {title}
                </Typography>
              </Tooltip>

              {children}
              {append && !small && append}
            </Grid>
            {onBoardingComplete && (
              <Grid item className={classes.avatarContainer}>
                <Avatar className={classes.avatar}>{avatarIcon}</Avatar>
              </Grid>
            )}
          </Grid>
        </>
      </CardContent>
      {/* <CardActions className={classes.cardActions}>
        <Button variant="contained" size="small" onClick={onClick}>
          {buttonText}
        </Button>
      </CardActions> */}
    </Card>
  );
};

export default MetricCardBase;
