import axios from 'axios';
import { Provider } from 'entities/provider';

export const getProviders = async (): Promise<Provider[]> => {
  const res = await axios.get<Provider[]>('/providers/providers.json');
  return res.data;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getProviders };
