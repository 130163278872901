import * as yup from 'yup';
import { Address } from 'entities/dashboard';

yup.addMethod<yup.ArraySchema<Partial<Address>>>(
  yup.array,
  'uniqueCurrent',
  function (message, mapper = (a: Partial<Address>) => a) {
    return this.test('uniqueCurrent', message, function (list) {
      const currentList = list || [];
      return currentList.length === new Set(currentList.map(mapper)).size;
    });
  },
);

yup.addMethod<yup.ArraySchema<Partial<Address>>>(
  yup.array,
  'oneCurrent',
  function (message, mapper = (a: Partial<Address>) => a) {
    return this.test('oneCurrent', message, function (list) {
      const currentList = list || [];
      return currentList.some(mapper);
    });
  },
);

declare module 'yup' {
  interface NotRequiredArraySchema<T> {
    uniqueCurrent(this: NotRequiredArraySchema<T>, ...ignoredKeys: unknown[]): NotRequiredArraySchema<T>;
    oneCurrent(this: NotRequiredArraySchema<T>, ...ignoredKeys: unknown[]): NotRequiredArraySchema<T>;
  }
}

export default yup;
