import React from 'react';

import { Box, ButtonBase, makeStyles, Theme, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 132,
    backgroundColor: theme.palette.background.light,
    borderRadius: 20,
    textAlign: 'left',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    boxShadow: theme.shadows[0],
  },
  mainIconContainer: {
    backgroundColor: theme.palette.background.actionContrast,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.8,
    width: '5rem',
    borderRadius: '50%',
    height: '5rem',
    marginRight: '1.5rem',
  },
  mainContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: 1,
    minWidth: '150px',
  },
  icon: {
    color: theme.palette.common.white,
  },
  button: {
    backgroundColor: theme.palette.primary.dark,
  },
  titleText: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  buttonText: {},
  loader: { marginLeft: '10px' },
}));

interface BlueCardProps {
  onBoardingComplete: boolean;
  onboardingStep: number;
  loading: boolean;
  onClick: () => void;
}

const BlueCard: React.FC<BlueCardProps> = ({ onClick, onboardingStep }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ButtonBase onClick={() => onClick()} className={classes.root}>
      <>
        <Box className={classes.mainIconContainer}>
          <AddIcon fontSize="large" color="error" className={classes.icon} />
        </Box>

        <Box className={classes.mainContentContainer}>
          <Typography variant="caption" className={classes.titleText} gutterBottom>
            {onboardingStep === 0 ? t('pages.dashboard.blueCard.main') : t('pages.dashboard.blueCard.mainAlternate')}
          </Typography>
          <Typography variant="caption">{t('pages.dashboard.blueCard.body2')}</Typography>
        </Box>
      </>
    </ButtonBase>
  );
};

export default BlueCard;
