import React from 'react';
import { FieldProps } from 'formik';
import { TextField } from '@material-ui/core';

interface Props {
  title: string;
}

type OwnProps = FieldProps & Props;
const FormikTextField: React.SFC<OwnProps> = ({ title, field, form, ...props }) => {
  const hasError = form.touched[field.name] && form.errors[field.name];
  return (
    <TextField
      fullWidth
      type="text"
      error={form.touched[field.name] && !!form.errors[field.name]}
      helperText={hasError}
      style={hasError ? { paddingBottom: 0 } : {}}
      {...field}
      {...props}
    />
  );
};

export default FormikTextField;
