import React, { FC } from 'react';
import { Company } from 'store/dashboard/actions';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import {
  Grid,
  NativeSelect,
  InputAdornment,
  FormHelperText,
  Chip,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import UserAlert, { Status, UserAlertProps } from 'components/common/UserAlert';
import NumberFormatCustom from 'components/common/NumberFormatCustom';
import ToolTipTextField from 'components/common/ToolTipTextField';
import InputLabelComponent from 'components/common/ToolTipInputLabel';
import { useOnboardLoadingAndErrorState } from 'store/dashboard/selectors';
import useOnboardingStyles from './form.styles';
import FormBase from './FormBase';
import SubmitButtons from './SubmitButtons';
import { noop } from 'utils';

interface FundingGoalsFormProps {
  formValues: Partial<Company>;
  onClickPrimary?: () => void;
  onClickSecondary?: () => void;
  primaryButtonTitle?: string;
}

interface LabelValue {
  value: string;
  label: string;
}

const singleOptions = [
  { value: undefined, label: '' },
  { value: 'ASAP', label: 'As soon as possible' },
  { value: 'fewweeks', label: 'In a few weeks' },
  { value: 'fewMonths', label: 'In a few months' },
  { value: 'justBrowsing', label: "I'm exploring my options" },
  { value: 'nextYear', label: 'In the next year' },
];
const fundingOptions: LabelValue[] = [
  { value: 'businessExpansion', label: 'Daily operations' },
  { value: 'purchasingStock', label: 'Purchasing stock' },
  { value: 'refinanceDebt', label: 'Loan refinance' },
  { value: 'marketing', label: 'Marketing' },
  { value: 'staffwages', label: 'Hiring' },
  { value: 'paying', label: 'Paying' },
  { value: 'payingTaxBill', label: 'Paying a tax bill' },
];
const unpaidInvoicesOptions = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
];

const FundingGoalsForm: FC<FundingGoalsFormProps> = ({
  formValues,
  onClickPrimary = noop,
  onClickSecondary,
  primaryButtonTitle,
}) => {
  const [openAlert, setOpenAlert] = React.useState<UserAlertProps>({
    open: false,
    message: '',
    status: Status.ERROR,
  });

  const { t } = useTranslation();
  const classes = useOnboardingStyles();

  const onBoardingSchema = yup.object({
    fundingAmount: yup
      .number()
      .required(t('pages.onBoarding.fundingAmountValidate'))
      .min(0, t('pages.onBoarding.fundingAmountValidate'))
      .typeError(t('pages.onBoarding.fundingAmountValidate'))
      .nullable(),
    fundingReasons: yup
      .array()
      .required(t('pages.onBoarding.fundingReasonsValidate'))
      .min(1, t('pages.onBoarding.fundingReasonsValidate'))
      .typeError(t('pages.onBoarding.fundingReasonsValidate')),
    fundingTime: yup.string().required(t('pages.onBoarding.fundingTimeValidate')).nullable(),
    fundingUnpaidInvoices: yup.boolean().default(false),
    fundingOwedInvoices: yup
      .number()
      .when('fundingUnpaidInvoices', {
        is: true,
        then: yup
          .number()
          .required(t('pages.onBoarding.fundingOwedValidate'))
          .min(0, t('pages.onBoarding.fundingOwedValidate'))
          .typeError(t('pages.onBoarding.fundingOwedValidate')),
      })
      .nullable(),
  });

  const { loading, patchCompany } = useOnboardLoadingAndErrorState(setOpenAlert, onClickPrimary);

  const { open, message, status } = openAlert;

  const handleClose = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert({ open: false });
  };

  const initialValues = {
    fundingAmount: formValues.fundingAmount,
    fundingReasons: fundingOptions.filter(
      (o) => formValues?.fundingReasons && formValues?.fundingReasons?.indexOf(o.value) >= 0,
    ),
    fundingTime: formValues.fundingTime,
    fundingUnpaidInvoices: !!formValues.fundingUnpaidInvoices,
    fundingOwedInvoices: formValues.fundingOwedInvoices,
    step: 3,
  };

  const onSubmit = (value: typeof initialValues) => {
    const payload = {
      ...value,
      fundingAmount: Number(value.fundingAmount),
      fundingReasons: (value.fundingReasons || []).map((o: { value: string }) => o.value),
      fundingTime: value.fundingTime || undefined,
      fundingUnpaidInvoices: value.fundingUnpaidInvoices,
    };
    if (value.fundingOwedInvoices) {
      payload.fundingOwedInvoices = Number(value.fundingOwedInvoices);
    }
    patchCompany(payload);
  };

  return (
    <FormBase>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={onBoardingSchema}>
        {({ handleChange, values, errors, touched, setFieldValue }) => (
          <Form noValidate>
            <Grid container item direction="column" spacing={4} className={classes.formGridContainer}>
              <Grid item>
                <Field
                  id="fundingAmount"
                  fullWidth
                  component={ToolTipTextField}
                  autoFocus
                  name="fundingAmount"
                  value={values.fundingAmount}
                  onChange={handleChange}
                  title={t('pages.onBoarding.fundingAmountLabel')}
                  toolTipMessage={t('pages.onBoarding.fundingAmountLabelToolTip')}
                  error={touched.fundingAmount && !!errors.fundingAmount}
                  helperText={touched.fundingAmount && errors.fundingAmount}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    inputComponent: NumberFormatCustom as any,
                  }}
                  placeholder="100000"
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={fundingOptions}
                  value={values.fundingReasons}
                  onChange={(opt, val) => setFieldValue('fundingReasons', val)}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <Field
                      component={ToolTipTextField}
                      {...params}
                      variant="standard"
                      label={t('pages.onBoarding.fundingReasonsLabel')}
                      placeholder="Purchasing Stock"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={touched.fundingReasons && !!errors.fundingReasons}
                      helperText={touched.fundingReasons && errors.fundingReasons}
                    />
                  )}
                  className={classes.textInput}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip {...getTagProps({ index })} color="primary" label={option.label} />
                    ))
                  }
                />
              </Grid>

              <Grid item>
                <InputLabelComponent
                  htmlFor="fundingTime"
                  id="fundingTimesLabel"
                  label={t('pages.onBoarding.fundingTimelineLabel')}
                  message={t('pages.onBoarding.fundingTimelineLabelToolTip')}
                />
                <NativeSelect
                  fullWidth
                  onChange={handleChange}
                  inputProps={{
                    name: 'fundingTime',
                    id: 'fundingTime',
                  }}
                  className={classes.select}
                  name="fundingTime"
                  value={values.fundingTime || ''}
                  error={touched.fundingTime && !!errors.fundingTime}
                >
                  {singleOptions.map(({ label, value }) => (
                    <option key={`option${value}`} value={value}>
                      {label}
                    </option>
                  ))}
                </NativeSelect>
                {touched.fundingTime && !!errors.fundingTime && (
                  <FormHelperText error>{errors.fundingTime}</FormHelperText>
                )}
              </Grid>

              <Grid item>
                <InputLabelComponent
                  htmlFor="fundingUnpaidInvoices"
                  id="fundingUnpaidInvoicesLabel"
                  label={t('pages.onBoarding.fundingUnpaidInvoicesLabel')}
                  message={t('pages.onBoarding.fundingUnpaidLabelTooltip')}
                />

                <RadioGroup
                  aria-label="unpaid-invoices"
                  value={values.fundingUnpaidInvoices}
                  onChange={(e) => {
                    const value = e.target.value === 'true';
                    setFieldValue('fundingUnpaidInvoices', value);
                    if (!value) setFieldValue('fundingOwedInvoices', null);
                  }}
                  name="fundingUnpaidInvoices"
                >
                  {unpaidInvoicesOptions.map((option) => (
                    <FormControlLabel
                      key={option.label}
                      value={option.value}
                      control={<Radio color="primary" />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </Grid>

              {values.fundingUnpaidInvoices && (
                <Grid item>
                  <Field
                    fullWidth
                    component={ToolTipTextField}
                    id="fundingOwedInvoices"
                    name="fundingOwedInvoices"
                    value={values.fundingOwedInvoices}
                    onChange={handleChange}
                    title={t('pages.onBoarding.fundingOwedInvoicesLabel')}
                    toolTipMessage={t('pages.onBoarding.fundingOwedLabelTooltip')}
                    error={touched.fundingOwedInvoices && !!errors.fundingOwedInvoices}
                    helperText={touched.fundingOwedInvoices && errors.fundingOwedInvoices}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">£</InputAdornment>,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      inputComponent: NumberFormatCustom as any,
                    }}
                    placeholder="10000"
                    className={classes.textInput}
                  />
                </Grid>
              )}

              <SubmitButtons
                onClickSecondary={onClickSecondary}
                loading={loading}
                primaryButtonTitle={primaryButtonTitle}
              />
            </Grid>
          </Form>
        )}
      </Formik>

      <UserAlert open={open} status={status} message={message} handleClose={handleClose} />
    </FormBase>
  );
};

export default FundingGoalsForm;
