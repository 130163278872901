import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      position: 'relative',
      '& > hr': {
        marginBottom: '2rem',
      },
    },
    instantLogin: {
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      padding: '0 15px',
      whiteSpace: 'nowrap',
      backgroundColor: theme.palette.background.light,
    },
  }),
);
