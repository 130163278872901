import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      padding: '.5rem',
      margin: 0,
    },
  }),
);
