import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core';
import { ReactComponent as LoginBaseImage } from 'assets/icon/authentication.svg';
import React, { FC, ReactNode } from 'react';
import { Trans } from 'react-i18next';

import { Logo, ThemeModeToggle } from 'components';

import useStyles from './LoginBase.styles';

export interface LoginBaseProps {
  leftPanelButton: ReactNode;
  i18nBaseKey: string;
  children: ReactNode;
}

const LoginBase: FC<LoginBaseProps> = ({ i18nBaseKey, children }: LoginBaseProps) => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.root} maxWidth="lg">
        <Grid container alignContent="center" alignItems="stretch">
          <Hidden smDown>
            <Grid item md={6} className={classes.leftPanel}>
              <Grid container>
                <Grid item md={4}>
                  <Logo width="100%" />
                </Grid>
                {/* <Grid item md={8}>
                  <Box display="flex" justifyContent="flex-end">
                    {leftPanelButton}
                  </Box>
                </Grid> */}
                <Grid item md={12}>
                  <Box display="flex" justifyContent="center">
                    <LoginBaseImage className={classes.teamwork} />
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Typography align="center" className={classes.disclaimer}>
                    <Trans i18nKey={`${i18nBaseKey}.disclaimer`} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Grid item container direction="column" md={6} sm={8} xs={12} className={classes.rightPanel} justify="center">
            <Hidden smDown>
              <Typography variant="h1" className={classes.header}>
                <Trans i18nKey={`${i18nBaseKey}.header`} />
              </Typography>
            </Hidden>
            <Hidden mdUp>
              <Box mb="1rem" display="flex" justifyContent="center">
                <Logo width="150" />
              </Box>
              <Typography variant="h1" className={classes.header} align="center">
                <Trans i18nKey={`${i18nBaseKey}.mobile.header`} />
              </Typography>
            </Hidden>
            <Grid container direction="column">
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Box position="fixed" top="0" right="0">
        <ThemeModeToggle />
      </Box>
    </>
  );
};

export default LoginBase;
