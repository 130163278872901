import { Drawer, Hidden } from '@material-ui/core';
import React from 'react';

import Content from './SideBarContent';
import useSidebarstyles from './Siderbar.styles';

const Sidebar: React.FC<{ mobileNavOpen: boolean; mobileNavToggle: () => void }> = ({
  mobileNavOpen,
  mobileNavToggle,
}) => {
  const classes = useSidebarstyles();

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={mobileNavToggle}
          open={mobileNavOpen}
          variant="temporary"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Content />
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="permanent">
          <Content />
        </Drawer>
      </Hidden>
    </>
  );
};

export default Sidebar;
