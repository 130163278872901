import React from 'react';
import {
  Cell,
  Pie,
  PieChart as PieChartNative,
  ResponsiveContainer,
  // Text,
} from 'recharts';

export interface PieChartProps {
  value: number;
  valueMax: number;
  mode: string | undefined;
  max: number;
}

export const PieChart: React.FC<PieChartProps> = ({ value, valueMax, mode }) => {
  const data = [
    { name: 'Your Score', value },
    { name: 'Full Amount', value: valueMax },
  ];

  const COLORS = [mode === 'dark' ? '#0062FF' : '#1E75FF', mode === 'dark' ? '#e0e0e0' : '#292932'];

  return (
    <div style={{ width: 51 }}>
      <ResponsiveContainer height={51} width="100%">
        <PieChartNative width={51} height={51}>
          <Pie
            stroke="none"
            data={data}
            startAngle={360}
            endAngle={0}
            innerRadius="90%"
            outerRadius="100%"
            paddingAngle={0}
            dataKey="value"
          >
            {/* <Label
              content={(props) => {
                const {
                  viewBox: { cx, cy },
                } = props;
                const positioningProps = {
                  x: cx,
                  y: cy,
                  textAnchor: 'middle',
                  verticalAnchor: 'middle',
                };
                const captionPositioningProps = {
                  x: cx,
                  y: cy + 23,
                  textAnchor: 'middle',
                  verticalAnchor: 'middle',
                };
                const presentationProps = {
                  fill: mode === 'dark' ? '#000000' : '#FFFFFF',
                  fontSize: '30',
                  fontFamily: 'inter light',
                };
                const captionProps = {
                  fill: mode === 'dark' ? '#000000' : '#FFFFFF',
                  fontSize: '12',
                  fontFamily: 'inter light',
                };

                return (
                  <>
                    <Text {...positioningProps} {...presentationProps}>
                      {value}
                    </Text>
                    <Text {...captionPositioningProps} {...captionProps}>
                      out of {max}
                    </Text>
                  </>
                );
              }}
            /> */}
            {data.map((entry, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChartNative>
      </ResponsiveContainer>
    </div>
  );
};

// // #E8B43E
// // 232, 180, 62
// // Mandy
// // #D95359
// // 217, 83, 89
// // Ming
// // #418996
// // 65, 137, 150
// // Limed Spruce
// // #2C373E
// // 44, 55, 62
// // White
// // #FFFFFF
// // 255, 255, 255
