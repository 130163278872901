import { createStyles, makeStyles, Theme, colors } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      // paddingLeft: theme.spacing(4),
    },
    mobileDrawer: {
      width: 180,
      paddingTop: 50,
    },
    desktopDrawer: {
      width: 200,
      background: theme.palette.background.light,
      top: 64,
      height: 'calc(100% - 64px)',
    },
    paper: {
      background: 'transparent',
    },
    avatar: {
      cursor: 'pointer',
      width: 64,
      height: 64,
    },
    active: {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.background.active,
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium,
      },
      '& $icon': {
        color: theme.palette.background.actionContrast,
      },
    },

    button: {
      color: theme.palette.common.black,
      justifyContent: 'flex-start',
      letterSpacing: 0,
      padding: `2px 2px`,
      paddingLeft: theme.spacing(2),

      // width: '100%',

      '&:hover': {
        backgroundColor: theme.palette.background.hover,
      },
    },
    icon: {
      marginRight: theme.spacing(1),
      color: `${colors.grey[600]}!important`,
      fontSize: '15px',

      '& $svg': {
        [theme.breakpoints.down('md')]: {
          width: '0.8em',
          height: '0.8em',
        },
      },
    },
    item: {
      display: 'flex',
    },
    title: {
      marginRight: 'auto',
      marginLeft: theme.spacing(1),

      '& $span': {
        [theme.breakpoints.down('md')]: {
          fontSize: '15px',
        },
      },
    },
    bottom: { position: 'absolute', bottom: 0, width: '100%' },
  }),
);
