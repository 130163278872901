import { Box, Typography } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import React from 'react';

const Header: React.FC<{
  title: string;
  metrics?: boolean;
  icon?: React.ReactElement<SvgIconProps>;
}> = ({ title, icon = <InsertDriveFileOutlinedIcon /> }) => (
  <Box
    style={{
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
    }}
  >
    <Typography gutterBottom component="div">
      <Typography variant="h2" display="inline">
        {icon}{' '}
      </Typography>
      <Typography variant="h2" display="inline">
        {title}
      </Typography>
    </Typography>
  </Box>
);

export default Header;
