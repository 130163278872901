import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { FirebaseReducer, useFirestoreConnect } from 'react-redux-firebase';
import { RootState } from 'store/types';

export const providers = {
  google: 'google.com',
  email: 'password',
} as const;

const AUTH_ERROR_CODES = ['auth/user-not-found', 'auth/wrong-password'] as const;

export const useAuth = (): FirebaseReducer.AuthState =>
  useSelector((state: RootState) => state.firebase.auth, shallowEqual);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useProfile = (): FirebaseReducer.Profile<Record<string, any>> =>
  useSelector((state: RootState) => state.firebase.profile);

export const useAuthError = (): { message: string; code: typeof AUTH_ERROR_CODES } | undefined =>
  useSelector((state: RootState) => state.firebase.authError);

export const useAuthErrorMessage = (): string | false => {
  const authError = useAuthError();
  const { t } = useTranslation();
  if (authError?.code) {
    return t(`pages.login.authErrors.${authError.code}`) as string;
  }
  return false;
};

export const useRegisterAuthErrorMessage = (): string | false => {
  const authError = useAuthError();
  const { t } = useTranslation();
  if (authError?.code) {
    return t(`pages.register.authErrors.${authError.code}`) as string;
  }
  return false;
};

export const useMetricsLink = (userId: string): string | null => {
  useFirestoreConnect([{ collection: 'metrics-url', doc: userId }]);
  return useSelector((state: RootState) => state.firestore.data['metrics-url']);
};

export const useAdmin = (): boolean => useSelector((state: RootState) => !!state.firebase.profile?.admin);
