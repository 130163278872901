import { Box, Fade, Theme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { timeouts } from 'constants/theme';
import React from 'react';

interface MetricCardBaseProps {
  children: React.ReactNode;
  // eslint-disable-next-line react/require-default-props
  noMaxWidth?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    display: 'flex',
    padding: theme.spacing(3),
    minHeight: 630,
  },
  small: {
    backgroundColor: theme.palette.background.light,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  cardContent: {
    maxWidth: 600,
    paddingTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  cardContentMax: {
    paddingTop: '1rem',
    maxWidth: 'none',
    width: '80%',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  smallTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  cardActions: {
    justifyContent: 'center',
  },
  childrenContent: {
    paddingTop: '1rem',
    textAlign: 'center',
    justifyContent: 'space-between',
    width: 'auto',
  },
  childrenContentMax: {
    paddingTop: '1rem',
    textAlign: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

const FormBase: React.FC<MetricCardBaseProps> = ({ children, noMaxWidth }) => {
  const classes = useStyles();

  return (
    <Card className={noMaxWidth ? classes.small : classes.root} elevation={1}>
      <CardContent className={noMaxWidth ? classes.cardContentMax : classes.cardContent}>
        <Fade in timeout={timeouts.fade}>
          <Box className={noMaxWidth ? classes.childrenContentMax : classes.childrenContent}>{children}</Box>
        </Fade>
      </CardContent>
    </Card>
  );
};

export default FormBase;
