import { PaletteType, useMediaQuery } from '@material-ui/core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { UserState } from './types';

export const useUser = (): UserState => useSelector((state: RootState) => state.user);

export const useThemeMode = (): PaletteType => {
  // detect from user-agent or OS
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // our app preferences
  const user = useUser();
  // the app theme
  const themeMode = useMemo(
    () => (prefersDarkMode && !user.preferences.themeMode ? 'dark' : user.preferences.themeMode),
    [prefersDarkMode, user.preferences.themeMode],
  );

  return themeMode;
};

export default useUser;
