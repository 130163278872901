import { Box, Button, ButtonProps, CircularProgress } from '@material-ui/core';
import React from 'react';

interface LoadingButtonProps {
  loading: boolean;
}

const SpinnerAdornment = () => (
  <CircularProgress
    style={{
      marginLeft: 15,
      marginTop: 10,
      position: 'absolute',
    }}
    size={25}
  />
);

const LoadingButton = (props: LoadingButtonProps & ButtonProps) => {
  const { children, loading, disabled, ...rest } = props;
  return (
    <Box justifyContent="space-between" width="100%">
      <Button {...rest} disabled={loading || disabled}>
        {children}
      </Button>
      {loading && <SpinnerAdornment {...rest} />}
    </Box>
  );
};

export default React.memo(LoadingButton);
