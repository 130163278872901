import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.light,
      maxWidth: '550px',
    },
    header: {
      paddingBottom: 0,
    },
    title: {},
    description: {
      minHeight: '40',
    },
    caption: {
      margin: '1.8rem 0 0.8rem',
    },
    requirement: {
      padding: '0.4rem 0.5rem',
      marginBottom: '0.6rem',
      backgroundColor: theme.palette.action.disabledBackground,
      borderRadius: theme.shape.borderRadius,
      '&:not(:last-child)': {
        marginRight: '0.4rem',
      },
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
    },
    criteriaSuccess: {
      color: '#53861b',
      border: 'none',
      borderRadius: 20,
      backgroundColor: '#82c43c2e',
    },
    criteriaFailed: {
      color: '#ff2e1f',
      border: 'none',
      borderRadius: 20,
      backgroundColor: 'rgb(244 67 54 / 18%)',
    },
    criteriaIcon: {
      fontSize: 16,
      marginRight: 3,
    },
    actionButton: {
      minWidth: '142px',
      '&:not(:last-child)': {
        marginRight: '1rem',
      },
    },
    appliedButton: {
      backgroundColor: `${theme.palette.success.main}!important`,
      opacity: 0.6,
    },
    checkIcon: {
      color: theme.palette.common.white,
    },
  }),
);
