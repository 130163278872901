import { LenderCategory, LenderCategoryApplication, LenderCategoryId } from 'entities/lender';
import { Provider } from 'entities/provider';
import { fromAxiosError, HttpError } from 'http/error';
import {
  getLenderCategories,
  applyToLenderCategory as applyToLenderCategoryApi,
  getLenderCategoryApplications,
} from 'http/lenders';
import { getProviders } from 'http/providers';
import { getLenderEligibility } from 'services/lenderElibility';
import { AppThunk } from 'store/types';
import {
  CLOSE_LENDER_POPUP,
  FETCH_LENDERS_ERROR,
  FETCH_LENDERS_START,
  FETCH_LENDERS_SUCCESS,
  FETCH_LENDER_CATEGORY_APPLICATIONS_ERROR,
  FETCH_LENDER_CATEGORY_APPLICATIONS_START,
  FETCH_LENDER_CATEGORY_APPLICATIONS_SUCCESS,
  FETCH_PROVIDERS_ERROR,
  FETCH_PROVIDERS_START,
  FETCH_PROVIDERS_SUCCESS,
  LendersActionTypes,
  LENDER_CATEGORY_APPLY_ERROR,
  LENDER_CATEGORY_APPLY_START,
  LENDER_CATEGORY_APPLY_SUCCESS,
  SELECT_LENDER_POPUP,
} from './types';

const fetchLenderCategoryApplicationsStart = (): LendersActionTypes => ({
  type: FETCH_LENDER_CATEGORY_APPLICATIONS_START,
});

const fetchLenderCategoryApplicationsSuccess = (applications: LenderCategoryApplication[]): LendersActionTypes => ({
  type: FETCH_LENDER_CATEGORY_APPLICATIONS_SUCCESS,
  payload: applications,
});

const fetchLenderCategoryApplicationsError = (error: HttpError): LendersActionTypes => ({
  type: FETCH_LENDER_CATEGORY_APPLICATIONS_ERROR,
  payload: error,
});

const fetchLenderCategoryApplications = (): AppThunk => async (dispatch) => {
  dispatch(fetchLenderCategoryApplicationsStart());
  try {
    const { rows } = await getLenderCategoryApplications();
    dispatch(fetchLenderCategoryApplicationsSuccess(rows));
  } catch (error) {
    dispatch(fetchLenderCategoryApplicationsError(fromAxiosError(error)));
  }
};

const fetchLendersStart = (): LendersActionTypes => ({
  type: FETCH_LENDERS_START,
});

const fetchLendersSuccess = (lenders: LenderCategory[]): LendersActionTypes => ({
  type: FETCH_LENDERS_SUCCESS,
  payload: lenders,
});

const fetchLendersError = (error: HttpError): LendersActionTypes => ({
  type: FETCH_LENDERS_ERROR,
  payload: error,
});

export const fetchLenderCategories = (
  startYear: number | Date | null,
  lastYearRevenue: number | null,
  monthlyRevenue: number | null,
  businessCustomerRevenuePercentage: number | null,
): AppThunk => async (dispatch) => {
  dispatch(fetchLendersStart());

  try {
    const lenders = await getLenderCategories();
    dispatch(
      fetchLendersSuccess(
        getLenderEligibility(lenders, {
          startYear: startYear || 0,
          lastYearRevenue: lastYearRevenue || 0,
          monthlyRevenue: monthlyRevenue || 0,
          businessCustomerRevenuePercentage: businessCustomerRevenuePercentage || 0,
        }),
      ),
    );
    dispatch(fetchLenderCategoryApplications());
  } catch (error) {
    dispatch(fetchLendersError(fromAxiosError(error)));
  }
};

const fetchProvidersStart = (): LendersActionTypes => ({
  type: FETCH_PROVIDERS_START,
});

const fetchProvidersSuccess = (lenders: Provider[]): LendersActionTypes => ({
  type: FETCH_PROVIDERS_SUCCESS,
  payload: lenders,
});

const fetchProvidersError = (error: HttpError): LendersActionTypes => ({
  type: FETCH_PROVIDERS_ERROR,
  payload: error,
});

export const fetchProviders = (): AppThunk => async (dispatch) => {
  dispatch(fetchProvidersStart());
  try {
    const lenders = await getProviders();
    dispatch(fetchProvidersSuccess(lenders));
  } catch (error) {
    dispatch(fetchProvidersError(fromAxiosError(error)));
  }
};

export const selectLenderPopup = (lender: LenderCategory): LendersActionTypes => ({
  type: SELECT_LENDER_POPUP,
  payload: lender,
});

export const closeLenderPopup = (): LendersActionTypes => ({
  type: CLOSE_LENDER_POPUP,
});

const applyToLenderCategoryStart = (): LendersActionTypes => ({
  type: LENDER_CATEGORY_APPLY_START,
});

const applyToLenderCategorySuccess = (categoryId: LenderCategoryId): LendersActionTypes => ({
  type: LENDER_CATEGORY_APPLY_SUCCESS,
  payload: categoryId,
});

const applyToLenderCategoryError = (error: HttpError): LendersActionTypes => ({
  type: LENDER_CATEGORY_APPLY_ERROR,
  payload: error,
});

export const applyToLenderCategory = (): AppThunk => async (dispatch, getState) => {
  const {
    lenders: { selectedLenderCategory },
  } = getState();
  if (selectedLenderCategory) {
    dispatch(applyToLenderCategoryStart());
    try {
      await applyToLenderCategoryApi(selectedLenderCategory.id);
      dispatch(applyToLenderCategorySuccess(selectedLenderCategory.id));
    } catch (error) {
      dispatch(applyToLenderCategoryError(fromAxiosError(error)));
    }
  }
};
