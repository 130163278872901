import { Box, Typography } from '@material-ui/core';
import { MoneyTwoTone as AvatarIcon } from '@material-ui/icons';
import MetricCardBase from 'components/dashboard/MetricCardBase';
import { timeouts } from 'constants/theme';
import React from 'react';
import CountTo from 'react-count-to';
import { useTranslation } from 'react-i18next';
import { GBPFormatter } from 'services/currencyFormatter';

interface CreditLimitProps {
  businessCreditLimit: number;
  onBoardingComplete: boolean;
  small?: boolean;
}

const CreditLimit: React.FC<CreditLimitProps> = (props) => {
  const { businessCreditLimit, onBoardingComplete, small } = props;
  const { t } = useTranslation();

  // const creditLimit = React.useMemo(
  //   () => GBPFormatter.format(businessCreditLimit),
  //   [businessCreditLimit],
  // );

  const creditLimit = (value: number) => <span>{GBPFormatter.format(value)}</span>;

  return (
    <MetricCardBase
      title={t('general.titles.creditLimit')}
      buttonText="Learn More"
      small={small}
      empty={!onBoardingComplete}
      // tooltipTitle={t('general.titles.creditLimit')}
      tooltipMessage={t('pages.dashboard.credit.caption2')}
      avatarIcon={<AvatarIcon />}
      onBoardingComplete={onBoardingComplete}
    >
      {onBoardingComplete ? (
        <div>
          <Box height="50px" display="flex" alignItems="center">
            <Typography variant="h1">
              <CountTo to={businessCreditLimit} speed={timeouts.countTo}>
                {creditLimit}
              </CountTo>
              {/* <Direction direction="up" percentage="0.5" /> */}
            </Typography>
          </Box>

          {/* <Typography variant="caption">
            {t('pages.dashboard.valuation.caption1')}
          </Typography> */}
        </div>
      ) : (
        <>
          <Typography variant="caption">{t('pages.dashboard.credit.caption2')}</Typography>
        </>
      )}
    </MetricCardBase>
  );
};

export default CreditLimit;
