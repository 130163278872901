interface BusinessProfile {
  businessCreditLimit: number;
  businessCreditScore: number;
  businessValuation: number;
}

export const calculateAge = (date: Date | number): number => {
  const birthday = new Date(date);
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const calculateAgePoint = (age: Date | number) => {
  // eslint-disable-next-line no-nested-ternary
  const result = age >= 5 ? 500 : age === 4 ? 400 : age === 3 ? 300 : age === 2 ? 200 : 100;
  return result;
};

const calculateBalancePoint = (balance: number) => {
  const percentage = (balance * 100) / 1000000;
  const balancePoint = (percentage * 250) / 100;
  return balancePoint;
};

const calculateRevenuePoint = (revenue: number) => {
  const percentage = (revenue * 100) / 200000;
  const revenuePoint = (percentage * 250) / 100;
  return revenuePoint;
};

const getCreditLimit = (monthlyRevenue: number) => monthlyRevenue * 1.5;

const getRevenue = (revenue: number, monthlyRevenue: number) => {
  const multipler1 = 5;
  const multiplier2 = 7.5;
  const year = 10;

  const valuation =
    // eslint-disable-next-line no-nested-ternary
    revenue > monthlyRevenue * year
      ? revenue * multipler1
      : revenue <= monthlyRevenue * year
      ? monthlyRevenue * multipler1 * multiplier2
      : 0;

  return valuation;
};

const getBusinessScore = (
  startYear: Date | number, // startYear
  balance: number, // cashBalance
  revenue: number, // lastYearRevenue
) => {
  const actual =
    calculateAgePoint(calculateAge(startYear)) +
    calculateBalancePoint(balance || 0) +
    calculateRevenuePoint(revenue || 0);

  const baseScore = 120;
  const maxScore = 980; // add a max score

  // eslint-disable-next-line no-nested-ternary
  return actual < baseScore ? baseScore : actual > maxScore ? maxScore : Math.round(actual);
};

export const calculateBusinessMetrics = (
  startYear: Date | number,
  cashBalance: number,
  revenue: number,
  monthlyRevenue: number,
): BusinessProfile => ({
  businessValuation: getRevenue(revenue, monthlyRevenue),
  businessCreditLimit: getCreditLimit(monthlyRevenue),
  businessCreditScore: getBusinessScore(startYear, cashBalance, revenue),
});
