import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: theme.palette.background.light,
    },
    guide: {
      position: 'relative',
      paddingTop: '3rem',
    },
    logo: {
      width: 80,
      height: 80,
      position: 'absolute',
      top: '-3rem',
      padding: '.75rem',
      border: `5px solid ${theme.palette.background.light}`,
      borderRadius: '10px',
    },
    actions: {
      paddingTop: '3rem',
    },
    actionButton: {
      minWidth: '142px',
      marginLeft: 20,
    },
    appliedButton: {
      backgroundColor: `${theme.palette.success.main}!important`,
      opacity: 0.6,
    },
    checkIcon: {
      color: theme.palette.common.white,
    },
    providers: {
      marginTop: '2rem',
    },
  }),
);
