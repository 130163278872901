import { Box, TextField } from '@material-ui/core';
import { InfoOutlined as TooltipIcon } from '@material-ui/icons';
import Tooltip from 'components/common/Tooltip';
import { FieldProps } from 'formik';
import React from 'react';
import useTextFieldStyles from './TextField.styles';

interface TooltipTextFieldProps extends FieldProps {
  title: string;
  toolTipMessage: string;
  placeholder?: string;
}

const TooltipTextField: React.SFC<TooltipTextFieldProps> = ({
  title,
  field,
  form,
  placeholder,
  toolTipMessage,
  ...props
}) => {
  const hasError = form.touched[field.name] && form.errors[field.name];
  const classes = useTextFieldStyles();
  return (
    <TextField
      fullWidth
      type="text"
      placeholder={placeholder}
      label={
        <Box display="flex" top="-1rem" position="relative" className={classes.tooltip}>
          <p>{title}</p>
          <Box>
            <Tooltip title={toolTipMessage} Icon={<TooltipIcon />}>
              <div />
            </Tooltip>
          </Box>
        </Box>
      }
      error={form.touched[field.name] && !!form.errors[field.name]}
      helperText={hasError}
      style={hasError ? { paddingBottom: 0 } : {}}
      {...field}
      {...props}
    />
  );
};

export default TooltipTextField;
