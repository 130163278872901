import React, { ReactNode } from 'react';
import MuiPopover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import UserIcon from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

type PopoverProps = {
  // eslint-disable-next-line react/require-default-props
  displayName?: string | null;
  // eslint-disable-next-line react/require-default-props
  photoURL?: string | null;
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode;
};

const Popover: React.FC<PopoverProps> = ({ children, displayName, photoURL }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button color="secondary" onClick={handleClick}>
        {photoURL === '' ? (
          <UserIcon color="primary" />
        ) : (
          <Avatar alt={displayName || ''} src={photoURL || ''} style={{ border: '1px solid #e2e2ea' }} />
        )}
        <ArrowDropDownIcon />
      </Button>
      <MuiPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {children}
      </MuiPopover>
    </>
  );
};

export default Popover;
