import { Button, Dialog, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import ModalActions from './ModalActions';
import ModalContent from './ModalContent';
import ModalTitle from './ModalTitle';

export interface ModalProps {
  open: boolean;
  title: string;
  description: string;
  handleClose: () => void;
  onClickPrimary: () => void;
  busy?: boolean;
  actionButton?: string;
  cancelAction?: boolean;
}

const CustomizedDialogs: FC<ModalProps> = ({
  open,
  handleClose,
  title,
  description,
  onClickPrimary,
  busy,
  actionButton,
  cancelAction = true,
}) => {
  const close = () => {
    if (!busy) {
      handleClose();
    }
  };
  return (
    <div>
      <Dialog onClose={close} aria-labelledby="customized-dialog-title" open={open}>
        <ModalTitle id="customized-dialog-title" onClose={close}>
          {title}
        </ModalTitle>
        <ModalContent dividers>
          <Typography variant="body1">{description}</Typography>
        </ModalContent>

        <ModalActions>
          {cancelAction && (
            <Button color="default" variant="outlined" disabled={busy} onClick={() => close()}>
              Cancel
            </Button>
          )}

          <Button autoFocus variant="contained" color="primary" disabled={busy} onClick={() => onClickPrimary()}>
            {actionButton || 'Confirm'}
          </Button>
        </ModalActions>
      </Dialog>
    </div>
  );
};

export default CustomizedDialogs;
