import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Fade,
  Grid,
  Hidden,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import Emoji from 'components/common/Emoji';
import Markdown from 'components/common/Markdown';
import { timeouts } from 'constants/theme';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import routes from 'router/routes';
import { selectLenderPopup } from 'store/lenders/actions';
import { useLenderCategories, useLendersBusy, useProviders } from 'store/lenders/selectors';
import useStyles from './LenderDetail.styles';

const LenderDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const busy = useLendersBusy();
  const categories = useLenderCategories();
  const providers = useProviders();
  const { goBack } = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const category = useMemo(() => categories.find((l) => l.id === id), [categories, id]);
  if (!category) {
    if (busy) {
      return null;
    }
    return <Redirect to={`/${routes.lenders}`} />;
  }

  const lenderProviders = providers.filter(
    (provider) => category.providers.findIndex((pId) => pId === provider.id) >= 0,
  );

  const onClickApply = () => dispatch(selectLenderPopup(category));

  const Actions = () => (
    <CardActions className={classes.actions}>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <Button className={classes.actionButton} variant="outlined" color="default" disableElevation onClick={goBack}>
          {t('pages.lendersDetail.backButton')}
        </Button>
        {category.appliable && (
          <Button
            className={clsx(classes.actionButton, {
              [classes.appliedButton]: category.applied,
            })}
            color="primary"
            variant="contained"
            disabled={!category.eligibility?.eligible || category.applied}
            onClick={() => onClickApply()}
            startIcon={category.applied ? <CheckIcon className={classes.checkIcon} /> : undefined}
          >
            {t(`pages.lendersDetail.${category.applied ? 'appliedButton' : 'applyButton'}`)}
          </Button>
        )}
      </Box>
    </CardActions>
  );

  return (
    <Fade in timeout={timeouts.fade}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1">
            <Emoji symbol={category.symbol} label={category.name} /> {t(category.name)} -{' '}
            {t('pages.lendersList.card.aka')} &quot;
            {t(category.alias)}&quot;
          </Typography>
        </Grid>

        <Grid item lg={7} xs={12}>
          <Markdown>{t(category.longDescription) as string}</Markdown>
        </Grid>

        <Grid item container spacing={isSmall ? 2 : 8}>
          <Grid item md={7} sm={12}>
            <Card className={classes.card}>
              <CardMedia height={200} component="img" alt="Lender Header" image={category.header} title="lender" />
              <CardContent className={classes.guide}>
                {/* <Avatar
                  src={lender.logo}
                  className={classes.logo}
                  style={{ backgroundColor: lender.logoColor }}
                /> */}
                <Markdown>{t(category.guide) as string}</Markdown>
              </CardContent>
              <Hidden smDown>
                <Actions />
              </Hidden>
            </Card>
          </Grid>
          <Grid item md={5} sm={12} container direction="column" spacing={2}>
            <Grid item>
              <Card className={classes.card}>
                <CardMedia
                  height={200}
                  component="img"
                  alt="Lender Header"
                  image={category.caseHeader}
                  title="lender"
                />
                <CardContent>
                  <Markdown>{t(category.case) as string}</Markdown>
                </CardContent>
                <Hidden mdUp>
                  <Actions />
                </Hidden>
              </Card>
            </Grid>
            <Grid item>
              <Card className={classes.card}>
                <CardContent>
                  <Markdown>{t(category.providersDescription) as string}</Markdown>
                  <Grid container spacing={4} justify="flex-start" className={classes.providers}>
                    {lenderProviders.map((provider) => (
                      <Grid item key={provider.id}>
                        <Tooltip title={provider.name} aria-label="provider-name">
                          <img src={provider.logo} alt={provider.name} width={60} />
                        </Tooltip>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  );
};

export default LenderDetail;
