import React from 'react';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import useSliderStyles from './Slider.styles';

interface SalesPlatform {
  shopify: number | boolean;
  amazon: number | boolean;
  ebay: number | boolean;
  wooCommerce: number | boolean;
  offline: number | boolean;
  other: number | boolean;
}

interface Props {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

const InputSlider: React.FC<{
  slideChange: (value: number | number[], name: string) => void;
  inputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: SalesPlatform | any;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: SalesPlatform | any;
  res: number;
  max: number | null;
  step?: number;
}> = ({ slideChange, inputChange, value, name, state, res, max, step = 10 }) => {
  const classes = useSliderStyles();
  const { t } = useTranslation();
  const sliderMax = 100;
  const ValueLabelComponent: React.FC<Props> = ({ children, open, value }) => (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={(max || 0) <= sliderMax ? value : `${t('pages.onBoarding.sliderError')}`}
    >
      {children}
    </Tooltip>
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>%</Grid>
        <Grid item xs>
          <Slider
            value={typeof value[name] === 'number' ? value[name] : 0}
            onChange={(_e, v) => slideChange(v, name)}
            aria-labelledby={`input-sliders${res}`}
            disabled={!state[name]}
            className={classes.slider}
            ValueLabelComponent={ValueLabelComponent}
            aria-label="custom thumb label"
            defaultValue={20}
          />
        </Grid>
        <Grid item>
          <Input
            className={classes.input}
            name={name}
            value={typeof value[name] === 'number' ? value[name] : 0}
            margin="dense"
            disabled={!state[name]}
            onChange={inputChange}
            inputProps={{
              step: step,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': `input-sliders${res}`,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default InputSlider;
