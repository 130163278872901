import Admin from 'pages/dashboard/admin';
import Company from 'pages/dashboard/company';
import CompanyProfile from 'pages/dashboard/company/company-profile';
import Documents from 'pages/dashboard/company/documents';
import Home from 'pages/dashboard/Home';
import Metrics from 'pages/dashboard/metrics';
import Perks from 'pages/dashboard/perks';
import UserProfile from 'pages/dashboard/userprofile';
import Funding from 'pages/dashboard/funding';
import Investors from 'pages/dashboard/funding/investors';
import { LenderList } from 'pages/dashboard/funding/lenders/components';

// import { LenderDetail, LenderList } from './Lenders/components';

import Grants from 'pages/dashboard/funding/grants';
import routes from 'router/routes';
// import IntegrationPage from 'pages/integration';
import { symbols } from 'components/common/Emoji';

export const companyMenu = [
  {
    path: routes.company,
    name: 'Company',
    icon: symbols.company,
    component: Company,
    new: false,
  },
];

export const companySidebarMenu = [
  {
    path: routes.companyProfile,
    name: 'Details',
    icon: symbols.details,
    component: CompanyProfile,
    new: false,
  },
  {
    path: routes.documents,
    name: 'Documents',
    icon: symbols.documents,
    component: Documents,
    new: false,
  },
];

export const fundingMenu = [
  {
    path: routes.funding,
    name: 'Funding',
    icon: symbols.funding,
    component: Funding,
    new: false,
  },
];

export const fundingSidebarMenu = [
  {
    path: routes.investors,
    name: 'Investment',
    icon: symbols.investors,
    component: Investors,
    new: false,
  },
  {
    path: routes.lenders,
    name: 'Borrowing',
    icon: symbols.lenders,
    component: LenderList,
    new: false,
  },
  {
    path: routes.grants,
    name: 'Grants',
    icon: symbols.grants,
    component: Grants,
    new: false,
  },
];

export const perksMenu = [
  {
    path: routes.perks,
    name: 'Perks',
    new: false,
    icon: symbols.perks,
    component: Perks,
  },
];

export const menu = [
  {
    path: routes.home,
    name: 'Home',
    icon: symbols.home,
    component: Home,
    new: false,
  },
];

export const dashboardMenu = [
  {
    path: routes.dashboard,
    name: 'Dashboard',
    icon: symbols.dashboard,
    component: Metrics,
    new: false,
    props: {},
  },
  // {
  //   path: routes.integration,
  //   name: 'Integrations',
  //   icon: symbols.integration,
  //   component: IntegrationPage,
  //   new: false,
  //   props: {
  //     exact: false,
  //   },
  // },
];

export const adminMenu = [
  {
    path: `${routes.admin}`,
    name: 'Admin',
    icon: symbols.admin,
    component: Admin,
    new: false,
  },
];

export const menuBottom = [
  {
    path: `${routes.profile}`,
    name: 'Profile',
    icon: symbols.profile,
    component: UserProfile,
    new: false,
  },
];
