import React, { FC, useCallback } from 'react';
import { CompanyDirector } from 'store/dashboard/types';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, FieldArray, FormikTouched, FormikErrors } from 'formik';
import {
  Grid,
  Box,
  Button,
  NativeSelect,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import UserAlert, { Status, UserAlertProps } from 'components/common/UserAlert';
import ToolTipTextField from 'components/common/ToolTipTextField';
import { useOnboardLoadingAndErrorState } from 'store/dashboard/selectors';
import FormBase from '../FormBase';
import useFormStyles from '../form.styles';
import InputLabelComponent from 'components/common/ToolTipInputLabel';
import { Address } from 'entities/dashboard';
import NumberFormatCustom from 'components/common/NumberFormatCustom';
import clsx from 'clsx';
import { noop, timeDiffCalc } from 'utils';
import yup from './yup-extended';
import {
  residentialStatusOptions,
  residentialAddressInitial,
  initialFormValues,
  currentAddressOptions,
} from './constants';
import SubmitButtons from '../SubmitButtons';

const today = new Date();

const isAddressAgeValid = (addresses: Partial<Address>[] | null) => {
  if (!addresses) return true;

  let isValid = false;
  addresses.forEach((address) => {
    if (address.addressStartDate) {
      const timeDiff = timeDiffCalc(today, address.addressStartDate, 'years');
      if (timeDiff >= 3) {
        isValid = true;
      }
    }
  });
  return isValid;
};

const getAddressFieldError = (
  touched: FormikTouched<FormValues>,
  errors: FormikErrors<FormValues>,
  index: number,
  fieldName: string,
): boolean => {
  return (
    touched.residentialAddress &&
    touched.residentialAddress[index] &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    touched.residentialAddress[index][fieldName] &&
    errors.residentialAddress &&
    typeof errors.residentialAddress !== 'string' &&
    (errors.residentialAddress[index] as Partial<Address>) &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (errors.residentialAddress[index] as Partial<Address>)[fieldName]
  );
};

interface FormValues {
  id: string | null;
  name: string | null;
  email: string | null;
  contactNumber: string | null;
  residentialAddress: Partial<Address>[];
}

interface AboutYouFormProps {
  directors: CompanyDirector[] | null;
  onClickPrimary?: () => void;
  onClickSecondary?: () => void;
  primaryButtonTitle?: string;
}

const AboutYouForm: FC<AboutYouFormProps> = ({
  directors,
  onClickPrimary = noop,
  onClickSecondary,
  primaryButtonTitle,
}) => {
  const [openAlert, setOpenAlert] = React.useState<UserAlertProps>({
    open: false,
    message: '',
    status: Status.ERROR,
  });
  const classes = useFormStyles();
  const { t } = useTranslation();

  const applicantSchema = yup.object({
    id: yup.string().nullable(),
    name: yup
      .string()
      .min(3, t('pages.onBoarding.applicantNameValidate'))
      .required(t('pages.onBoarding.applicantNameValidate'))
      .nullable(),
    email: yup
      .string()
      .email(t('pages.onBoarding.applicantEmailValidate'))
      .required(t('pages.onBoarding.applicantEmailValidate'))
      .nullable(),
    contactNumber: yup.string().required(t('pages.onBoarding.applicantContantNumberValidate')).nullable(),
    residentialAddress: yup
      .array(
        yup.object({
          houseNumber: yup.string().required(t('pages.onBoarding.applicantHouseNumberValidate')).nullable(),
          addressLine1: yup.string().required(t('pages.onBoarding.applicantAddressLine1Validate')).nullable(),
          addressLine2: yup.string().nullable(),
          city: yup.string().required(t('pages.onBoarding.applicantCityValidate')).nullable(),
          county: yup.string().required(t('pages.onBoarding.applicantCountyValidate')).nullable(),
          country: yup.string().required(t('pages.onBoarding.applicantCountryValidate')).nullable(),
          postcode: yup.string().required(t('pages.onBoarding.applicantPostcodeValidate')).nullable(),
          current: yup.boolean(),
          addressStartDate: yup.date().required(t('pages.onBoarding.applicantAddressStartDateValidate')).nullable(),
          status: yup.string().required(t('pages.onBoarding.applicantResidentialStatusValidate')).nullable(),
          propertyEquity: yup
            .number()
            .when('status', {
              is: (val: string | null) => val?.includes('homeowner'),
              then: yup
                .number()
                .min(0, t('pages.onBoarding.applicantPropertyEquityValidate'))
                .required(t('pages.onBoarding.applicantPropertyEquityValidate'))
                .nullable(),
            })
            .nullable(),
        }),
      )
      .oneCurrent(t('pages.onBoarding.applicantCurrentValidate'), (a: Partial<Address>) => a.current)
      .uniqueCurrent(t('pages.onBoarding.applicantDuplicateCurrentWarning'), (a: Partial<Address>) => a.current),
  });

  const { loading, patchApplicant } = useOnboardLoadingAndErrorState(setOpenAlert, onClickPrimary);

  const { open, message, status } = openAlert;

  const handleClose = useCallback((event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert({ open: false });
  }, []);

  const applicant = (directors?.filter((dir) => dir.applicant)[0] as FormValues) || initialFormValues;

  const initialValues = {
    id: applicant.id,
    name: applicant.name,
    email: applicant.email,
    contactNumber: applicant.contactNumber,
    residentialAddress: applicant.residentialAddress,
  };

  const onSubmit = (values: FormValues) => {
    const payload = { ...values };

    const residentialAddress = values.residentialAddress.map((resAddr) => {
      const addr = { ...resAddr };
      if (addr.propertyEquity) addr.propertyEquity = Number(addr.propertyEquity);
      return addr;
    });

    payload.residentialAddress = residentialAddress;
    patchApplicant(payload);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDirectorChange = (value: string, values: FormValues, setValues: (values: FormValues) => void) => {
    const selectedDirector = directors?.find((dir) => dir.id === value);
    if (selectedDirector) {
      const updatedValues = { ...values, id: selectedDirector.id, name: selectedDirector.name };
      if (selectedDirector.email) updatedValues.email = selectedDirector.email;
      if (selectedDirector.contactNumber) updatedValues.contactNumber = selectedDirector.contactNumber;
      if (selectedDirector.residentialAddress) {
        updatedValues.residentialAddress = selectedDirector.residentialAddress.map((resAddr) => {
          const addr = { ...resAddr };
          // Set default value for status if not already present
          if (!addr.status) addr.status = residentialAddressInitial.status;
          if (!addr.addressStartDate) addr.addressStartDate = residentialAddressInitial.addressStartDate;
          return addr;
        });
      }
      setValues(updatedValues);
    }
  };

  return (
    <FormBase>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={applicantSchema} enableReinitialize>
        {({ handleChange, values, errors, touched, setValues, setFieldValue, submitCount }) => {
          const validAddressAge = isAddressAgeValid(values.residentialAddress);

          return (
            <Form noValidate>
              <Grid container item direction="column" spacing={4}>
                {directors && directors.length > 0 && (
                  <Grid item>
                    <InputLabelComponent htmlFor="id" id="id" label={t('pages.onBoarding.directorChoiceLabel')} />

                    <RadioGroup
                      aria-label="unpaid-invoices"
                      onChange={(e) => handleDirectorChange(e.target.value, values, setValues)}
                      name="id"
                      value={values.id || ''}
                    >
                      {directors.map((option) => (
                        <FormControlLabel
                          key={option.id}
                          value={option.id}
                          control={<Radio color="primary" />}
                          label={option.name}
                        />
                      ))}
                    </RadioGroup>
                  </Grid>
                )}

                <Grid item>
                  <Field
                    name="name"
                    onChange={handleChange}
                    component={ToolTipTextField}
                    label={t('pages.onBoarding.applicantNameLabel')}
                    value={values.name || ''}
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    name="email"
                    onChange={handleChange}
                    component={ToolTipTextField}
                    label={t('pages.onBoarding.applicantEmailLabel')}
                    value={values.email || ''}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    name="contactNumber"
                    onChange={handleChange}
                    component={ToolTipTextField}
                    label={t('pages.onBoarding.applicantContactNumberLabel')}
                    value={values.contactNumber || ''}
                    error={touched.contactNumber && !!errors.contactNumber}
                    helperText={touched.contactNumber && errors.contactNumber}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <FieldArray name="residentialAddress">
                    {({ insert, remove, push }) => {
                      return (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div>
                            {values.residentialAddress &&
                              values.residentialAddress.length > 0 &&
                              values.residentialAddress.map((address, index) => {
                                return (
                                  <Grid container item direction="column" spacing={4} key={index}>
                                    <Box className={classes.personalAddressContainer}>
                                      <Typography variant="subtitle2">
                                        {t('pages.onBoarding.applicantAddressSectionTitle')} {index + 1}
                                      </Typography>

                                      {index !== 0 && (
                                        <IconButton
                                          color="primary"
                                          aria-label={`delete address ${index + 1}`}
                                          component="span"
                                          onClick={() => remove(index)}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      )}
                                    </Box>

                                    <Grid item>
                                      <Field
                                        id={`residentialAddress.${index}.houseNumber`}
                                        fullWidth
                                        component={TextField}
                                        name={`residentialAddress.${index}.houseNumber`}
                                        value={address.houseNumber || ''}
                                        onChange={handleChange}
                                        label={t('pages.onBoarding.applicantHouseNumberLabel')}
                                        error={getAddressFieldError(touched, errors, index, 'houseNumber')}
                                        helperText={getAddressFieldError(touched, errors, index, 'houseNumber')}
                                        margin="normal"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        placeholder="e.g. 277EA"
                                        className={classes.textInput}
                                      />
                                    </Grid>

                                    <Grid item>
                                      <Field
                                        id={`residentialAddress.${index}.addressLine1`}
                                        fullWidth
                                        component={TextField}
                                        name={`residentialAddress.${index}.addressLine1`}
                                        value={address.addressLine1 || ''}
                                        onChange={handleChange}
                                        label={t('pages.onBoarding.applicantAddressLine1Label')}
                                        error={getAddressFieldError(touched, errors, index, 'addressLine1')}
                                        helperText={getAddressFieldError(touched, errors, index, 'addressLine1')}
                                        margin="normal"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        placeholder="e.g. Address Line 1"
                                        className={classes.textInput}
                                      />
                                    </Grid>

                                    <Grid item>
                                      <Field
                                        id={`residentialAddress.${index}.addressLine2`}
                                        fullWidth
                                        component={TextField}
                                        name={`residentialAddress.${index}.addressLine2`}
                                        value={address.addressLine2 || ''}
                                        onChange={handleChange}
                                        label={t('pages.onBoarding.applicantAddressLine2Label')}
                                        error={getAddressFieldError(touched, errors, index, 'addressLine2')}
                                        helperText={getAddressFieldError(touched, errors, index, 'addressLine2')}
                                        margin="normal"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        placeholder="e.g. Address Line 2"
                                        className={classes.textInput}
                                      />
                                    </Grid>

                                    <Grid item>
                                      <Field
                                        id={`residentialAddress.${index}.city`}
                                        fullWidth
                                        component={TextField}
                                        name={`residentialAddress.${index}.city`}
                                        value={address.city || ''}
                                        onChange={handleChange}
                                        label={t('pages.onBoarding.applicantCityLabel')}
                                        error={getAddressFieldError(touched, errors, index, 'city')}
                                        helperText={getAddressFieldError(touched, errors, index, 'city')}
                                        margin="normal"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        placeholder="e.g. City"
                                        className={classes.textInput}
                                      />
                                    </Grid>

                                    <Grid item>
                                      <Field
                                        id={`residentialAddress.${index}.county`}
                                        fullWidth
                                        component={TextField}
                                        name={`residentialAddress.${index}.county`}
                                        value={address.county || ''}
                                        onChange={handleChange}
                                        label={t('pages.onBoarding.applicantCountyLabel')}
                                        error={getAddressFieldError(touched, errors, index, 'county')}
                                        helperText={getAddressFieldError(touched, errors, index, 'county')}
                                        margin="normal"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        placeholder="e.g. London"
                                        className={classes.textInput}
                                      />
                                    </Grid>

                                    <Grid item>
                                      <Field
                                        id={`residentialAddress.${index}.country`}
                                        fullWidth
                                        component={TextField}
                                        name={`residentialAddress.${index}.country`}
                                        value={address.country || ''}
                                        onChange={handleChange}
                                        label={t('pages.onBoarding.applicantCountryLabel')}
                                        error={getAddressFieldError(touched, errors, index, 'country')}
                                        helperText={getAddressFieldError(touched, errors, index, 'country')}
                                        margin="normal"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        placeholder="e.g. England"
                                        className={classes.textInput}
                                      />
                                    </Grid>

                                    <Grid item>
                                      <Field
                                        id={`residentialAddress.${index}.postcode`}
                                        fullWidth
                                        component={TextField}
                                        name={`residentialAddress.${index}.postcode`}
                                        value={address.postcode || ''}
                                        onChange={handleChange}
                                        label={t('pages.onBoarding.applicantPostcodeLabel')}
                                        error={getAddressFieldError(touched, errors, index, 'postcode')}
                                        helperText={getAddressFieldError(touched, errors, index, 'postcode')}
                                        margin="normal"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        placeholder="e.g. EX187"
                                        className={classes.textInput}
                                      />
                                    </Grid>

                                    <Grid item>
                                      <InputLabelComponent
                                        htmlFor={`residentialAddress.${index}.current`}
                                        id={`residentialAddress.${index}.current`}
                                        label={t('pages.onBoarding.applicantCurrentLabel')}
                                      />

                                      <RadioGroup
                                        aria-label="current-address"
                                        value={address.current}
                                        onChange={(e) => {
                                          const value = e.target.value === 'true';
                                          setFieldValue(`residentialAddress.${index}.current`, value);
                                        }}
                                        name={`residentialAddress.${index}.current`}
                                      >
                                        {currentAddressOptions.map((option) => (
                                          <FormControlLabel
                                            key={option.label}
                                            value={option.value}
                                            control={<Radio color="primary" />}
                                            label={option.label}
                                          />
                                        ))}
                                      </RadioGroup>
                                    </Grid>

                                    <Grid item style={{ textAlign: 'left' }}>
                                      <DatePicker
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id={`residentialAddress.${index}.addressStartDate`}
                                        name={`residentialAddress.${index}.addressStartDate`}
                                        label={t('pages.onBoarding.applicantAddressStartDateLabel')}
                                        error={getAddressFieldError(touched, errors, index, 'addressStartDate')}
                                        helperText={getAddressFieldError(touched, errors, index, 'addressStartDate')}
                                        value={address.addressStartDate}
                                        onChange={(date) => {
                                          setFieldValue(`residentialAddress.${index}.addressStartDate`, date);
                                        }}
                                      />
                                    </Grid>

                                    <Grid item>
                                      <InputLabelComponent
                                        htmlFor={`residentialAddress.${index}.status`}
                                        id={`residentialAddress.${index}.status`}
                                        label={t('pages.onBoarding.applicantResidentialStatusLabel')}
                                        message={t('pages.onBoarding.applicantResidentialStatusLabelToolTip')}
                                      />
                                      <NativeSelect
                                        fullWidth
                                        value={address.status || ''}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFieldValue(`residentialAddress.${index}.status`, value);
                                          if (!value?.includes('homeowner')) {
                                            setFieldValue(`residentialAddress.${index}.propertyEquity`, null);
                                          }
                                        }}
                                        inputProps={{ name: 'residentialStatus', id: 'residentialStatus' }}
                                        placeholder="e.g Tenant"
                                        name={`residentialAddress.${index}.status`}
                                        error={getAddressFieldError(touched, errors, index, 'status')}
                                      >
                                        {residentialStatusOptions.map((rSOption, index) => (
                                          // eslint-disable-next-line react/no-array-index-key
                                          <option key={`rSOption${index}`} value={rSOption.value}>
                                            {rSOption.label}
                                          </option>
                                        ))}
                                      </NativeSelect>
                                    </Grid>

                                    {address.status?.includes('homeowner') && (
                                      <Grid item>
                                        <Field
                                          id={`residentialAddress.${index}.propertyEquity`}
                                          fullWidth
                                          component={ToolTipTextField}
                                          name={`residentialAddress.${index}.propertyEquity`}
                                          value={address.propertyEquity || ''}
                                          onChange={handleChange}
                                          title={t('pages.onBoarding.applicantPropertyEquityLabel')}
                                          toolTipMessage={t('pages.onBoarding.applicantPropertyEquityLabelToolTip')}
                                          error={getAddressFieldError(touched, errors, index, 'propertyEquity')}
                                          helperText={getAddressFieldError(touched, errors, index, 'propertyEquity')}
                                          margin="normal"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          InputProps={{
                                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            inputComponent: NumberFormatCustom as any,
                                          }}
                                          placeholder="10000"
                                          className={classes.textInput}
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                );
                              })}

                            <Button
                              color="primary"
                              onClick={() => push(residentialAddressInitial)}
                              className={clsx([classes.btn_sec, classes.personalAddressAdd])}
                              variant="contained"
                            >
                              {t('pages.onBoarding.applicantAddAddressButton')}
                            </Button>
                          </div>
                        </MuiPickersUtilsProvider>
                      );
                    }}
                  </FieldArray>
                </Grid>

                {typeof errors.residentialAddress === 'string' && (
                  <Typography variant="subtitle2" className={classes.personalAddressDateWarning}>
                    {errors.residentialAddress}
                  </Typography>
                )}

                {!(typeof errors.residentialAddress === 'string') && !validAddressAge && (
                  <Typography variant="subtitle2" className={classes.personalAddressDateWarning}>
                    {t('pages.onBoarding.applicantAddressDateWarning')}
                  </Typography>
                )}

                <SubmitButtons
                  onClickSecondary={onClickSecondary}
                  loading={loading}
                  primaryButtonTitle={primaryButtonTitle}
                />
              </Grid>
            </Form>
          );
        }}
      </Formik>

      <UserAlert open={open} status={status} message={message} handleClose={handleClose} />
    </FormBase>
  );
};

export default AboutYouForm;
