import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PlayCircleFilledWhite as GetStartedIcon,
  Business as FundingGoalsIcon,
  Assessment as BusinessDetailsIcon,
  AccountCircle as AboutYouIcon,
  ShowChart as BusinessPerformanceIcon,
  DoneAll as CongratsIcon,
} from '@material-ui/icons';
import {
  Start,
  FundingGoalsForm,
  BusinessDetailsForm,
  AboutYouForm,
  BusinessPerformanceForm,
  Finish,
  Stepper,
} from 'components/onboarding';
import { ThemeModeToggle } from 'components';
import { useHistory } from 'react-router-dom';
import routes from 'router/routes';
import { useDashboardState } from 'store/dashboard/selectors';
import useOnboardingStyles from './onBoarding.styles';

export const icons: { [index: string]: React.ReactElement } = {
  1: <GetStartedIcon />,
  2: <FundingGoalsIcon />,
  3: <BusinessDetailsIcon />,
  4: <AboutYouIcon />,
  5: <BusinessPerformanceIcon />,
  6: <CongratsIcon />,
};

const OnBoarding: React.FC = () => {
  const { company, onBoardingComplete } = useDashboardState();
  const history = useHistory();
  const [slide, setSlide] = useState(0);
  const { t } = useTranslation();
  const classes = useOnboardingStyles();

  useEffect(() => {
    if (slide === 0 && onBoardingComplete) {
      history.push(routes.home);
    }
  }, [slide, onBoardingComplete, history]);

  const getSteps = () => {
    return [
      t('pages.onBoarding.slide0Header'),
      t('pages.onBoarding.slide1Header'),
      t('pages.onBoarding.slide2Header'),
      t('pages.onBoarding.slide3Header'),
      t('pages.onBoarding.slide4Header'),
      t('pages.onBoarding.slide5Header'),
    ];
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return <Start onClickPrimary={() => setSlide(1)} onClickSecondary={() => history.goBack()} />;
      case 1:
        return (
          <FundingGoalsForm
            formValues={company}
            onClickPrimary={() => setSlide(2)}
            onClickSecondary={() => setSlide(0)}
          />
        );
      case 2:
        return (
          <BusinessDetailsForm
            formValues={company}
            onClickPrimary={() => setSlide(3)}
            onClickSecondary={() => setSlide(1)}
          />
        );
      case 3:
        return (
          <AboutYouForm
            directors={company.directors}
            onClickPrimary={() => setSlide(4)}
            onClickSecondary={() => setSlide(2)}
          />
        );
      case 4:
        return (
          <BusinessPerformanceForm
            formValues={company}
            onClickPrimary={() => setSlide(5)}
            onClickSecondary={() => setSlide(3)}
            finalStep
          />
        );
      case 5:
        return <Finish onClickPrimary={() => history.push(routes.home)} />;
      default:
        return 'Unknown stepIndex';
    }
  };

  // important change to increase number of slides
  const stepCount = 6;

  return (
    <Box className={classes.root}>
      <Box className={classes.title} />

      <Stepper steps={getSteps()} getStepContent={getStepContent} length={stepCount} activeStep={slide} />

      <Box position="fixed" top="0" right="0">
        <ThemeModeToggle />
      </Box>
    </Box>
  );
};

export default OnBoarding;
