import Loader from 'components/common/Loader';
import Navbar from 'components/common/Navbar';
import Container from 'components/dashboard/Container';
import Sidebar from 'components/dashboard/sidebar';
import { adminMenu, companyMenu, fundingMenu, menu, menuBottom, perksMenu, dashboardMenu } from 'constants/data/menu';
import React, { useEffect, useState, useMemo } from 'react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom';
import routes from 'router/routes';
import { useAdmin, useAuth, useProfile } from 'store/firebase/selectors';
import useLayoutStyles from './Layout.styles';

interface MatchParams {
  name: string;
}

type DashboardProps = RouteComponentProps<MatchParams>;

const Dashboard: React.FC<DashboardProps> = () => {
  const classes = useLayoutStyles();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const userProfile = useProfile();

  const handleDrawerToggle = () => {
    setMobileNavOpen(!mobileNavOpen);
  };
  const auth = useAuth();

  const currentUser = useMemo(
    () => ({
      email: auth.email,
      displayName: userProfile.displayName || auth.displayName || '',
      photoURL: auth.photoURL,
    }),
    [auth, userProfile],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    currentUser.displayName !== null && setLoading(false);
  }, [currentUser]);

  const isAdmin = useAdmin();

  return (
    <div className={classes.root}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Navbar mobileNavToggle={handleDrawerToggle} currentUser={currentUser} />

          <div className={classes.flex}>
            <Sidebar mobileNavOpen={mobileNavOpen} mobileNavToggle={handleDrawerToggle} />
            <Container>
              <Switch>
                <Redirect exact from="/d" to={`/${routes.home}`} />

                {menu.map((menuRoute) => (
                  <Route
                    exact
                    path={`/${menuRoute.path}`}
                    key={menuRoute.path}
                    render={(props) => <menuRoute.component {...props} currentUser={currentUser} />}
                  />
                ))}

                {dashboardMenu.map((menuRoute) => (
                  <Route
                    exact
                    path={`/${menuRoute.path}`}
                    key={menuRoute.path}
                    render={(props) => <menuRoute.component {...props} />}
                    {...menuRoute.props}
                  />
                ))}

                {companyMenu.map((menuRoute) => (
                  <Route path={`/${menuRoute.path}`} key={menuRoute.path} render={() => <menuRoute.component />} />
                ))}
                {fundingMenu.map((menuRoute) => (
                  <Route path={`/${menuRoute.path}`} key={menuRoute.path} render={() => <menuRoute.component />} />
                ))}
                {perksMenu.map((menuRoute) => (
                  <Route path={`/${menuRoute.path}`} key={menuRoute.path} render={() => <menuRoute.component />} />
                ))}

                {isAdmin &&
                  adminMenu.map((menuRoute) => (
                    <Route path={`/${menuRoute.path}`} key={menuRoute.path} render={() => <menuRoute.component />} />
                  ))}

                {menuBottom.map((menuRoute) => (
                  <Route
                    exact
                    path={`/${menuRoute.path}`}
                    key={menuRoute.path}
                    render={(props) => <menuRoute.component {...props} currentUser={currentUser} />}
                  />
                ))}
              </Switch>
            </Container>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
