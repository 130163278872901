import React, { FC } from 'react';
import { AppBar, Box, Divider, Hidden, IconButton, makeStyles, Theme, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Logo } from 'components';
import Menu from 'components/dashboard/Menu';
import { CurrentUser } from 'store/firebase/types';
import { useShowIntercom } from 'core/constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: { zIndex: 1210 },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  helpButton: {},
}));

interface NavbarProps {
  mobileNavToggle?: () => void;
  currentUser: CurrentUser;
  className?: string;
}

const Navbar: FC<NavbarProps> = ({ mobileNavToggle, currentUser, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const showIntercomNewMessage = useShowIntercom();

  return (
    <AppBar elevation={0} className={classes.root} position="fixed" {...rest}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton onClick={mobileNavToggle} className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
        </Hidden>

        <RouterLink to="/">
          <Logo height="38px" width="100px" />
        </RouterLink>

        <Box flexGrow={1} />

        <IconButton onClick={() => showIntercomNewMessage(t('services.intercom.help'))} className={classes.helpButton}>
          <HelpIcon />
        </IconButton>

        <Menu currentUser={currentUser} />
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

export default Navbar;
