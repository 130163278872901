import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    // minWidth: 300,
    backgroundColor: theme.palette.background.light,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    borderRadius: theme.shape.borderRadius,
  },
  small: {
    height: 200,
    width: '100%',
    justifyContent: 'space-between',
    // alignItems: 'center',
  },
  cardContent: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    padding: 0,
    '&:last-child': {
      paddingBottom: '1rem',
      paddingLeft: '2rem',
    },
  },
  title: {
    textTransform: 'uppercase',
  },
  empty: {
    color: theme.palette.common.black,
  },
  smallTitle: {
    fontWeight: 'bold',
    fontVariantCaps: 'all-small-caps',

    fontSize: 13,
    marginBottom: '0.35em',
  },
  cardActions: {
    justifyContent: 'center',
  },
  childrenContent: {
    justifyContent: 'space-around',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    marginTop: '-13px',
  },
  icon: {
    color: theme.palette.text.secondary,
    width: 20,
    height: 20,
  },
  avatar: {
    height: 56,
    width: 56,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.neutral.dark,
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));
