import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '3rem',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: 'black',
      [theme.breakpoints.down('sm')]: {
        marginTop: '1rem',
        // height: '100vh',
      },
    },
    btn_pri: {},
    btn_sec: {},
    textInput: {
      margin: theme.spacing(0),
      minWidth: 500,
      textAlign: 'left',

      [theme.breakpoints.down('sm')]: {
        minWidth: 300,
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    },
    textInputNoMin: {
      margin: theme.spacing(0),
      textAlign: 'left',

      [theme.breakpoints.down('sm')]: {
        // minWidth: 300,
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    },
    btn: {
      textAlign: 'center',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
    },
    h1: {
      fontSize: '36px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
      },
    },
    title: {
      textAlign: 'center',
    },
    card: {
      backgroundColor: theme.palette.common.white,
      borderRadius: '20px',
      width: '35rem',
      padding: '2rem',
      marginBottom: '3rem',
      [theme.breakpoints.down('sm')]: {
        width: '100vw',
      },
    },

    year: {
      '&:focus': {
        boxShadow: '0 0 5px #1397ff',
        border: '2px solid #1397ff',
        outline: 'none',
      },
    },
    select: {},
    btnDiv: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(3),
    },
    slide3: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
      },
    },
    white: {
      margin: theme.spacing(1) * 2,

      display: 'flex',
      flexDirection: 'column',
      paddingTop: '2rem',
      paddingBottom: '2rem',
      justifyContent: 'space-between',
      alignItems: 'center',
      textAlign: 'center',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      border: '2px solid #0062ff',
      borderRadius: '20px',
      // height: '100%',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        height: '13rem',
      },
    },
    whiteh4: {
      fontWeight: 600,
      marginBottom: '0.5rem',
    },
    whitep: {
      width: '14rem',
      fontSize: '14px',
      marginBottom: '0.5rem',
    },
    whitebtnDiv: {
      height: '3.5rem',
      width: '90%',
      borderRadius: '10px',
      color: theme.palette.common.white,
      border: `2px solid ${theme.palette.primary.main}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    blue: {
      // height: '100%',
      opacity: 0.3,
      margin: theme.spacing(1) * 2,
      display: 'flex',
      paddingTop: '2rem',
      paddingBottom: '2rem',
      textAlign: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderRadius: '20px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        marginTop: '0.5rem',
      },
    },
    blueh4: {
      fontWeight: 600,
      marginBottom: '0.5rem',
    },
    bluep: {
      width: '14rem',
      fontSize: '14px',
      marginBottom: '0.5rem',
    },
    bluebtnDiv: {
      height: '3.5rem',
      width: '90%',
      backgroundColor: 'white',
      color: theme.palette.primary.main,
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    welcomeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    tooltip: {
      backgroundColor: theme.palette.background.default,
    },
    formControl: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
      border: 'none',
    },
    slider: {
      [theme.breakpoints.up('sm')]: {
        marginTop: '14px',
      },
    },
    slider1: {
      [theme.breakpoints.up('sm')]: {
        marginTop: '10px',
      },
    },
    formControlLabel: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: '7px',
      },
    },
    personalAddressContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    personalAddressAdd: {
      marginTop: 30,
      marginBottom: 10,
    },
    personalAddressDateWarning: {
      color: theme.palette.error.main,
    },
    formGridContainer: {
      '& > div': {
        width: '100%',
      },
    },
    ecomLogos: {
      height: '20px',
    },
  }),
);
