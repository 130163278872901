import { Grid, Paper, Typography } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import MoonIcon from '@material-ui/icons/Brightness3';
import SunIcon from '@material-ui/icons/Brightness7';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { userToggleThemeMode } from 'store/user/actions';
import { useThemeMode } from 'store/user/selectors';

const ThemeModeToggle: FC<{ useLabel?: boolean }> = ({ useLabel }) => {
  const themeMode = useThemeMode();
  const dispatch = useDispatch();

  const toggleTheme = () => {
    dispatch(userToggleThemeMode());
  };

  return (
    <Paper elevation={0}>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="body1">{useLabel ? `${themeMode} mode` : ''}</Typography>
        </Grid>
        <Grid item>
          <Switch
            checked={themeMode === 'dark'}
            onChange={() => toggleTheme()}
            name="ThemeToggle"
            icon={<SunIcon />}
            checkedIcon={<MoonIcon />}
            size="medium"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ThemeModeToggle;
