import { Avatar, Fade, Grid, makeStyles, Typography, Theme } from '@material-ui/core';
import { ReactComponent as CompanyImage } from 'assets/icon/company.svg';
import LoadingButton from 'components/common/LoadingButton';
import BusinessCreditScore from 'components/dashboard/BusinessCreditScore';
import BusinessValuation from 'components/dashboard/BusinessValuation';
// import StyledTabs from 'components/dashboard/CompanyProfile/StyledTab';
import TabPanels from 'components/dashboard/CompanyProfile/TabPanels';
import CreditLimit from 'components/dashboard/CreditLimit';
import { timeouts } from 'constants/theme';
import { postCompany } from 'http/dashboard';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routes from 'router/routes';
import { CreateCompany } from 'store/dashboard/actions';
import { useDashboardState } from 'store/dashboard/selectors';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: '56px',
    width: '56px',
    marginRight: '30px',
    border: '1px solid #e2e2ea',
    background: theme.palette.common.white,
  },
  heading: {
    textTransform: 'capitalize',
  },
  headerGrid: { display: 'flex', flexDirection: 'row' },
}));

const CompanyProfile: FC = () => {
  const {
    company,
    businessProfile: { businessCreditLimit, businessCreditScore, businessValuation },
    company: { website, registeredName, id, onboardingStep },
    onBoardingComplete,
  } = useDashboardState();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  // const [value, setValue] = useState(0);
  const [companyCreateLoading, setCompanyCreateLoading] = useState(false);

  const navigateOnboard = () => {
    history.push(routes.onboarding);
  };

  const onboardCompany = async () => {
    // companyExists and onboarding is not complete
    if (onboardingStep !== 0 && !onBoardingComplete) {
      navigateOnboard();
      return;
    }

    if (!id) {
      // !onboarded and !companyExists, create newCompany
      try {
        setCompanyCreateLoading(true);
        const res = await postCompany();
        if (res) {
          dispatch(CreateCompany(res));
          navigateOnboard();
        }
        setCompanyCreateLoading(false);
      } catch (error) {
        setCompanyCreateLoading(false);
        if (process.env.NODE_ENV === 'development') {
          // console.log('error', error);
          navigateOnboard();
        }
      }
    } else {
      navigateOnboard();
    }
  };

  // const handleChange = (_: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  const companyTitle: string = registeredName || t('pages.companyDetails.title');

  return (
    <Fade in timeout={timeouts.fade}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.headerGrid}>
          {website && (
            <Avatar
              alt={`${registeredName}`}
              src={`//logo.clearbit.com/${website}?size=80`}
              className={classes.avatar}
              variant="square"
            />
          )}

          <Typography variant="h3" className={classes.heading}>
            {companyTitle}
          </Typography>
        </Grid>

        {onBoardingComplete ? (
          <>
            <Grid item sm={12} md={8}>
              <Grid container spacing={3}>
                {/* <Grid item sm={12} md={4}>
                  <StyledTabs
                    value={value}
                    handleChange={handleChange}
                    tabText={[
                      {
                        title: t('pages.companyDetails.tabs.title1'),
                        subtitle: t('pages.companyDetails.tabs.subtitle1'),
                      },
                      {
                        title: t('pages.companyDetails.tabs.title2'),
                        subtitle: t('pages.companyDetails.tabs.subtitle2'),
                      },
                      {
                        title: t('pages.companyDetails.tabs.title3'),
                        subtitle: t('pages.companyDetails.tabs.subtitle3'),
                      },
                    ]}
                  />
                </Grid> */}
                <Grid item sm={12} md={12}>
                  <TabPanels company={company} /* value={value} */ />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={4} xs={12}>
              <Grid container spacing={3}>
                {/* <CompanyCard
                    name={registeredName || ''}
                    img={`//logo.clearbit.com/${website}?size=80`}
                    creditLimit={GBPFormatter.format(businessCreditLimit)}
                    valuation={GBPFormatter.format(businessValuation)}
                    businessScore={`${businessCreditScore}/999`}
                  /> */}

                <Grid item xs={12}>
                  <BusinessValuation businessValuation={businessValuation} onBoardingComplete={onBoardingComplete} />
                </Grid>
                <Grid item xs={12}>
                  <CreditLimit businessCreditLimit={businessCreditLimit} onBoardingComplete={onBoardingComplete} />
                </Grid>
                <Grid item xs={12}>
                  <BusinessCreditScore score={businessCreditScore} onBoardingComplete={onBoardingComplete} />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container justify="center" alignContent="center" style={{ textAlign: 'center' }}>
              <Grid item xs={12} md={12}>
                <CompanyImage height="200px" />
              </Grid>

              <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                <Typography variant="caption" gutterBottom display="block">
                  {t('pages.companyDetails.cta')}
                </Typography>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  loading={companyCreateLoading}
                  onClick={onboardCompany}
                >
                  {t('pages.companyDetails.buttonEmpty')}
                </LoadingButton>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Fade>
  );
};

export default CompanyProfile;
