import * as firebase from 'firebase/app';
import 'firebase/auth';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';

import App from './App';
import './assets/fonts/fonts.css';
import './i18n';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { store } from './store';

firebase.initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG as string));
firebase.firestore();

const { dispatch } = store;

const rrfProps = {
  firebase,
  config: {
    userProfile: 'users',
    useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  },
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

const renderApp = () =>
  render(
    <React.StrictMode>
      <Provider store={store}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ReactReduxFirebaseProvider firebase={firebase} dispatch={dispatch} config={{}} {...rrfProps}>
          <App />
        </ReactReduxFirebaseProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp);
}

renderApp();
