/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fade, Grid, Link, Typography, Box } from '@material-ui/core';
import { openCalendly } from 'components/common/CalendlyPopup';
import DocumentsList from 'components/common/DocumentsList';
import RejectedList from 'components/common/DocumentsList/RejectedList';
import Emoji from 'components/common/Emoji';
import UserAlert, { Status, UserAlertProps } from 'components/common/UserAlert';
import FileDrop from 'components/FileDrop';
import allowedFileTypes from 'constants/data/allowedFileTypes';
import { timeouts } from 'constants/theme';
import { deleteDocument, fetchDocuments } from 'http/documents';
import React, { useCallback, useEffect, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addDocuments, deleteDocument as deleteDocumentAction, getDocuments } from 'store/documents/actions';
import { Document } from 'store/documents/types';
import { useAuth } from 'store/firebase/selectors';
import { RootState } from 'store/types';
import { businessScore } from 'constants/businessScore';
import SeedrsLogo from 'assets/partners/seedrs-logo.png';

const Investors: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [rejectedFiles, setRejections] = useState<FileRejection[]>([]);
  const documents = useSelector((state: RootState) => state.documents.items);
  const auth = useAuth();

  useEffect(() => {
    (async () => {
      const uploadedDocuments = await fetchDocuments();
      dispatch(getDocuments(uploadedDocuments));
    })();
  }, [dispatch]);

  const [{ open, message, status }, setOpenAlert] = React.useState<UserAlertProps>({
    open: false,
    message: '',
    status: Status.ERROR,
  });

  const handleDelete = useCallback(
    async (doc: Document) => {
      await deleteDocument(doc.name);
      dispatch(deleteDocumentAction(doc.id));
      setOpenAlert({
        open: true,
        message: t('pages.documents.validation.successDelete'),
        status: Status.SUCCESS,
      });
    },
    [dispatch, t],
  );

  const handleUpload = useCallback(
    (files: File[]) => {
      setOpenAlert({
        open: true,
        message: t('pages.documents.validation.success'),
        status: Status.SUCCESS,
      });
      dispatch(
        addDocuments(
          files.map((file) => ({
            id: file.name,
            name: file.name,
          })),
        ),
      );
    },
    [t, dispatch],
  );

  const handleRejection = useCallback((files: FileRejection[]) => {
    setRejections(files);
  }, []);

  const handleClose = useCallback((event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert({ open: false });
  }, []);

  const bookCalendly = () =>
    openCalendly({
      url: businessScore.calendly.investors,
      prefill: {
        name: auth.displayName || '',
        email: auth.email || '',
      },
    });

  return (
    <>
      <Fade in timeout={timeouts.fade}>
        <Grid container spacing={3}>
          <Grid item md={9} sm={12} xs={12}>
            <Typography gutterBottom component="div">
              <Typography variant="h1" display="inline">
                <Emoji variant="investors" />{' '}
              </Typography>
              <Typography variant="h1" display="inline">
                {t('pages.investors.header')}
              </Typography>
            </Typography>

            <Typography variant="h3" gutterBottom>
              {t('pages.dashboard.equityDetails')}
            </Typography>

            <Typography variant="h3" gutterBottom>
              {t('pages.dashboard.equityIncludes')}
            </Typography>

            <Box paddingBottom={2} paddingTop={1}>
              <img src={SeedrsLogo} alt="" height="25px" />
            </Box>

            <Typography variant="h3" gutterBottom>
              {t('pages.investors.details')}{' '}
              <Link href="#" onClick={() => bookCalendly()}>
                <Emoji variant="calendar" /> {t('pages.investors.action')}
              </Link>{' '}
            </Typography>
          </Grid>

          <Grid item xs={11} lg={8}>
            <FileDrop
              allowedFileTypes={allowedFileTypes}
              onUpload={handleUpload}
              onReject={handleRejection}
              uploadMessage={`${t('pages.investors.uploadMessage')}`}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h3" gutterBottom>
              {t('components.documentsList.uploadHistory')}
            </Typography>
            {documents?.length === 0 ? (
              <Typography variant="body1">{t('components.documentsList.noDocuments')}</Typography>
            ) : (
              <DocumentsList documents={documents} onDelete={handleDelete} />
            )}
          </Grid>
          {rejectedFiles.length > 0 && (
            <Grid item xs={12} lg={6}>
              <Typography variant="h3" gutterBottom>
                {t('components.documentsList.rejectedFiles')}
              </Typography>
              <RejectedList rejectedFiles={rejectedFiles} />
            </Grid>
          )}
        </Grid>
      </Fade>
      <UserAlert open={open} status={status} message={message} handleClose={handleClose} />
    </>
  );
};

export default Investors;
