import { ActionReducerMapBuilder, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import http from '../../../http';
import {
  IntegrationDateBalance,
  IntegrationState,
  Providers,
  IntegrationProviderAccountID,
} from '../integration.types';
import { resolveProviderAccountID } from '../integration.utils';

const SERVER_URL = process.env.REACT_APP_DOMAIN;

interface GetHistoricalResponseData {
  date: Date;
  subtotal: number;
}

const getCompanyHistoricalBalanceURL = (provider: Providers, integrationId: string): string =>
  `${SERVER_URL}/company/transactions/balances/${provider}/${integrationId}`;

export const fetchCompanyHistoricalBalance = async (
  provider: Providers,
  accountID: string,
): Promise<GetHistoricalResponseData[]> => {
  const { status, data } = await http.get(getCompanyHistoricalBalanceURL(provider, accountID));

  if (status !== 200) {
    throw new Error('Failed to fetch integration transactions');
  }

  return data;
};

export const getIntegrationHistoricalBalance = createAsyncThunk<
  IntegrationProviderAccountID & { history: IntegrationDateBalance[] },
  IntegrationProviderAccountID
>('integration/getIntegrationHistoricalBalance', async ({ provider, accountID }: IntegrationProviderAccountID) => {
  const history = await fetchCompanyHistoricalBalance(provider, accountID);
  return {
    provider,
    accountID,
    history: history.map(({ date, subtotal }) => ({
      date: typeof date === 'string' ? new Date(date).toLocaleDateString() : date.toLocaleDateString(),
      subtotal,
    })),
  };
});

export const resetIntegrationHistoricalBalance = createAction<IntegrationProviderAccountID>(
  'integration/resetIntegrationHistoricalBalance',
);

export const getIntegrationHistoricalBalanceReducers = (builder: ActionReducerMapBuilder<IntegrationState>): void => {
  builder.addCase(getIntegrationHistoricalBalance.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getIntegrationHistoricalBalance.fulfilled, (state, { payload: { provider, accountID, history } }) => {
    state.loading = false;
    state.balance_history[resolveProviderAccountID(provider, accountID)] = history;
  });
  builder.addCase(getIntegrationHistoricalBalance.rejected, (state) => {
    state.loading = false;
  });
  builder.addCase(resetIntegrationHistoricalBalance, (state, { payload: { provider, accountID } }) => {
    delete state.balance_history[resolveProviderAccountID(provider, accountID)];
  });
};
