import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ThemeModeToggle from 'components/theme-mode-toggle';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import routes from 'router/routes';
import { useProfile } from 'store/firebase/selectors';
import { CurrentUser } from 'store/firebase/types';
import Popover from './Popover';

type MenuProps = {
  currentUser: CurrentUser;
};
const Menu: React.FC<MenuProps> = ({ currentUser }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { displayName, photoURL, email } = currentUser;
  const userProfile = useProfile();
  const logout = useCallback(() => {
    history.push(routes.logout);
  }, [history]);
  const profile = () => {
    history.push(`/${routes.profile}`);
  };

  const name = userProfile.displayName || displayName;
  const userEmail = userProfile.email || email;
  const primary = name || userEmail || '';
  const secondary = userEmail || '';
  return (
    <Popover displayName={name} photoURL={userProfile.photoURL || photoURL}>
      <List>
        <ListItem button onClick={() => profile()}>
          <ListItemText primary={primary} secondary={primary === secondary ? '' : secondary} />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ThemeModeToggle useLabel />
        </ListItem>
        <ListItem button onClick={() => logout()}>
          <ListItemText primary={t('menus.user.logout')} />
        </ListItem>
      </List>
    </Popover>
  );
};
export default Menu;
