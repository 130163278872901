export const SET_GLOBAL_ERROR = 'SET_GLOBAL_ERROR';

export type GlobalError = string | boolean;

interface SetGlobalErrorAction {
  type: typeof SET_GLOBAL_ERROR;
  payload: GlobalError;
}

export type GlobalActionTypes = SetGlobalErrorAction;

export interface GlobalState {
  error: GlobalError;
}

export interface GlobalErrorHookReturn {
  globalError: GlobalError;
  setGlobalError: (error: GlobalError) => void;
}
