import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import Investors from './investors';
import Grants from './grants';
import Lenders from './lenders';

const Funding: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/lenders`} component={Lenders} />
      <Route path={`${path}/investors`} exact component={Investors} />
      <Route path={`${path}/grants`} exact component={Grants} />
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Funding;
