import LinearProgress from '@material-ui/core/LinearProgress';
import GlobalAlert from 'components/common/GlobalAlert';
import { GlobalStatus } from 'components/common/GlobalAlert/GlobalAlert';
import Modal from 'components/common/Modal';
import { useAnalytics, useIntercom, usePipedriveSync } from 'core/constants';
import { AuthModeListener, Dashboard, Login, Logout, Onboarding, Register } from 'pages';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useSynchronizedDashboardState } from 'store/dashboard/selectors';
import { useAuth } from 'store/firebase/selectors';
import { useGlobalError } from 'store/global';

import routes from './routes';

const PublicRoutes: FC = () => (
  <Switch>
    <Route path={routes.authListener} component={AuthModeListener} />
    <Route path={routes.login} component={Login} />
    <Route path={routes.register} component={Register} />
    <Route>
      <Redirect to={routes.login} />
    </Route>
  </Switch>
);

const PrivateRoutes: FC = () => {
  const dashboard = useSynchronizedDashboardState();
  usePipedriveSync(process.env.REACT_APP_ENV as string);
  useIntercom(process.env.REACT_APP_INTERCOM_APP_ID as string, dashboard.company);

  return dashboard.synchronizing ? null : (
    <Switch>
      <Route path={routes.authListener} component={AuthModeListener} />
      <Route path={routes.onboarding} component={Onboarding} />
      <Route exact path={routes.logout} component={Logout} />
      <Route path={routes.default} component={Dashboard} />
      <Route>
        <Redirect to={routes.default} />
      </Route>
    </Switch>
  );
};

export default function AppRouter(): JSX.Element {
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const auth = useAuth();
  const analytics = useAnalytics();
  const { globalError } = useGlobalError();
  const { t } = useTranslation();

  const { uid, displayName } = auth;

  useEffect(() => {
    analytics.init();
    analytics.identify(uid);
    analytics.people.set({
      $name: displayName,
    });
  }, [analytics, displayName, uid]);

  useEffect(() => {
    if (globalError) {
      setErrorModalOpen(true);
    } else {
      setErrorModalOpen(false);
    }
  }, [globalError]);

  const Routes = () => (auth.isEmpty ? <PublicRoutes /> : <PrivateRoutes />);

  return (
    <>
      <Router>{auth.isLoaded ? <Routes /> : <LinearProgress />}</Router>

      {globalError && !errorModalOpen && (
        <GlobalAlert status={GlobalStatus.ERROR} message={t('general.validation.globalErrorMessage')} />
      )}

      <Modal
        open={errorModalOpen}
        handleClose={() => setErrorModalOpen(false)}
        title={t('general.validation.globalError')}
        description={t('general.validation.globalErrorMessage')}
        actionButton={t('general.validation.globalErrorAction')}
        onClickPrimary={() => {
          window.open(`mailto:platform-support@scorethebusiness.com?subject=${t('general.email.supportSubject')}`);
        }}
        cancelAction={false}
      />
    </>
  );
}
