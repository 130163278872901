import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import * as rax from 'retry-axios';
import * as firebase from 'firebase';
import { store } from 'store';
import { setGlobalError } from 'store/global';
import { accept5xx, isNetWorkError } from './utils';

const baseURL = process.env.REACT_APP_BASE_API;

export const http = axios.create({ baseURL });

// retry axios setup
http.defaults.raxConfig = {
  instance: http,
  retry: 1,
};
rax.attach(http);

const addAuthorizationToken = async (config: AxiosRequestConfig) => {
  const token = await firebase.auth().currentUser?.getIdToken();

  if (process.env.NODE_ENV === 'development') {
    console.log('token', token);
  }
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

const clearGlobalErrors = (response: AxiosResponse) => {
  const globalError = store.getState().global.error;
  if (globalError) store.dispatch(setGlobalError(false));
  return response;
};

const checkForGlobalError = (error: AxiosError) => {
  const currentRetryAttempt = error.config.raxConfig?.currentRetryAttempt;
  const responseStatusCode = error.response?.status;

  if (isNetWorkError(error) || (responseStatusCode && accept5xx(responseStatusCode) && currentRetryAttempt === 1)) {
    store.dispatch(setGlobalError(true));
  }

  return Promise.reject(error);
};

http.interceptors.request.use(addAuthorizationToken);

http.interceptors.response.use(clearGlobalErrors, checkForGlobalError);

export default http;
