export const residentialStatusOptions = [
  { value: 'tenant', label: 'Tenant' },
  { value: 'homeownerMortgage', label: 'Homeowner with mortgage' },
  { value: 'homeownerNoMortgage', label: 'Homeowner without mortgage' },
  { value: 'withFriendsOrFamily', label: 'Living with friends or family' },
];

export const residentialAddressInitial = {
  houseNumber: null,
  addressLine1: null,
  addressLine2: null,
  city: null,
  county: null,
  country: null,
  postcode: null,
  current: false,
  addressStartDate: new Date(),
  status: residentialStatusOptions[0].value,
  propertyEquity: null,
};

export const initialFormValues = {
  id: null,
  name: null,
  email: null,
  contactNumber: null,
  residentialAddress: [residentialAddressInitial],
};

export const currentAddressOptions = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
];
