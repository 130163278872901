import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      height: '100vh',
    },
    leftPanel: {
      backgroundColor: theme.palette.background.paper,
      borderTopLeftRadius: '50px',
      borderBottomLeftRadius: '50px',
      padding: '65px 50px',
    },
    teamwork: {
      marginTop: '3rem',
      width: '100%',
      maxWidth: '414px',
    },
    leftPanelButton: {
      backgroundColor: theme.palette.background.light,
      color: theme.palette.background.actionContrast,
      textTransform: 'capitalize',
      fontWeight: 600,
      fontSize: '12px',
      paddingRight: '2rem',
      paddingLeft: '2rem',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    disclaimer: {
      marginTop: '4rem',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '36px',
      textAlign: 'center',
      letterSpacing: '0.1px',
    },
    rightPanel: {
      backgroundColor: theme.palette.background.light,
      padding: '4rem 3rem',
      margin: '0 auto',
      boxShadow: '3px -5px 40px rgba(205, 205, 212, 0.1)',
      borderTopRightRadius: '50px',
      borderBottomRightRadius: '50px',
      [theme.breakpoints.down('sm')]: {
        borderRadius: '20px',
        padding: '2rem 2rem',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '2rem 1rem 1rem',
      },
    },
    header: {
      fontWeight: 600,
      letterSpacing: '0.116667px',
      marginBottom: '1.375rem',
    },
    mainButton: {
      height: '45px',
    },
    googleButton: {
      height: '45px',

      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  }),
);
