import { colors, Tabs, makeStyles } from '@material-ui/core';
import {
  AccountCircle as AboutYouIcon,
  Assessment as BusinessDetailsIcon,
  Business as FundingGoalsIcon,
  ShowChart as BusinessPerformanceIcon,
  MoreRounded as AdditionalDetailsIcon,
} from '@material-ui/icons';
import TabPanel from 'components/common/TabPanel';
// import CompanyDetails from './CompanyDetails';
// import FinancialProfile from './FinancialProfile';
// import FundingProfile from './FundingProfile';
import {
  AboutYouForm,
  BusinessDetailsForm,
  BusinessPerformanceForm,
  FundingGoalsForm,
  AdditionalDetailsForm,
} from 'components/onboarding';
import { Company } from 'entities/dashboard';
import React, { useState } from 'react';
import { StyledTab } from './StyledTab';

type propsType = {
  company: Company;
  // value: number;
  // eslint-disable-next-line react/require-default-props
};

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    minHeight: 'auto',
  },
  indicator: {
    display: 'none',
  },
});

const TabPanels: React.FC<propsType> = ({ company }) => {
  const [value, setValue] = useState(0);
  const classes = useStyles();

  // eslint-disable-next-line
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="company details"
        classes={{
          root: classes.root,
          indicator: classes.indicator,
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <StyledTab label="Funding Goals" icon={<FundingGoalsIcon style={{ color: colors.orange[800] }} />} value={0} />
        <StyledTab
          label="Business details"
          wrapped={false}
          icon={<BusinessDetailsIcon style={{ color: colors.purple[800] }} />}
          value={1}
        />
        <StyledTab
          label="Your details"
          wrapped={false}
          icon={<AboutYouIcon style={{ color: colors.blue[800] }} />}
          value={2}
          disabled={company && !company.registeredNumber}
        />
        <StyledTab
          label="Performance"
          wrapped={false}
          icon={<BusinessPerformanceIcon style={{ color: colors.pink[800] }} />}
          value={3}
        />
        <StyledTab
          label="Additional Details"
          wrapped={false}
          icon={<AdditionalDetailsIcon style={{ color: colors.deepPurple[800] }} />}
          value={4}
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <FundingGoalsForm formValues={company} primaryButtonTitle="Update" />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <BusinessDetailsForm formValues={company} primaryButtonTitle="Update" updateMode />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <AboutYouForm directors={company.directors} primaryButtonTitle="Update" />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <BusinessPerformanceForm formValues={company} primaryButtonTitle="Update" />
      </TabPanel>

      <TabPanel value={value} index={4}>
        <AdditionalDetailsForm formValues={company} primaryButtonTitle="Update" />
      </TabPanel>
    </>
  );
};

export default TabPanels;
