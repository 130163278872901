import { AxiosError } from 'axios';

export interface HttpError<T = string> {
  message: string;
  url?: string;
  response?: {
    data: T;
    headers: {
      [key: string]: string;
    };
    status: number;
    statusText: string;
  };
}

export const fromAxiosError = <T>(error: AxiosError<T>): HttpError<T> => ({
  message: error.message,
  url: error.config.url,
  response: error.response
    ? {
        data: error.response.data,
        headers: error.response.headers,
        status: error.response.status,
        statusText: error.response.statusText,
      }
    : undefined,
});
