import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    paper: {
      borderRadius: '10px',
    },
    paperScrollPaper: {
      height: 'calc(100vh - 100px)',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      padding: '25px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      padding: '43px',
    },
    title: {
      fontWeight: 600,
      fontSize: '16px',
    },
    description: {
      lineHeight: '18px',
    },
    img: {
      width: '100%',
    },
  }),
);
