import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContent: {
      backgroundColor: theme.palette.background.light,
      padding: '2rem 2rem',
      outline: 0,
      // borderRadius: '20px',
    },
    sentContent: {
      padding: '2rem 3rem',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        padding: '2rem',
      },
    },
    sentHeader: {
      fontWeight: 'bold',
      marginBottom: 20,
    },
    sentMessage: {
      marginBottom: 10,
    },
    continueButton: {
      width: 150,
      alignSelf: 'flex-end',
      marginTop: 35,
    },
  }),
);
