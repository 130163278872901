/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Link,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { Google as GoogleIcon, MailOutline as MailOutlineIcon, Lock as LockIcon } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import LoginBase, { useLoginBaseStyles } from 'components/login-base';
import { auth as fireAuth } from 'firebase';
import { Form, Formik } from 'formik';
import React, { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import { useAuthErrorMessage } from 'store/firebase/selectors';
import * as yup from 'yup';
import { useAnalytics, ANALYTICS_ACTIONS } from 'core/constants';
import ResetModal from './components/reset-modal';
import useLoginStyles from './Login.styles';

interface LoginForm {
  email: string;
  password: string;
  remember: boolean;
}

const Login: FC = () => {
  const baseClasses = useLoginBaseStyles();
  const classes = useLoginStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const firebase = useFirebase();
  const errorMessage = useAuthErrorMessage();
  const [showResetModal, setShowResetModal] = useState(false);
  const analytics = useAnalytics();

  const login = ({ email, password, remember }: LoginForm) => {
    const persistence = remember ? fireAuth.Auth.Persistence.LOCAL : fireAuth.Auth.Persistence.SESSION;
    firebase.auth().setPersistence(persistence);
    firebase
      .login({ email, password })
      .then(() => {
        analytics.track(ANALYTICS_ACTIONS.EMAIL_LOGIN);
      })
      .catch(() => {
        // console.log(t('general.validation.error'));
        analytics.track(ANALYTICS_ACTIONS.EMAIL_LOGIN_FAIL);
      });
  };

  const loginWithGoogle = () =>
    firebase
      .login({
        provider: 'google',
        type: 'popup',
      })
      .then(() => {
        analytics.track(ANALYTICS_ACTIONS.GOOGLE_LOGIN);
      })
      .catch(() => {
        // console.log(t('general.validation.error'));
        analytics.track(ANALYTICS_ACTIONS.GOOGLE_LOGIN_FAIL);
      });

  const goRegister = () => {
    history.push('/register');
  };

  const signUpButton = (
    <Button variant="contained" className={baseClasses.leftPanelButton} disableElevation onClick={goRegister}>
      <Trans i18nKey="pages.login.signUpButton" />
    </Button>
  );

  const loginSchema = yup.object({
    email: yup
      .string()
      .required(t('pages.login.validation.email.required'))
      .email(t('pages.login.validation.email.format')),
    password: yup
      .string()
      .required(t('pages.login.validation.password.required'))
      .min(6, t('pages.login.validation.password.length')),
    remember: yup.boolean(),
  });

  return (
    <LoginBase leftPanelButton={signUpButton} i18nBaseKey="pages.login">
      <Formik
        initialValues={{ email: '', password: '', remember: false }}
        onSubmit={login}
        validationSchema={loginSchema}
      >
        {({ handleChange, values, errors, touched }) => (
          <Form noValidate>
            <FormGroup>
              <Grid container item direction="column" spacing={4}>
                <Grid item>
                  <TextField
                    fullWidth
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    label={t('pages.login.emailLabel')}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <MailOutlineIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    name="password"
                    type="password"
                    autoComplete="password"
                    value={values.password}
                    onChange={handleChange}
                    label={t('pages.login.passwordLabel')}
                    error={touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item container>
                  <Grid item xs={8}>
                    <FormControlLabel
                      control={<Checkbox checked={values.remember} onChange={handleChange} name="remember" />}
                      label={t('pages.login.rememberMeLabel')}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center" height="100%">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <Link href="#" onClick={() => setShowResetModal(true)}>
                        <Trans i18nKey="pages.login.forgotPasswordLink" />
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    fullWidth
                    disableElevation
                    variant="contained"
                    color="primary"
                    className={baseClasses.mainButton}
                  >
                    <Trans i18nKey="pages.login.loginButton" />
                  </Button>
                </Grid>
                <Grid item className={classes.divider}>
                  <Divider />
                  <Typography className={classes.instantLogin} variant="overline">
                    <Trans i18nKey="pages.login.instantLogin" />
                  </Typography>
                </Grid>
              </Grid>
            </FormGroup>
          </Form>
        )}
      </Formik>

      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={firebase.logout}>
        <Alert elevation={6} variant="filled" onClose={firebase.logout} severity="warning">
          {errorMessage}
        </Alert>
      </Snackbar>

      <Grid container item direction="column" spacing={3}>
        <Grid item>
          <Button
            fullWidth
            disableElevation
            variant="contained"
            color="secondary"
            className={baseClasses.googleButton}
            startIcon={<GoogleIcon />}
            onClick={loginWithGoogle}
          >
            <Trans i18nKey="pages.login.googleButton" />
          </Button>
        </Grid>
        {/* <Grid item>
          <Button
            disabled
            fullWidth
            disableElevation
            variant="contained"
            color="primary"
            startIcon={<i className="fab fa-facebook-square" />}
          >
            <Trans i18nKey="pages.login.facebookButton" />
          </Button>
        </Grid> */}
        <Grid item>
          <Box display="flex" justifyContent="center">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

            <Link href="#" align="center" onClick={goRegister}>
              <Trans i18nKey="pages.login.mobile.signUpLink" />
            </Link>
          </Box>
        </Grid>
      </Grid>

      <ResetModal open={showResetModal} onClose={() => setShowResetModal(false)} />
    </LoginBase>
  );
};

export default Login;
