import { Fade, Grid, Hidden, Typography } from '@material-ui/core';
import { ReactComponent as WelcomeImage } from 'assets/icon/astronaut.svg';
import { openCalendly } from 'components/common/CalendlyPopup';
import Emoji from 'components/common/Emoji';
import BlueCard from 'components/dashboard/BlueCard';
import BusinessCreditScore from 'components/dashboard/BusinessCreditScore';
import BusinessValuation from 'components/dashboard/BusinessValuation';
import CreditLimit from 'components/dashboard/CreditLimit';
import ProfileCard from 'components/dashboard/ProfileCard';
import Header from 'components/dashboard/SectionHeader/Header';
import { businessScore } from 'constants/businessScore';
import { timeouts } from 'constants/theme';
import { User } from 'entities/user';
import { postCompany } from 'http/dashboard';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routes from 'router/routes';
import { CreateCompany } from 'store/dashboard/actions';
import { useDashboardState } from 'store/dashboard/selectors';
import { useThemeMode } from 'store/user/selectors';
import useHomeStyles from './Home.styles';
// import { ShopifyCard } from './components/ShopifyCard';

interface HomeProps {
  currentUser: User;
}

const Home: FC<HomeProps> = ({ currentUser: currentUserProp }) => {
  const formattedDate = new Date().toDateString();
  const [dayOfTheWeek, month, day, year] = formattedDate.split(' ');
  const { t } = useTranslation();
  const classes = useHomeStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const themeMode = useThemeMode();

  const [companyCreateLoading, setCompanyCreateLoading] = useState(false);

  const {
    businessProfile: { businessCreditLimit, businessCreditScore, businessValuation },
    company: { id, onboardingStep },
    onBoardingComplete,
  } = useDashboardState();

  // const onBoardingComplete = false;

  const navigateToGrants = () => {
    history.push(`/${routes.grants}`);
  };

  const navigateToLenders = () => {
    history.push(`/${routes.lenders}`);
  };

  const navigateToInvestors = () => {
    history.push(`/${routes.investors}`);
  };

  const navigateOnboard = () => {
    history.push(routes.onboarding);
  };

  const bookCalendly = () =>
    openCalendly({
      url: businessScore.calendly.followUp,
      prefill: {
        name: currentUserProp.displayName || '',
        email: currentUserProp.email || '',
      },
    });

  const onboardCompany = async (onOnboardingComplete: () => void) => {
    // companyExists and onboarding is not complete
    if (onboardingStep !== 0 && !onBoardingComplete) {
      navigateOnboard();
      return;
    }

    // company onboarded? run onOnboardingComplete()
    if (onBoardingComplete) {
      onOnboardingComplete();
      return;
    }

    if (!id) {
      // !onboarded and !companyExists, create newCompany
      try {
        setCompanyCreateLoading(true);
        const res = await postCompany();
        if (res) {
          dispatch(CreateCompany(res));
          navigateOnboard();
        }
        setCompanyCreateLoading(false);
      } catch (error) {
        setCompanyCreateLoading(false);
        if (process.env.NODE_ENV === 'development') {
          // console.log('error', error);
          navigateOnboard();
        }
      }
    } else {
      navigateOnboard();
    }
  };

  return (
    <Fade in timeout={timeouts.fade}>
      <div>
        <Grid container spacing={3}>
          <Grid item xl={6} lg={7} md={7} sm={12} xs={12} className={classes.noPadding}>
            <Typography component="div">
              <Typography variant="h1" display="inline">
                <Emoji variant="welcome" />{' '}
                {`${t('pages.dashboard.welcome')}${currentUserProp.displayName && `, ${currentUserProp.displayName}!`}`}
              </Typography>
            </Typography>
            <Typography variant="caption" gutterBottom>
              {dayOfTheWeek}, {day} {month} {year}
            </Typography>
            <div className={classes.marginBottom} />
            <Typography variant="h2" gutterBottom>
              {t('pages.dashboard.detailsHeader')}
            </Typography>
            <Typography variant="h3" gutterBottom>
              {t('pages.dashboard.details1')}
            </Typography>
            {/* <Typography variant="body1" className={classes.text}>
                {t('pages.dashboard.details2')}
              </Typography> */}
          </Grid>
          <Hidden mdDown>
            <Grid
              item
              xl={3}
              lg={5}
              md={5}
              sm={12}
              xs={12}
              className={classes.noPadding}
              style={{ textAlign: 'right' }}
            >
              <WelcomeImage height={200} />
            </Grid>
          </Hidden>

          {/* Notifications Metrics */}
          {!onBoardingComplete && (
            <>
              <Grid container spacing={3} className={classes.noPadding}>
                <Grid item xs={12} className={classes.noPadding}>
                  <Header title={t('pages.dashboard.header1')} icon={<Emoji variant="notification" />} />
                </Grid>
                <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
                  <BlueCard
                    onBoardingComplete={onBoardingComplete}
                    onboardingStep={onboardingStep}
                    loading={companyCreateLoading}
                    onClick={() => onboardCompany(bookCalendly)}
                  />
                </Grid>
              </Grid>
              <Grid item className={classes.spacer} />
            </>
          )}

          {/* Business Metrics */}
          <Grid container spacing={3} className={classes.noPadding}>
            <Grid item xs={12} className={classes.noPadding}>
              <Header title={t('pages.dashboard.header2')} icon={<Emoji variant="dashboard" />} />
            </Grid>
            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <BusinessValuation businessValuation={businessValuation} onBoardingComplete={onBoardingComplete} />
            </Grid>
            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <CreditLimit businessCreditLimit={businessCreditLimit} onBoardingComplete={onBoardingComplete} />
            </Grid>
            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <BusinessCreditScore
                score={businessCreditScore}
                onBoardingComplete={onBoardingComplete}
                mode={themeMode}
              />
            </Grid>
          </Grid>

          {/* <Grid container spacing={3} className={classes.noPadding}>
            <ShopifyCard />
          </Grid> */}

          <Grid item className={classes.spacer} />

          {/* Profile cards */}
          <Grid container spacing={3} className={classes.noPadding}>
            <Grid item xs={12} className={classes.noPadding}>
              <Header title={t('pages.dashboard.header3')} icon={<Emoji variant="funding" />} />
            </Grid>

            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <ProfileCard
                title={t('pages.dashboard.equityTitle')}
                include={t('pages.dashboard.equityIncludes')}
                onBoardingComplete={onBoardingComplete}
                buttonText={onBoardingComplete ? t('pages.dashboard.equityButton') : t('general.titles.getStarted')}
                h4={t('pages.dashboard.equityHeader')}
                p={t('pages.dashboard.equityDetails')}
                currentUser={currentUserProp}
                timeout={timeouts.grow.first}
                cardEvent={() => onboardCompany(navigateToInvestors)}
              />
            </Grid>

            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <ProfileCard
                title={t('pages.dashboard.lenderTitle')}
                include={t('pages.dashboard.lenderIncludes')}
                onBoardingComplete={onBoardingComplete}
                buttonText={onBoardingComplete ? t('pages.dashboard.lenderButton') : t('general.titles.getStarted')}
                h4={t('pages.dashboard.lenderHeader')}
                p={`${t('pages.dashboard.lenderDetails1')} ${t('pages.dashboard.lenderDetails2')}`}
                currentUser={currentUserProp}
                timeout={timeouts.grow.second}
                cardEvent={() => onboardCompany(navigateToLenders)}
              />
            </Grid>

            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <ProfileCard
                onBoardingComplete={onBoardingComplete}
                title={t('pages.dashboard.grantsTitle')}
                include="Grant providers include"
                buttonText={onBoardingComplete ? t('pages.dashboard.grantButton') : t('general.titles.getStarted')}
                h4={t('pages.dashboard.grantsHeader')}
                p={t('pages.dashboard.grantsDetails')}
                currentUser={currentUserProp}
                timeout={timeouts.grow.third}
                cardEvent={() => onboardCompany(navigateToGrants)}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default Home;
