import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { fetchLenderCategories, fetchProviders } from 'store/lenders/actions';

import { useDashboardState } from 'store/dashboard/selectors';
import { LenderDetail, LenderList } from './components';
import LenderConfirm from './components/lender-confirm';

const Lenders: FC = () => {
  const dispatch = useDispatch();

  const {
    company: { startYear, lastYearRevenue, monthlyRevenue, businessCustomerRevenuePercentage },
  } = useDashboardState();
  const { path } = useRouteMatch();

  useEffect(() => {
    dispatch(fetchProviders());
    dispatch(fetchLenderCategories(startYear, lastYearRevenue, monthlyRevenue, businessCustomerRevenuePercentage));
  }, [dispatch, startYear, lastYearRevenue, monthlyRevenue, businessCustomerRevenuePercentage]);

  return (
    <>
      <Switch>
        <Route exact path={path} component={LenderList} />
        <Route path={`${path}/:id`} component={LenderDetail} />
        <Route>
          <Redirect to={path} />
        </Route>
      </Switch>
      <LenderConfirm />
    </>
  );
};

export default Lenders;
