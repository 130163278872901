import { ActionReducerMapBuilder, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import http from '../../../http';
import {
  IntegrationState,
  IntegrationTransaction,
  Providers,
  IntegrationProviderAccountID,
} from '../integration.types';
import { resolveProviderAccountID } from '../integration.utils';

const SERVER_URL = process.env.REACT_APP_DOMAIN;

type GetIntegrationTransactionsResponseData = IntegrationTransaction;

const getIntegrationTransactionsURL = (provider: Providers, accountID: string): string =>
  `${SERVER_URL}/company/transactions/${provider}/${accountID}`;

export const fetchTransactions = async (
  provider: Providers,
  accountID: string,
): Promise<GetIntegrationTransactionsResponseData[]> => {
  const { status, data } = await http.get(getIntegrationTransactionsURL(provider, accountID));

  if (status !== 200) {
    throw new Error('Failed to fetch integration transactions');
  }

  return data;
};

export const getTransactions = createAsyncThunk(
  'integration/getTransactions',
  async ({ provider, accountID }: { provider: Providers; accountID: string }) => {
    const transactions = await fetchTransactions(provider, accountID);
    return { provider, accountID, transactions };
  },
);

export const resetTransactions = createAction<IntegrationProviderAccountID>('integration/resetTransactions');

export const getIntegrationTransactionsReducers = (builder: ActionReducerMapBuilder<IntegrationState>): void => {
  builder.addCase(getTransactions.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getTransactions.fulfilled, (state, { payload: { transactions, accountID, provider } }) => {
    state.loading = false;
    state.transactions[resolveProviderAccountID(provider, accountID)] = transactions;
  });
  builder.addCase(getTransactions.rejected, (state) => {
    state.loading = false;
  });
  builder.addCase(resetTransactions, (state, { payload: { accountID, provider } }) => {
    delete state.transactions[resolveProviderAccountID(provider, accountID)];
  });
};
