import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import StyledBadge from 'components/common/StyledBadge';
import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import Emoji from 'components/common/Emoji';
import useSideBarStyles from './Siderbar.styles';

const SideBarItem: React.FC<{
  // eslint-disable-next-line react/require-default-props
  className?: string;
  href: string;
  // Icon?: React.ComponentType;
  Icon: string;
  title: string;
  // eslint-disable-next-line react/require-default-props
  newItem?: boolean;
  // eslint-disable-next-line react/require-default-props
  nested?: boolean;
}> = ({ className, href, Icon, title, newItem, nested = false }) => {
  const classes = useSideBarStyles();
  return (
    <ListItem
      disableGutters
      button
      activeClassName={classes.active}
      className={[classes.button, className].join('')}
      style={{ paddingLeft: nested ? '35px' : '16px' }}
      component={RouterLink}
      to={href}
    >
      {/* <ListItemIcon className={classes.icon}>{Icon && <Icon />}</ListItemIcon> */}
      <ListItemIcon className={classes.icon}>
        <Emoji symbol={Icon} label={title} />
      </ListItemIcon>

      <StyledBadge badgeContent="new" color="primary" invisible={!newItem} variant="dot">
        <ListItemText primary={title} className={classes.title} />
      </StyledBadge>
    </ListItem>
  );
};

export default SideBarItem;
