import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import React, { useCallback } from 'react';

import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Emoji from 'components/common/Emoji';
import {
  adminMenu,
  companyMenu,
  companySidebarMenu,
  fundingSidebarMenu,
  menu,
  menuBottom,
  perksMenu,
  dashboardMenu,
} from 'constants/data/menu';
import { useHistory } from 'react-router-dom';
import routes from 'router/routes';
import { maxText } from 'services/textFormatter';
import { useDashboardState } from 'store/dashboard/selectors';
import { useAdmin /*, useAuth, useMetricsLink */ } from 'store/firebase/selectors';
import SideBarItem from './SideBarItem';
import useSidebarstyles from './Siderbar.styles';

const SideBarContent: React.FC = () => {
  const classes = useSidebarstyles();
  const {
    company: { registeredName },
    onBoardingComplete,
  } = useDashboardState();

  const history = useHistory();

  const [companyMenuOpen, setCompanyMenuOpen] = React.useState(true);
  const [fundingMenuOpen, setFundingMenuOpen] = React.useState(true);

  const logout = useCallback(() => {
    history.push(routes.logout);
  }, [history]);

  const maxCompanyStringLength = 12;

  const isAdmin = useAdmin();
  // const currentUser = useAuth();
  // const metricsLink: any = useMetricsLink(currentUser.uid);

  // const hasMetricsURL = () => {
  //   if (metricsLink && metricsLink[currentUser.uid]) {
  //     return !!metricsLink[`${currentUser.uid}`]?.url;
  //   }
  //   return false;
  // };

  return (
    <Box className={classes.paper}>
      <>
        <List disablePadding>
          {menu.map((menuItem) => (
            <SideBarItem
              href={`/${menuItem.path}`}
              key={menuItem.name}
              title={menuItem.name}
              Icon={menuItem.icon}
              newItem={menuItem.new}
            />
          ))}

          {/* {hasMetricsURL() &&
           */}
          {dashboardMenu.map((menuItem) => (
            <SideBarItem
              href={`/${menuItem.path}`}
              key={menuItem.name}
              title={menuItem.name}
              Icon={menuItem.icon}
              newItem={menuItem.new}
            />
          ))}

          {/* Funding Menu */}
          {onBoardingComplete && (
            <>
              <ListItem
                button
                className={classes.button}
                onClick={() => setFundingMenuOpen(!fundingMenuOpen)}
                disableGutters
              >
                <ListItemIcon className={classes.icon}>
                  <Emoji variant="funding" />
                </ListItemIcon>
                <ListItemText className={classes.title} primary="Funding" />
                <ListItemSecondaryAction style={{ display: 'flex' }}>
                  {fundingMenuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse in={fundingMenuOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {fundingSidebarMenu.map((menuItem) => (
                    <Box className={classes.nested} key={menuItem.name}>
                      <SideBarItem
                        href={`/${menuItem.path}`}
                        title={menuItem.name}
                        Icon={menuItem.icon}
                        newItem={menuItem.new}
                        nested
                      />
                    </Box>
                  ))}
                </List>
              </Collapse>
            </>
          )}

          {/* Company Menu */}
          {onBoardingComplete ? (
            <>
              <ListItem
                button
                className={classes.button}
                onClick={() => setCompanyMenuOpen(!companyMenuOpen)}
                disableGutters
              >
                <ListItemIcon className={classes.icon}>
                  <Emoji variant="company" />
                </ListItemIcon>
                <ListItemText
                  className={classes.title}
                  primary={(registeredName && maxText(registeredName, maxCompanyStringLength)) || 'Company'}
                />
                <ListItemSecondaryAction style={{ display: 'flex' }}>
                  {companyMenuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse in={companyMenuOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {companySidebarMenu.map((menuItem) => (
                    <Box className={classes.nested} key={menuItem.name}>
                      <SideBarItem
                        href={`/${menuItem.path}`}
                        title={menuItem.name}
                        Icon={menuItem.icon}
                        newItem={menuItem.new}
                        nested
                      />
                    </Box>
                  ))}
                </List>
              </Collapse>
            </>
          ) : (
            companyMenu.map((menuItem) => (
              <SideBarItem
                href={`/${menuItem.path}`}
                key={menuItem.name}
                title={menuItem.name}
                Icon={menuItem.icon}
                newItem={menuItem.new}
              />
            ))
          )}
        </List>

        {/* Perks Menu */}
        {perksMenu.map((menuItem) => (
          <SideBarItem
            href={`/${menuItem.path}`}
            key={menuItem.name}
            title={menuItem.name}
            Icon={menuItem.icon}
            newItem={menuItem.new}
          />
        ))}

        {/* Bottom Menu */}
        <div className={classes.bottom}>
          <Divider />
          <List disablePadding>
            {isAdmin &&
              adminMenu.map((menuItem) => (
                <SideBarItem
                  href={`/${menuItem.path}`}
                  key={menuItem.name}
                  title={menuItem.name}
                  Icon={menuItem.icon}
                />
              ))}
            {menuBottom.map((menuItem) => (
              <SideBarItem
                href={`/${menuItem.path}`}
                key={menuItem.name}
                title={menuItem.name}
                Icon={menuItem.icon}
                newItem={menuItem.new}
              />
            ))}

            <ListItem button disableGutters className={classes.button} onClick={() => logout()}>
              <ListItemIcon className={classes.icon}>
                <Emoji variant="logout" />
              </ListItemIcon>
              <ListItemText primary="Logout" className={classes.title} />
            </ListItem>
          </List>
        </div>
      </>
    </Box>
  );
};

export default SideBarContent;
