import { useDispatch } from 'react-redux';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useApplyingToLenderCategory, useSelectedLenderCategory } from 'store/lenders/selectors';

import { applyToLenderCategory, closeLenderPopup } from 'store/lenders/actions';

import { Button, Dialog, Typography, DialogActions } from '@material-ui/core';
import useStyles from './LenderConfirm.styles';

// interface LenderConfirmProps {}

const LenderConfirm: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedLender = useSelectedLenderCategory();
  const applying = useApplyingToLenderCategory();
  const onCloseApply = () => dispatch(closeLenderPopup());
  const apply = () => dispatch(applyToLenderCategory());

  if (!selectedLender) {
    return null;
  }

  return (
    <Dialog
      open
      aria-labelledby="customized-dialog-title"
      classes={{
        paper: styles.paper,
        paperScrollPaper: styles.paperScrollPaper,
      }}
    >
      <div className={styles.container}>
        <img src="/lenders/confirm-lender.png" alt={t(`${selectedLender.confirmation.title}`)} className={styles.img} />
        <div className={styles.body}>
          <Typography variant="h6" className={styles.title}>
            {t(`${selectedLender.confirmation.title}`)}
          </Typography>

          <Typography variant="body1" className={styles.description}>
            {t(`${selectedLender.confirmation.description}`)
              .split('\n')
              .map((text) => (
                <p>{text}</p>
              ))}
          </Typography>
        </div>

        <DialogActions>
          <Button color="default" disabled={applying} variant="outlined" onClick={onCloseApply}>
            Back
          </Button>
          <Button autoFocus disabled={applying} variant="contained" color="primary" onClick={apply}>
            Continue
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default LenderConfirm;
