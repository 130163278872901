import { Avatar, Box, Button, colors, Fade, Grid, Typography } from '@material-ui/core';
import Emoji from 'components/common/Emoji';
import Table from 'components/common/Table';
import { timeouts } from 'constants/theme';
import { useAnalytics, ANALYTICS_ACTIONS } from 'core/constants';
import Papa from 'papaparse';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const getLengthColor = (length) => {
  switch (length) {
    case 'Short':
      return 'green';
    case 'Medium':
      return 'orange';
    case 'Long':
      return 'red';
    default:
      return 'green';
  }
};

const Grants = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const removeByIndex = (array, index) => array.filter((_, i) => i !== index);

  const analytics = useAnalytics();

  const fetchGrants = useCallback(async () => {
    const response = await fetch(require('constants/data/BscorefundingGrantsSheet.csv'));

    const reader = response.body.getReader();
    const result = await reader.read(); // raw array
    const decoder = new TextDecoder('utf-8');
    const csv = decoder.decode(result.value);
    const results = Papa.parse(csv, {
      header: false,
      skipEmptyLines: true,
    });

    if (results.data) {
      return removeByIndex(results.data, 0);
    }

    return [];
  }, []);

  const columns = [
    {
      label: 'logo',
      options: {
        customBodyRender: (_, meta) => {
          const domain = new URL(meta.rowData[7]);
          const orgName = meta.rowData[2];
          let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

          let initials = [...orgName.matchAll(rgx)] || [];

          return (
            <Box>
              <Avatar
                style={{
                  backgroundColor: colors.grey[800],
                  width: 40,
                  height: 40,
                }}
                src={`//logo.clearbit.com/${domain.hostname}?size=40`}
              >
                {(initials.shift()?.[1] || '') + (initials.pop()?.[1] || '').toUpperCase()}
              </Avatar>
            </Box>
          );
        },
      },
    },
    {
      name: 'Grant Name',
      options: {
        customBodyRender: (value) => (
          <Typography variant="caption">
            <Box fontWeight="fontWeightBold">{value}</Box>
          </Typography>
        ),
      },
    },
    {
      name: 'Organisation',
      options: {
        customBodyRender: (value) => <Typography variant="caption">{value}</Typography>,
      },
    },
    {
      name: 'Value',
      options: {
        customBodyRender: (value) => <Typography variant="caption">{value}</Typography>,
      },
    },
    {
      name: 'Length',
      options: {
        customBodyRender: (value) => (
          <>
            <div
              style={{
                display: 'inline-block',
                height: 8,
                width: 8,
                borderRadius: 4,
                backgroundColor: getLengthColor(value),
                marginRight: 5,
              }}
            />
            <Typography variant="caption">{value}</Typography>
          </>
        ),
      },
    },
    {
      name: 'Closing Date',
      options: {
        customBodyRender: (value) =>
          value === 'Ongoing' ? (
            <Typography variant="caption">{value}</Typography>
          ) : (
            <Typography variant="caption">
              <Box fontWeight="fontWeightBold">{value}</Box>
            </Typography>
          ),
      },
    },
    {
      name: 'Category',
      options: {
        customBodyRender: (value) => (
          <Typography variant="caption">{value}</Typography>
          // <Chip color="secondary" label={value} />
        ),
      },
    },
    {
      name: '',
      options: {
        filter: false,
        customBodyRender: (url, meta) => (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              analytics.track(ANALYTICS_ACTIONS.GRANT_VISIT, {
                $org: meta.rowData[2],
                $url: url,
                $value: meta.rowData[3],
              });

              window.open(`${url}`, '_blank');
            }}
          >
            <Typography variant="caption">learn more</Typography>
          </Button>
        ),
      },
    },
  ];

  useEffect(() => {
    (async () => {
      if (rows < 1) {
        setLoading(false);
        setRows(await fetchGrants());
      }
    })();
  }, [dispatch, fetchGrants, columns, rows]);

  return (
    <Fade in timeout={timeouts.fade}>
      <Grid container spacing={3}>
        <Grid item md={9} sm={12} xs={12}>
          <Typography variant="h1" display="inline" gutterBottom>
            <Emoji variant="grants" /> {t('pages.grants.header')}
          </Typography>

          <Typography variant="h3" gutterBottom component="div">
            {t('pages.grants.details1')}
          </Typography>
          {/* <Typography variant="h3" gutterBottom>
            {t('pages.grants.details2')}
          </Typography> */}
        </Grid>

        <Grid item xs={12}>
          {columns.length > 1 && rows.length > 1 && <Table title="" columns={columns} data={rows} loading={loading} />}
        </Grid>
      </Grid>
    </Fade>
  );
};

export default Grants;
