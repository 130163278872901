import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

import MUIDataTable, { MUIDataTableProps } from 'mui-datatables';

// eslint-disable-next-line react/require-default-props
const DataTable: React.FC<MUIDataTableProps & { loading: boolean; rowsPerPage?: number }> = ({
  title,
  columns,
  data,
  loading,
  rowsPerPage,
  ...rest
}) => (
  <>
    {!loading ? (
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={{
          selectableRows: 'none',
          download: false,
          print: false,
          filterType: 'dropdown',
          rowsPerPage,
          elevation: 0,
        }}
        {...rest}
      />
    ) : (
      <Skeleton variant="rect" width="100%" height={800} />
    )}
  </>
);

export default DataTable;
