import React, { useCallback, useMemo } from 'react';
import { Box, Card, CardContent, CardHeader, Button, Grid } from '@material-ui/core';
import * as yup from 'yup';
import { useFirebase } from 'react-redux-firebase';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import UserAlert, { Status, UserAlertProps } from 'components/common/UserAlert';
import FormikTextField from 'components/common/FormikTextField';
import fire from 'firebase';
import useStyles from './UserProfile.styles';

const Password: React.FC<{ email: string | null }> = ({ email }) => {
  const classes = useStyles();
  const firebase = useFirebase();
  const { t } = useTranslation();
  const [openAlert, setOpenAlert] = React.useState<UserAlertProps>({
    open: false,
    message: '',
    status: Status.ERROR,
  });
  const { open, message, status } = openAlert;
  const handleClose = useCallback((event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert({ open: false });
  }, []);
  const updateProfileSchema = useMemo(
    () =>
      yup.object({
        currentPassword: yup.string().required(t('pages.register.validation.password.required')),
        password: yup
          .string()
          .required(t('pages.register.validation.password.required'))
          .matches(/^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/, t('pages.register.validation.password.pattern')),
        confirmPassword: yup
          .string()
          .required(t('pages.register.validation.passwordConfirm.required'))
          .oneOf([yup.ref('password')], t('pages.register.validation.passwordConfirm.match')),
      }),
    [t],
  );

  return (
    <>
      <Formik
        initialValues={{
          currentPassword: '',
          password: '',
          confirmPassword: '',
        }}
        onSubmit={async (value, { resetForm }) => {
          const credential = fire.auth.EmailAuthProvider.credential(email || '', value.currentPassword);
          try {
            await firebase.auth().currentUser?.reauthenticateWithCredential(credential);
          } catch (err) {
            if (err) {
              setOpenAlert({
                open: true,
                message: `${t('pages.profile.wrongPassword')}`,
                status: Status.ERROR,
              });
              return;
            }
          }

          firebase
            .auth()
            .currentUser?.updatePassword(value.password)
            .then(() => {
              setOpenAlert({
                open: true,
                message: `${t('pages.profile.success')}`,
                status: Status.SUCCESS,
              });
              resetForm({});
            })
            .catch(() => {
              setOpenAlert({
                open: true,
                message: `${t('pages.profile.error')}`,
                status: Status.ERROR,
              });
            });
        }}
        validationSchema={updateProfileSchema}
      >
        {({ handleChange, values }) => (
          <Form noValidate>
            <Card className={classes.root} elevation={0}>
              <CardHeader subheader={t('pages.profile.password.subHeader')} title={t('pages.profile.password.title')} />
              <CardContent>
                <Grid container spacing={3} lg={6} md={12}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      label={t('pages.profile.password.current')}
                      name="currentPassword"
                      onChange={handleChange}
                      type="password"
                      value={values.currentPassword}
                      component={FormikTextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      label={t('pages.profile.password.new')}
                      name="password"
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      component={FormikTextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      label={t('pages.profile.password.confirm')}
                      name="confirmPassword"
                      onChange={handleChange}
                      type="password"
                      value={values.confirmPassword}
                      component={FormikTextField}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button color="default" variant="outlined" type="submit" className={classes.button}>
                  {t('pages.profile.update')}
                </Button>
              </Box>
            </Card>
          </Form>
        )}
      </Formik>
      <UserAlert open={open} status={status} message={message} handleClose={handleClose} />
    </>
  );
};

export default Password;
