import { DocumentActions, DocumentActionTypes, Document } from './types';

export const getDocuments = (document: Document[]): DocumentActionTypes => ({
  type: DocumentActions.GetDocuments,
  payload: document,
});

export const deleteDocument = (id: string): DocumentActionTypes => ({
  type: DocumentActions.DeleteDocument,
  id,
});

export const addDocuments = (document: Document[]): DocumentActionTypes => ({
  type: DocumentActions.AddDocuments,
  payload: document,
});
