import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  // hero: {
  //   height: '300px',
  //   background: theme.palette.background.light,
  //   width: '100%',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   display: 'flex',
  // },
  // heroContentPad: { padding: 20 },
  heroText: {
    color: theme.palette.text.primary,
  },
  avatar: {
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    marginBottom: 20,
  },
  actionsContainer: {},
  media: {},
  mediaCardGrid: { marginTop: 20, maxWidth: 550 },
  mediaCard: {
    minHeight: 360,
    display: 'flex',
  },
  mediaCardCental: {
    minHeight: 360,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
  },
  bottom: {
    alignmentBaseline: 'baseline',
    paddingLeft: 16,
    paddingBottom: 20,
  },
  center: { textAlign: 'left' },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  button: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
}));
