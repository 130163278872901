import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Tab, { TabProps } from '@material-ui/core/Tab';
import React from 'react';

export const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      '&:focus': {
        opacity: 1,
      },
      display: 'flex',
      padding: `0 ${theme.spacing(1)}`,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > svg': {
        paddingRight: theme.spacing(1),
        fontSize: '26px',
      },
    },
    labelIcon: {
      minHeight: 'auto',
    },
    selected: {
      background: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius * 4,
      boxShadow: '3px -5px 40px rgba(205, 205, 212, 0.4)',
      color: theme.palette.common.black,
    },
  }),
)((props: TabProps) => <Tab disableRipple {...props} />);
