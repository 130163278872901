import React, { useEffect } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import routes from 'router/routes';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.up('lg')]: {
      paddingLeft: 230,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    paddingTop: 90,
    flexGrow: 1,

    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      padding: '6em 0em 0em 4em',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '6em 2em 4em',
    },
  },
  footer: {
    padding: '2rem',
    textAlign: 'center',
    width: '100%',
  },
}));

const Container: React.SFC = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // scroll to top on location change
  }, [location.pathname]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.contentContainer}>
        <main className={classes.content}>
          <>{children}</>
        </main>
        <div className={classes.footer}>
          <Typography variant="caption">
            <Trans i18nKey="general.footer.header" />
          </Typography>
          <br />
          {/* clear bit attribution https://clearbit.com/logo */}
          {location.pathname === `/${routes.company}` && (
            <Typography variant="caption">
              <a href="https://clearbit.com" style={{ textDecoration: 'none', color: '#13131A' }}>
                Logos provided by Clearbit
              </a>
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default Container;
