import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '3rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: 'black',
      [theme.breakpoints.down('sm')]: {
        marginTop: '1rem',
      },
    },
    btn: {
      width: '8rem',
      height: '50px',
      textAlign: 'center',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    h1: {
      fontSize: '36px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
      },
    },
    title: {
      textAlign: 'center',
    },
    card: {
      backgroundColor: 'white',
      borderRadius: '20px',
      width: '35rem',
      padding: '2rem',
      marginBottom: '3rem',
      [theme.breakpoints.down('sm')]: {
        width: '100vw',
      },
    },
    input: {
      width: '100%',
      height: '3rem',
      color: 'black',
      paddingLeft: '5px',
      borderRadius: '10px',
      marginBottom: '1rem',
      marginTop: '5px',
    },
    year: {
      width: '100%',
      height: '3rem',
      color: 'black',
      paddingLeft: '5px',
      borderRadius: '10px',
      marginBottom: '1rem',
      marginTop: '5px',
    },
    select: {
      marginBottom: '1rem',
      marginTop: '5px',
    },
    btnDiv: {
      width: '100%',
      marginTop: '5rem',
      display: 'flex',
      justifyContent: 'space-between',
    },
    slide3: {
      display: 'flex',
      width: '50%',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
      },
    },
    white: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '4rem',
      paddingBottom: '2rem',
      justifyContent: 'space-between',
      alignItems: 'center',
      textAlign: 'center',
      backgroundColor: 'white',
      color: '#0062ff',
      height: '20rem',
      width: '20rem',
      border: '2px solid #0062ff',
      borderRadius: '20px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        height: '13rem',
      },
    },
    whiteh4: {
      fontWeight: 600,
      marginBottom: '0.5rem',
    },
    whitep: {
      width: '14rem',
      fontSize: '14px',
      marginBottom: '0.5rem',
    },
    whitebtnDiv: {
      height: '3.5rem',
      width: '90%',
      borderRadius: '10px',
      border: '2px solid #0062ff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    blue: {
      display: 'flex',
      paddingTop: '4rem',
      paddingBottom: '2rem',
      textAlign: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#0062ff',
      color: 'white',
      height: '20rem',
      width: '20rem',
      borderRadius: '20px',
      [theme.breakpoints.down('sm')]: {
        height: '12rem',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        marginTop: '0.5rem',
      },
    },
    blueh4: {
      fontWeight: 600,
      marginBottom: '0.5rem',
    },
    bluep: {
      width: '14rem',
      fontSize: '14px',
      marginBottom: '0.5rem',
    },
    bluebtnDiv: {
      height: '3.5rem',
      width: '90%',
      backgroundColor: 'white',
      color: '#0062ff',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
  }),
);
