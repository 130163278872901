import { useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'store/firebase/selectors';
import { RootState } from 'store/types';
import { Status, UserAlertProps } from 'components/common/UserAlert';
import { usePrevious } from 'core/constants';
import { Company } from 'entities/dashboard';
import {
  synchronizeCompany,
  patchCompany as patchCompanyAction,
  patchApplicant as patchApplicantAction,
} from './actions';
import { CompanyDirectorPatch, DashboardState, OnboardLoadingErrorStateHookReturn } from './types';

export const useDashboardState = (): DashboardState => useSelector((state: RootState) => state.dashboard, shallowEqual);

export const useSynchronizedDashboardState = (): DashboardState => {
  const dashboard = useDashboardState();
  const auth = useAuth();
  const dispatch = useDispatch();
  const { uid, isLoaded, isEmpty } = auth;
  useEffect(() => {
    if (uid && !isEmpty && isLoaded && dashboard.synchronizing === undefined) {
      dispatch(synchronizeCompany());
    }
  }, [uid, isEmpty, isLoaded, dashboard, dispatch]);
  return dashboard;
};

export const useOnboardLoadingAndErrorState = (
  onError: Dispatch<SetStateAction<UserAlertProps>>,
  onSuccess: () => void,
): OnboardLoadingErrorStateHookReturn => {
  const patching = useSelector((state: RootState) => state.dashboard.patching);
  const submitting = useSelector((state: RootState) => state.dashboard.submitting);
  const error = useSelector((state: RootState) => state.dashboard.error);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loading = patching || submitting;
  const prevLoading = usePrevious(loading);

  useEffect(() => {
    if (prevLoading && !loading) {
      if (error) {
        const message = typeof error === 'string' ? error : t('general.validation.error');
        onError({
          open: true,
          message,
          status: Status.ERROR,
        });
      } else {
        onSuccess();
      }
    }
  }, [prevLoading, loading, error, onError, onSuccess, t]);

  const patchCompany = useCallback(
    (payload: Partial<Company>, finalStep = false) => {
      dispatch(patchCompanyAction(payload, finalStep));
    },
    [dispatch],
  );

  const patchApplicant = useCallback(
    (payload: CompanyDirectorPatch) => {
      dispatch(patchApplicantAction(payload));
    },
    [dispatch],
  );

  return { loading, error, patchCompany, patchApplicant };
};
