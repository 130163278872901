/* eslint-disable no-template-curly-in-string */
import { LenderCategoryIdMap } from 'entities/lender';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    resources: {
      en: {
        translation: {
          general: {
            validation: {
              error: 'Something went wrong, please try again later',
              globalError: 'Oops something went wrong.',
              globalErrorMessage: 'We are currently having some connections issues, please get in touch with support.',
              globalErrorAction: 'Contact support',
              serverError: 'Oops something went wrong',
              serverErrorMessage: 'We are currently having some connections issues.',
            },
            footer: {
              header: '© Copyright Business Score is the trading Name of Business Score Systems Ltd',
            },
            titles: {
              valuation: 'Business Valuation',
              creditLimit: 'Credit Limit',
              creditScore: 'Business credit score',
              learnMore: 'Learn more',
              getInTouch: 'Get in touch',
              bookTime: 'Book time',
              undecided: 'Still undecided?',
              continue: 'Continue',
              getStarted: 'Get started',
            },
            email: {
              supportSubject: 'Support query on business score platform',
            },
          },
          menus: {
            user: {
              logout: 'Logout',
            },
          },
          pages: {
            login: {
              loginButton: 'Login',
              signUpButton: 'Create an account instead',
              disclaimer: 'Get funding for your eCommerce company for ads, hiring, stock and growth',
              header: 'Log in to your account',
              emailLabel: 'E-mail',
              passwordLabel: 'Password',
              rememberMeLabel: 'Remember Me',
              forgotPasswordLink: 'Forgot Password',
              instantLogin: 'Instant Login',
              googleButton: 'Continue with Google',
              facebookButton: 'Continue with Facebook (soon)',

              sendResetEmailButton: 'Send',
              mobile: {
                header: 'Log In',
                signUpLink: "Don't have an account? Sign Up",
              },
              forgotPassword: {
                header: 'Enter your e-mail address to receive password reset instructions',
                success: 'Password reset successfully sent',
              },
              validation: {
                email: {
                  required: 'E-mail is required',
                  format: 'Must be a valid e-mail address',
                },
                password: {
                  required: 'Password is required',
                  length: 'Must be at least ${min} chars long',
                },
              },
              authErrors: {
                'auth/user-not-found': 'User e-mail not found.',
                'auth/wrong-password': 'Wrong credentials.',
              },
              messageSent: {
                header: 'Password reset sent',
                message1: 'Your password reset email is on its way to',
                message2: 'Just click the link in your email and continue.',
                button: 'Close',
              },
            },
            register: {
              loginButton: 'Login',
              disclaimer: 'Join over 260 eCommerce founders!',
              header: 'Create your free account',
              fullNameLabel: 'Full Name',
              emailLabel: 'E-mail',
              passwordLabel: 'Password',
              passwordConfirmLabel: 'Confirm password',
              registerButton: 'Create an account',
              instantRegister: 'Or sign up with:',
              googleButton: 'Continue with Google',
              mobile: {
                header: 'Register',
                loginLink: 'Already registered? Log in',
              },
              validation: {
                fullName: {
                  required: 'Full Name is required',
                  length: 'Must be at least ${min} chars long',
                },
                email: {
                  required: 'E-mail is required',
                  format: 'Must be a valid e-mail address',
                },
                password: {
                  required: 'Password is required',
                  pattern: 'Must be minimum of 8 Characters, at least one number and one alphabet',
                },
                passwordConfirm: {
                  required: 'Password confirm is required',
                  match: 'Passwords must match',
                },
              },
              authErrors: {
                'auth/email-already-in-use': 'This email is already in use.',
                'auth/invalid-password':
                  'The provided value for the password user property is invalid. It must be a string with at least six characters.',
              },
            },
            verification: {
              email: {
                header: '',
                disclaimer: 'Get funding for your eCommerce company for ads, hiring, stock and growth',
                header1: 'Email Verification',
                success: 'Email verified successfully click',
                link: 'to go to Login page',
                title: 'Email verification successful',
                description: 'Your email has been successfully verified',
                errorTitle: 'Email verification Failed',
                error: `Your verification link may have expired. To resend your verification link; login, navigate to profile page and select Verify your account.`,
                alreadyTitle: 'Your email is verified',
                alreadyDescription: `Your email address has already been verified`,
              },
              password: {
                button: 'Reset Password',
                disclaimer: 'Get funding for your eCommerce company for ads, hiring, stock and growth',
                header: 'Reset your password',
                title: 'Password reset successful',
                description: 'Your password was successfully reset',
                actionButton: 'Continue to login',
                newPasswordLabel: 'New password',
                errorTitle: 'Password reset Failed',
                error: `Your password reset link may have expired. Please, go to login then forgot password to send a new one.`,
              },
            },
            dashboard: {
              welcome: 'Welcome',
              detailsHeader: 'Business Score - the eCommerce funding platform',
              details1:
                'We’ve built a new way for eCommmerce businesses to access funding. Fair, fast and simple. From here, you can easily access investment, borrowing and grant options.',
              details2: '',
              equityTitle: 'Investment',
              header1: "What's new",
              header2: 'Business Metrics',
              header3: 'Access Funding',
              equityHeader: 'Accelerate your business to the next big thing',
              equityDetails:
                'Connect your sales data and get in front of 40+ eCommerce equity investors. We’ll track your progress and let you know when investors are interested in your business.',
              equityIncludes: 'Investors include',
              equityButton: 'Upload your pitch deck',
              lenderTitle: 'Borrowing',
              lenderHeader: 'Grow your business and manage cash flow',
              lenderDetails1:
                'We’ve simplified your borrowing options to 5 core products. Learn how they can support your business and make applications.',
              lenderIncludes: 'Lenders include',
              lenderButton: 'Explore your options',
              grantsTitle: 'Grants',
              grantsHeader: 'Access free money',
              grantsDetails: 'eCommerce grants categorized and listed in one place.',
              grantIncludes: 'Grant providers includes',
              grantButton: 'See list',
              blueCard: {
                main: 'Get Started',
                mainAlternate: 'Complete your form',
                body2: 'Complete your business profile to access business metrics and see your funding options.',
                caption: 'Start here',
              },
              optionsCard: {
                body1: 'You have 2 new',
                body2:
                  'There is no better advertisement campaign that is low cost and also successful at the same time.',
                caption: 'Start here',
              },
              creditScore: {
                caption1:
                  ' out of 999, your Business credit score is generated based on the financial information you have provided',
                caption2: 'Indicator of credit worthiness',
              },
              valuation: {
                caption1: 'Compared to ($21340 last year)',
                caption2: "Derived from several factors including last year's revenue and average monthly revenue",
              },
              credit: {
                caption1: 'Compared to ($19000 last year)',
                caption2: 'Amount of short-term funding lenders are likely to provide your business',
              },
            },
            companyDetails: {
              title: 'Company Details',
              button: 'Update',
              alert: 'Details updated successfully',
              cta: 'Create your company to get started',
              buttonEmpty: 'Get started',
              tabs: {
                title1: 'Company Details',
                subtitle1: 'View and edit details like company name and website',
                title2: 'Funding Requirements',
                subtitle2: 'View and edit all your funding requirements',
                title3: 'Financial Details',
                subtitle3: 'View and edit all your sales data',
              },
            },
            onBoarding: {
              welcome: {
                getStarted: "Let's get started",
              },
              forwardButton: 'Next',
              backButton: 'Back',
              submitButton: 'Complete',
              slide0Header: 'Get started',
              slide1Header: 'Your funding goals?',
              slide2Header: 'Tell us about your business',
              slide3Header: 'Tell us about you',
              slide4Header: "How's your business performing?",
              slide5Header: 'Congrats ',
              maxValidate: 'percentage must not be more than 100',
              slide3paragraph: 'Your data will remain anonymized and never shared without your permission.',
              companyTypeLabel: 'What kind of business do you run?',
              companyTypeValidate: 'Kind of business is required',
              companyIncorporatedLabel: 'Where is your business incorporated?',
              companyIncorporatedValidate: 'Business incorporated is required',
              companyTradingNameLabel: "What's your business trading name?",
              companyTradingNameValidate: 'Trading name is required',
              companyNameLabel: "What's your registered company name?",
              companyNameValidate: 'Registered company name is required',
              companyNameLabelToolTip: 'If this is the same as your trading name, just retype it here',
              websiteLabel: "What's your business website?",
              websiteLabelToolTip: 'If you sell on a marketplace rather than your own site, just link that here.',
              companyRegisteredNumberLabel: "What's your registered company number?",
              companyRegisteredNumberValidate: 'Registered number is required',
              companyIncorporatedOnLabel: 'Incorporated on',
              companyIncorporatedOnValidate: 'Incorporated on is required',
              TradingYearLabel: 'Which year did you start trading?',
              TradingYearToolTip: 'Use the date that you officially registered your business as your starting point.',
              TradingYearValidate: 'start Year is required',
              directorChoiceLabel: 'Which director are you?',
              applicantNameLabel: "What's your name?",
              applicantNameValidate: 'name is required',
              applicantEmailLabel: "What's your email address?",
              applicantEmailValidate: 'email address is required',
              applicantContactNumberLabel: "What's your best contact number?",
              applicantContantNumberValidate: 'contact number is required',
              applicantHouseNumberLabel: 'House number/house name',
              applicantHouseNumberValidate: 'House number is required',
              applicantAddressLine1Label: 'Address line 1',
              applicantAddressLine1Validate: 'Address line 1 is required',
              applicantAddressLine2Label: 'Address line 2',
              applicantCityLabel: 'City',
              applicantCityValidate: 'City is required',
              applicantCountyLabel: 'County/State',
              applicantCountyValidate: 'County/State is required',
              applicantCountryLabel: 'Country',
              applicantCountryValidate: 'Country is required',
              applicantPostcodeLabel: 'PostCode',
              applicantPostcodeValidate: 'PostCode is required',
              applicantCurrentLabel: 'Do you currently live here?',
              applicantCurrentValidate: 'One current address is needed',
              applicantAddressStartDateLabel: 'When did you move into this address?',
              applicantAddressStartDateValidate: 'This field is required',
              applicantResidentialStatusLabel: "What's your residential status?",
              applicantResidentialStatusValidate: 'residential status is required',
              applicantResidentialStatusLabelToolTip:
                "Don't worry, this is just to match you with the best options and doesn't mean you'll have to give up any security if you don't want to.",
              applicantPropertyEquityLabel: 'How much equity do you have in your property?',
              applicantPropertyEquityValidate: 'property equity is required',
              applicantPropertyEquityLabelToolTip: "Don't worry, we only use this to sort options",
              applicantAddAddressButton: 'Add another address',
              applicantAddressSectionTitle: 'Personal Address',
              applicantAddressDateWarning: 'We need a 3 year address history to progress',
              applicantDuplicateCurrentWarning: 'Duplicate current address issue',
              monthlyRevenueLabel: "What's your average monthly turnover?",
              monthlyRevenueValidate: 'Average Monthly Revenue must be greater than zero',
              monthlyRevenueLabelToolTip: 'Please base this off your last 3 months.',
              fundingAmountLabel: 'How much funding do you need?',
              fundingAmountValidate: 'funding amount is required',
              fundingAmountLabelToolTip: "This isn't final. If you're unsure, just give an estimate.",
              fundingReasonsLabel: 'What will you use your funding for? (Select all that apply)',
              fundingReasonsValidate: 'Select at least one',
              fundingTimelineLabel: 'When do you need your funding?',
              fundingTimeValidate: 'funding time is required',
              fundingTimelineLabelToolTip: 'Just give an approximate date.',
              fundingUnpaidInvoicesLabel: 'Do you have unpaid customer invoices you would like to exchange for cash?',
              fundingUnpaidLabelTooltip:
                'If your business issues invoices to customers (like retailers) whereby they pay after 30 or 60 days, you may be able to unlock the value in these invoices by "selling" them to a lender.',
              fundingOwedInvoicesLabel: 'How much are you owed in customer invoices?',
              fundingOwedValidate: 'owed customer invoices is required',
              fundingOwedLabelTooltip: 'Just a rough estimate is fine.',
              salesPlatformLabel: 'Where do your sales come from?',
              salesPlatformTitle: 'Sales Platform',
              salesPercentageTitle: 'Sales Percentage',
              salesPlatformLabelToolTip: 'Please use approximate figures.',
              revenueLastYear: 'What was your revenue in',
              revenueLastYearValidate: 'last year revenue is required',
              revenueLastYearToolTip: 'As filed in your annual accounts at Companies House.',
              revenueThisYear:
                'How much revenue have you made in the last 12 months or since you started trading if less than 12 months?',
              revenueThisYearValidate: 'revenue is required',
              revenueThisYearToolTip: 'Please use the 12 months (or fewer) to date.',
              grossMargin: "What's your gross profit margin?",
              grossMarginValidate: 'gross margin is required',
              grossMarginToolTip:
                'You can calculate it by taking your sales revenue minus cost of goods sold (COGS) and divide this by your sales revenue, e.g. £10,000 sales - £7,000 COGS, divided by £10,000 sales = 0.3 = 30% gross profit margin. Please note COGS includes all costs associated with manufacturing and delivering goods to customers but excludes all operating costs including staff and office space.',
              reccuringPercentage: 'How much of your revenue comes from repeat customers?',
              reccuringPercentageValidate: 'reccuring percentage is required',
              reccuringPercentageToolTip: 'This could be via subscription or recurring orders.',
              businessCustomerRevenuePercentage: 'How much of your revenue comes from business customers?',
              businessCustomerRevenueValidate: 'Field is required',
              businessCustomerRevenueToolTip:
                'Typically these customers will invoice you and payment is made after 30 or 60 days.',
              cashBalance: "What's your current cash balance?",
              cashBalanceValidate: 'Cash balance is required',
              cashBalanceToolTip:
                'This includes money in your business bank accounts and as well as money waiting to be cleared (less than 7 days) in your seller accounts.',
              debitBalance: "What's your current debt balance?",
              debitBalanceValidate: 'Debt balance is required',
              debitBalanceToolTip: 'This is the total value of all your outstanding loans.',
              inventoryBalance: "What's your current inventory balance?",
              inventoryBalanceValidate: 'inventory balance is required',
              inventoryBalanceToolTip: 'This is the total value of all your current unsold stock at cost price.',
              receivableBalance: "What's your current accounts receivables balance?",
              receivableBalanceValidate: 'receivable balance is required',
              receivableBalanceToolTip:
                'This is the value of unpaid invoices from business customers or expected platform payouts (in more than 7 days).',
              debtBalanceLabel: "What's your current debt balance?",
              repeatCustomersRevenuePercentageLabel: 'How much of your revenue comes from repeat customers?',
              shopify: 'Shopify',
              ebay: 'Ebay',
              wooCommerce: 'Woo Commerce',
              amazon: 'Amazon',
              offline: 'Offline',
              other: 'Others',
              manualEntryHeader: 'Manual Entry',
              manualEntryP1: 'Get pre-approved for funding',
              manualEntryP2: 'See your business valuation',
              manualEntryP3: 'x - No access to equity investors (as they require validated sales)',
              manualEntryButton: 'Submit',
              fullConnectHeader: 'Full Connect',
              fullConnectP1: 'Get pre-approved for funding',
              fullConnectP2: 'See your business valuation',
              fullConnectP3: 'Introductions to equity investors',
              fullConnectButton: 'Connect',
              sliderError: 'Your total sales percentage is above 100%',
              validation: {
                fullName: {
                  required: 'Full Name is required',
                  length: 'Must be at least ${min} chars long',
                },
                email: {
                  required: 'E-mail is required',
                  format: 'Must be a valid e-mail address',
                },
                password: {
                  required: 'Password is required',
                  length: 'Must be at least ${min} chars long',
                },
                passwordConfirm: {
                  required: 'Password confirm is required',
                  match: 'Passwords must match',
                },
              },
              finish: {
                main: 'Congrats',
                subtitle: "You're one step closer to getting funding for your business",
                button: 'Finish',
              },
            },
            documents: {
              title: 'Documents',
              subtitle:
                'Upload information to support your funding applications here. <br/><br/> Please ensure files are no larger than 20MB.',
              uploadMessage1: 'Drag and drop or ',
              uploadMessage2: 'click here to select a file',
              validation: {
                success: 'Your document(s) were securely uploaded',
                successDelete: 'Your document was successfully deleted',
                error: 'Your document is above 20MB, please reduce the file size.',
                rejected: `One or more of your documents was rejected.
                  Please use the following types`,
                deleteWarningTitle: `Are you sure you want to delete {{name}}`,
                deleteWarningDescription: `Once the document is deleted, it can't be recovered.`,
              },
            },
            profile: {
              title: 'Profile',
              subtitle: 'Update your profile details here',
              success: 'Update Successful',
              error: 'Something went wrong try again later',
              wrongPassword: 'Password is incorrect',
              email: 'Email is required',
              phone: 'Phone number is required',
              displayName: 'Display Name is required',
              save: 'Save details',
              update: 'Update',
              photo: {
                title: 'Photo',
                button: 'Upload Photo',
              },
              accountList: {
                title: 'You signed up with the following account(s):',
                toolTip: 'You signed up with a',
                validation: {
                  rejected: 'Only jpeg and png image files below 5mb are accepted',
                },
              },
              details: {
                title: 'Personal info',
                displayName: 'Name',
                email: 'Email',
                phone: 'Phone Number',
              },
              password: {
                subHeader: 'Update your password',
                title: 'Password',
                current: 'Current password',
                new: 'New password',
                confirm: 'Confirm password',
              },
              verify: {
                title: 'Your email address is not verified',
                description:
                  'To verify your email address, click on the button below to send a verification link to your email address.',
                button: 'Verify your email address',
                sendEmailSuccess: 'Your verification email is on its way.',
                sendEmailFailure: 'Your verification email not sent, please try again',
              },
            },
            grants: {
              header: 'Grants',
              details1: 'Check out the grants available for your eCommerce businesses.',
              details2: `We aim to keep this as up to date as possible. Over 100 grants have been categorized and listed on the platform.The grants are listed across 
              several categories including: Manufacturing, covid-related support, Payroll and others.`,
            },
            metrics: {
              header: 'Dashboard',
              details1: 'Dive into your eCommerce store metrics with the Business Score metrics dashboard',
            },
            perks: {
              header: 'Perks',
              details1: "Check out our perks for Business Score members. We'll be adding more soon!",
              button: 'Get this perk',
              offerMessage: 'Have a great offer that would suit our customers?',
              offerButton: 'Get in touch',
            },
            investors: {
              header: 'Investment',
              details: `Upload your pitch deck to get started, then`,
              action: 'book a call with our team',
              uploadMessage: 'Drag and drop or click here to select your pitch deck',
            },
            integration: {
              header: 'Integrations',
              connectHeader: 'Connect your store',
              connectDescription:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mollis condimentum aliquet. Ut ac justo efficitur, dapibus nisl eu, cursus erat. Vivamus fermentum, sapien non placerat dapibus, neque odio rhoncus lacus, et pellentesque turpis leo sit amet metus. Proin in efficitur velit. Proin elit velit, fermentum auctor mauris sit amet, egestas consectetur tortor. ',
              action: 'Connect to Store',
              actionDone: 'Connected',
              connected: 'Store connected',
            },
            integrationDetails: {
              header: 'integration',
              storeName: 'Store name',
              sync: 'Synchronize',
              lastSync: 'Your store was last synchronized on',
              chartYLabel: 'subtotal',
              chartXLabel: 'transactions',
              details1:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis finibus arcu, a lacinia turpis. Mauris efficitur tortor condimentum libero hendrerit mattis a ut nulla. Phasellus in feugiat turpis. Nulla vel vestibulum lorem. Quisque viverra pharetra molestie. Etiam malesuada purus dolor, ac ullamcorper velit maximus sed. Nulla sit amet facilisis ante, ut tincidunt diam. Nulla commodo tempus odio et lobortis.',
              details2:
                'Integer iaculis pharetra metus, in fringilla massa fermentum sit amet. Duis eget nisl eu orci venenatis tristique at in lacus. Nunc sed erat vel ex tempus finibus bibendum a purus. Ut congue sem id congue interdum. Morbi malesuada nibh maximus justo lobortis, non suscipit quam auctor. Donec in felis condimentum, venenatis est ac, facilisis felis.',
            },
            lendersList: {
              header: 'Borrowing',
              details: `Learn about your borrowing options and make applications.`,
              card: {
                aka: 'aka',
                applyButton: 'Apply',
                appliedButton: 'Applied',
                learnMoreButton: 'Learn more',
                caption: 'Requirements',
              },
              criteria: {
                age3months: '3 months+', // 0
                age6months: '6 months+', // 1
                age12months: '12 months+', // 2
                revenue20k: '£20k/yr+', // 3
                monthlyRevenue5k: '£5k/m+', // 4
                monthlyRevenue4k: '£4k/m+', // 5
                monthlyRevenue3k: '£3k/m+', // 6
                monthlyRevenue2k: '£2k/m+', // 7
                monthlyRevenue500: '£500/m+', // 8
                b2bSales5: '5%+ B2B sales', // 9
              },
              bookTimeCTA: 'in with one of our team',
            },
            lendersDetail: {
              backButton: 'Back',
              applyButton: 'Apply',
              appliedButton: 'Applied',
              lenderCategories: {
                [LenderCategoryIdMap.revenueBasedFiance]: {
                  name: 'Revenue-Based Finance',
                  alias: 'The Flexible One',
                  shortDescription: 'Cash upfront and flexible repayments that track the performance of your business',
                  aka: 'AKA',
                  description: `**How it works**\n\nRevenue-based finance works a bit like a business loan, 
                  but with more flexible repayments. Lenders will provide you with money based on your sales 
                  and marketing data. You then make repayments daily, weekly or monthly, plus a fixed fee and 
                  interest on top. Sounds a lot like a business loan, right? The difference is that these repayments 
                  are taken as an agreed percentage of your business revenue. You have a slow month? You pay less. 
                  You have a better month? You pay more. With RBF, you only pay what you can afford.`,
                  guide: `###### Revenue-Based Finance aka The Flexible One\n\n**What we like about it**\n- The process 
                  is super quick. Decisions can be made within 24 hours and only require you to provide ‘read-only’ access to 
                  your sales data.\n- The repayments for revenue based finance are very flexible. Unlike most loans, there are 
                  no fixed monthly payments, and they are based on business performance.\n- RBF provides you with upfront capital, 
                  meaning you can accelerate your business growth in a shorter period of time. This is particularly great if your 
                  business has high sales periods - you can fund your company in line with these peaks.\n\n**What to 
                  watch out for**\n- If your revenue grows very quickly, so do your repayments. 
                  On the plus side, this will pay the loan off faster. However as the repayments are fixed, this will mean 
                  you’ve technically paid a higher APR.\n- Some lenders will provide you with a credit card to use and will 
                  specify exactly what the spending is for.\n- You will start paying back the loan as soon as your business 
                  receives any revenue. Make sure that your gross margins are high enough so that you can afford to repay the 
                  pre-agreed percentage. (Unsure? Don’t worry - we can talk you through the details.)\n\n**What 
                  are Revenue-Based Lenders looking for?**\n\nYour business must have:\n- at least 3 months 
                  trading history\n- consistently high gross margins of 30% or more.\n- monthly sales of over £4k.\n- majority 
                  of your sales online i.e. via Shopify or Amazon.`,
                  case: `**Case Study**\n\nAlex is preparing for Christmas which is typically a great time for her watch brand. 
                  In order to boost sales even more this year, Alex wants to run advertising campaigns to drive traffic to her 
                  website. She decides to use revenue-based finance to help fund this.\n\nAlex received £20,000 from a lender 
                  with a fixed fee of £2,000, so in total she will repay £22,000. She will reimburse 15% of her weekly sales 
                  until the £22,000 balance is repaid. This is good news for her as, after a busy Christmas period she wants to 
                  take a well-deserved break for a week where she closes shop. She doesn’t need to worry about not being able to 
                  make her repayments as they will simply pick back up when she returns. Therefore, revenue based finance is 
                  perfect for her business.`,
                  providersDescription: `**Providers**\n\nHere are just some of the providers of 
                  revenue-based finance. `,
                  confirmation: {
                    title: 'Revenue-Based Finance application',
                    description: `By pressing continue, you give us permission to find you options using the information you have submitted. You confirm that your business:\n\n- Registered at Companies House at least 3 months ago\n\n- Generates £4,000+ monthly revenue`,
                  },
                },
                [LenderCategoryIdMap.businessLoan]: {
                  name: 'Business Loan',
                  alias: 'The Traditional One',
                  shortDescription:
                    'Lump sum of money upfront repaid over a fixed duration with an agreed interest rate',
                  aka: 'AKA',
                  description: `**How it works**\n\nLenders will provide you with a lump sum of money plus an additional set interest rate. The interest rate is the cost of borrowing the funds. You then repay both the capital and interest over an agreed period of time.`,
                  guide: `###### Business Loan aka “The Traditional One”\n\n**What we like about it**\n- Out of any of the business funding options, it is loans that provide you with the largest amount of capital upfront - up to 25% of your turnover. If you need to borrow a larger amount of money, the Business Loan is your friend.\nLoan lenders don’t have requirements on what you use the funds for. Therefore, Business Loans give you the gift of flexibility to spend it on what you want.\n - Loans are dependable and regular. The interest rate and length of repayment is agreed upfront, meaning that your monthly installments are consistent and uniform.\n\n**What to watch out for**\n\n - Many loan providers require lots of information about your business which can make for a slower process. Since it may take a few days to get your paperwork sorted, make sure you plan ahead and don’t leave it to the last minute!\n-As repayments are fixed, you need to be sure that you will be able to afford monthly repayments even during quiet periods such as post-Christmas.\n-With hundreds of lenders in the market it can be confusing knowing the best option. (That’s ok, though - we can help with this!)\n\n **What are Business Loan Lenders looking for?**\n\n Your business must have: \n\n - been trading for at least 1+ years, with up-to-date filed accounts reported to Companies House.**\n- at least 10% profitability. This is to show that you can afford the monthly repayments.**\n- a plan of how you are going to use the money and cash flow forecast to show how the business will perform moving forward.`,
                  case: `**Case Study**\n\nMatthew owns a fashion brand. He wants to manufacture a new tracksuit range. He’s currently been selling t-shirts and hoodies, however, he sees a great opportunity to expand. Matthew has already put some designs on his website, has had a few pre-orders and is feeling confident.\n\n Matthew applies for a £20,000 loan over 3 years from a lender with an interest rate of 10%. His monthly repayments over the 3 years will be £641.30 and in total, Matthew will repay £23,086.94.`,
                  providersDescription: `**Providers**\n\nHere are just some of the providers of business loans.`,
                  confirmation: {
                    title: 'Business Loan Application',
                    description: `By pressing continue, you give us permission to find you options using the information you have submitted. You confirm that your business:\n\n- Registered at Companies House at least 12 months ago\n\n - Filed accounts at Companies House with £20,000+ annual revenue\n\n - Generates £5,000+ monthly revenue`,
                  },
                },
                [LenderCategoryIdMap.creditLine]: {
                  name: 'Credit Line',
                  alias: 'The Buffer',
                  shortDescription:
                    'Works like an overdraft, so you only pay for what you use. Ideal if you need a cash buffer',
                  aka: 'AKA',
                  description: `**How it works**\n\nLine of credit works like an overdraft. You have a pot of money which acts as a buffer. You can dip into this as and when you need it. You only pay interest on what you’ve used. However, bear in mind that once the money has been used, it is not renewed.`,
                  guide: `###### Credit Line aka “The Buffer” \n\n**What we like about it**\n- Lines of credit offer you peace of mind and a buffer that ease cash flow concerns.\n- Many lenders offer interest-only arrangements meaning you only pay when you dip into it.\n\n**What to 
                  watch out for**\n- Just like an overdraft, living in a line of credit can be more expensive than other types of funding. This is because it’s designed only to be dipped into as and when you need it - not to function within it. \n\n**What are Line of Credit Lenders looking for?**\n\nYour business must have:\n-  at least 6 months trading to show your business is stable.\n- consistently high gross margins of 30% or more.\n\n On top of this, you must provide a personal guarantee. A personal guarantee is a valuable asset like a property, vehicle, stock, an investment or cash. This is so that if for whatever reason your business can’t repay the line of credit, then you can. `,
                  case: `**Case Study**\n\nSophie runs an art shop. She has had strong sales online, however, summer is coming and she has plans to do an offline pop-up store in Box Park. Sophie has to pay for some visual and creative work for the pop-up and uses her credit line to pay for materials before she recovers the spend through sales.\n\n Sophie applied for a £20,000 credit line with a 15% interest charge. When she’s dipped into the credit line by £10,000 she pays £4 per day until she pays back the funds. `,
                  providersDescription: `**Providers**\n\nHere are just some of the providers of credit lines. `,
                  confirmation: {
                    title: 'Credit Line Application',
                    description: `By pressing continue, you give us permission to find you options using the information you have submitted. You confirm that your business:\n\n- Registered at Companies House at least 6 months ago\n\n - Generates £2,000+ monthly revenue`,
                  },
                },
                [LenderCategoryIdMap.invoiceFinance]: {
                  name: 'Invoice Finance',
                  alias: 'The Chaser',
                  shortDescription: 'Manage cash flow by selling your unpaid invoices to lenders',
                  aka: 'AKA',
                  description: `**How it works**\n\nInvoice finance is somewhat different from traditional forms of finance. It might be easier to explain using an example. Imagine this scenario:\n\nYou’re a company who provides goods to your customers. In order to pay you, your customers raise invoices with you. However invoice financing allows you to swap around this process. If you need some capital, lenders will give it to you by buying your invoices directly from you. Then, once you’ve delivered your goods, your customers pay back your invoices to the lender directly. Everybody’s happy!\n\nInvoice financing is also sometimes called 'accounts receivable financing' or 'receivables financing.'`,
                  guide: `###### Invoice Finance aka “The Chaser”\n\n**What we like about it**\n- Invoice financing really frees up cash-flow for your business, especially when you have challenging payment terms with customers.\n- Invoice finance fees are a tax-deductible cost meaning you can write them off of your business tax bill.\n- Many providers offer a selective invoice finance service where you can pick and choose which invoices to have funded.\n\n**What to watch out for**\n- Your customers will be aware that you’re using invoice finance. Whilst you may prefer this not to be the case, bear in mind that this is standard practise for many retailers.\n- Ensure that the lender you go with is flexible. Your business may be growing at a rapid rate and you don’t want to be stuck in a facility that doesn't grow as you do.\n- Be aware of any additional rates stated on the contract which could eat into your margins. (We can help with this by going through your contract with you, to make sure you understand all of it!)\n\n**What are Invoice Finance Lenders looking for?**\n\nYour business must have:\n- business customers, such as a retailer. You cannot get invoice financing if you are a business-to-consumer company. \n- reputable, dependable business customers. The more reputable your business customers are, the easier it will be to get invoice financing.You’ll also be offered better rates.`,
                  case: `**Case Study**\n\nAndre runs a coffee business. He has just landed a retail deal with a national coffee chain. They will buy his stock, however the payment terms are 90 days - leaving him short of cash. He turns to invoice financing to help him.\n\nAndre submitted an invoice worth £20,000 and his lender provided him an advance of 80% of the invoice upfront - £16,000. Fast forward 90 days later, and Andre’s customer -the national coffee chain - has paid the lender. The lender paid Andre the remaining 15% (£3,200) and kept 5% as their fee.\n\nThe national coffee chain was so pleased with Andre’s products, they became repeat buyers and business has never been better for Andre.`,
                  providersDescription: `**Providers**\n\nBelow is a list of some - but by no means all - of the providers of Invoice Finance Lenders. `,
                  confirmation: {
                    title: 'Invoice Finance Application',
                    description: `By pressing continue, you give us permission to find you options using the information you have submitted. You confirm that your business:\n\n- Generates £500+ monthly revenue\n\n - Sells 5%+ of its products to other businesses`,
                  },
                },
                [LenderCategoryIdMap.stockFinance]: {
                  name: 'Stock Finance',
                  alias: 'The IOU',
                  shortDescription: 'Get stock without using your own cash, then repay as you sell your products',
                  aka: 'AKA',
                  description: `**How it works**\n\nStock finance is a type of working capital where the lender pays for your inventory or stock and sends funds directly to your supplier. This means that if you’re having cash-flow issues, you can still fulfil your orders.\n\nPro-tip: Lenders can even pay your supplier in local currency to avoid foreign exchange loses!`,
                  guide: `###### Stock finance aka “The IOU”\n\n**What we like about it**\n\n- The stock finance loan frees up cash flow for your business. This is especially useful when you have challenging payment terms with your customers.\n- Many providers offer a selective invoice finance service where you can pick and choose which invoices you need funded.\n\n**What to watch out for**\n- Stock finance loans can be less flexible than other types of funding.\n- The main thing to look out for are the additional fees. Check the contract. (If you’re unsure - let us know, we can help.)\n\n**What are The Stock Finance Lenders looking for?**\n\nYour business must have:\n- an established relationship with your supplier.\n-  a track record of successfully selling the products you are buying.`,
                  case: `**Case Study**\n\nYaniv sells electric scooters. The scooters are manufactured in Germany, and he wants to place an order soon to avoid a lack of stock on his website. So he turns to trade finance in order to afford this.\n\nYaniv places an order of €50,000 worth of scooters using a stock finance loan at a rate of 1.5% per month. The lender purchases the scooters from the manufacturer and 3 months later Yaniv has €100,000 cash as a result of selling all the stock. Yaniv repays the lender €50,000 plus the 4.5% fee - €2250.  `,
                  providersDescription: `**Providers**\n\nBelow is a list of some - but by no means all - of the providers of Trade Finance Loan Lenders.`,
                  confirmation: {
                    title: 'Credit Line Application',
                    description: `By pressing continue, you give us permission to find you options using the information you have submitted. You confirm that your business:\n\n- Registered at Companies House at least 6 months ago\n\n- Generates £5,000+ monthly revenue\n\n- Has ordered from your current supplier before`,
                  },
                },
                // [LenderIDs.creditCard]: {
                //   name: 'Business Credit Card',
                //   alias: 'The Plastic',
                //   shortDescription:
                //     'Like a personal credit card, but for your business. Easy.',
                //   aka: 'AKA',
                //   description: ``,
                //   guide: ``,
                //   case: ``,
                //   providersDescription: ``,
                //   confirmation: {
                //     title: 'Business Credit Card Application',
                //     description: `By pressing continue, you give us permission to find you options using the information you have submitted. You confirm that your business:\n\n- Registered at Companies House at least 3 months ago\n\n Generates £3,000+ monthly revenue`
                // },
              },
            },
          },
          links: {
            siderBar: {
              home: 'Home',
              grants: 'Grants',
              metrics: 'Dashboard',
              profile: 'Profile',
              documents: 'Documents',
            },
          },
          components: {
            documentsList: {
              uploadHistory: 'Uploaded documents',
              rejectedFiles: 'The following file(s) were rejected',
              noDocuments: "You don't have any uploaded documents",
            },
            fundingTime: {
              asap: 'ASAP',
              fewweeks: 'Few weeks',
              fewMonths: 'In a few months',
              justBrowsing: 'Just browsing',
              nextYear: 'Next year',
            },
          },
          services: {
            intercom: {
              help: 'Hi Business Score. I need some help with the portal.',
            },
          },
        },
      },
      // added pt-BR for example only
      'pt-BR': {
        translation: {
          pages: {
            login: {
              loginButton: 'Entrar',
            },
          },
        },
      },
    },
  });

export default i18n;
