import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import CompanyProfile from './company-profile';
import Documents from './documents';

const Funding: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/profile`} exact component={CompanyProfile} />
      <Route path={`${path}/documents`} exact component={Documents} />
      <Route>
        <Redirect to={`${path}/profile`} />
      </Route>
    </Switch>
  );
};

export default Funding;
