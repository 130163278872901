import Typography from '@material-ui/core/Typography';
import React, { FC, ReactNode } from 'react';
import ReactMarkdown, { AllowedTypesProp, DisallowedTypesProp, ReactMarkdownPropsBase } from 'react-markdown';

const heading = ({ children, level }: { children: ReactNode; level: number }) => (
  <Typography variant={`h${level}` as 'h1'}>{children}</Typography>
);

const link = ({ children, href }: { children: ReactNode; href: string }) => (
  <a
    style={{ borderBottom: '1px solid #000', fontWeight: 'bold' }}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);

const paragraph = ({ children }: { children: ReactNode }) => (
  <Typography variant="body1" gutterBottom>
    {children}
  </Typography>
);

type MarkdownProps = ReactMarkdownPropsBase &
  MutuallyExclusive<AllowedTypesProp, DisallowedTypesProp> & {
    children: string;
  };

const Markdown: FC<MarkdownProps> = (props) => (
  <ReactMarkdown
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    className="markdown"
    renderers={{ heading, link, paragraph }}
    escapeHtml={false}
  />
);

export default Markdown;
