import { UserState, UserActionTypes, USER_CHANGE_THEME_MODE, USER_TOGGLE_THEME_MODE } from './types';

export const initialState: UserState = {
  preferences: {
    themeMode: 'dark',
  },
};

export const userReducer = (state = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case USER_CHANGE_THEME_MODE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          themeMode: action.payload,
        },
      };
    case USER_TOGGLE_THEME_MODE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          themeMode: state.preferences.themeMode === 'dark' ? 'light' : 'dark',
        },
      };
    default:
      return state;
  }
};

export default userReducer;
