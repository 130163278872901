import { PaletteType } from '@material-ui/core';
import { UserActionTypes, PIPEDRIVE_USER_UPDATED, USER_CHANGE_THEME_MODE, USER_TOGGLE_THEME_MODE } from './types';

export const pipedriveUserUpdated = (): UserActionTypes => ({
  type: PIPEDRIVE_USER_UPDATED,
});

export const userChangeThemeMode = (mode: PaletteType): UserActionTypes => ({
  type: USER_CHANGE_THEME_MODE,
  payload: mode,
});

export const userToggleThemeMode = (): UserActionTypes => ({
  type: USER_TOGGLE_THEME_MODE,
});
