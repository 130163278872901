const routes = {
  default: '/d',
  profile: 'd/profile',
  logout: '/logout',
  onboarding: '/onboard',
  home: 'd/home',
  company: 'd/company',
  documents: 'd/company/documents',
  companyProfile: 'd/company/profile',
  funding: 'd/funding',
  grants: 'd/funding/grants',
  lenders: 'd/funding/lenders',
  investors: 'd/funding/investors',
  integration: 'd/integration',
  perks: 'd/perks',
  admin: 'd/admin',
  adminCompany: '/d/admin/company',
  dashboard: 'd/dashboard',
  verifyEmail: '/auth/email',
  passwordReset: '/auth/passwordReset',
  authListener: '/auth',
  login: '/login',
  register: '/register',
} as const;

export default routes;
