import { colors, PaletteType } from '@material-ui/core';
import { unstable_createMuiStrictModeTheme as createMuiTheme, Theme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

export const timeouts = {
  grow: {
    first: 500,
    second: 1000,
    third: 1500,
  },
  fade: 800,
  countTo: 1234,
};

const theme = (mode: PaletteType): Theme => {
  const dark = mode === 'light';
  return createMuiTheme({
    overrides: {
      MuiButton: {
        root: {
          borderRadius: '3px',
          textTransform: 'unset',
          fontFamily: ['inter bold'].join(','),
          alignItems: 'center',
          justifyContent: 'center',
          display: 'inline-flex',
          height: '32px',
          padding: '0px 12px',
          fontSize: 'inherit',
          fontWeight: 400,
          margin: '8px 0 4px',
        },

        containedPrimary: {
          color: dark ? '#FAFAFB!important' : '#FFFFFF!important',
          boxShadow: 'none',

          '&$hover': {
            boxShadow: 'none',
          },
        },
        outlined: {
          border: `1px solid ${dark ? '#EFEFEF' : '#E2E2EA'}`,

          '&$disabled': {
            border: `1px solid ${dark ? '#EFEFEF' : '#E2E2EA'}`,
            cursor: 'default',
          },
        },
      },
      MuiDrawer: {
        paperAnchorDockedLeft: {
          backgroundColor: dark ? '#13131A' : '#FFFFFF',
          borderRight: `1px solid ${dark ? '#2B2932' : '#E2E2EA'}`,
        },
      },
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: dark ? '#2B2932' : '#E2E2EA',
        },
      },
      MuiToolbar: {
        regular: {
          backgroundColor: dark ? '#13131A' : '#FFFFFF',
        },
      },
      MuiIconButton: {
        root: {
          color: dark ? '#FAFAFB' : '#13131A',
        },
      },

      MuiPaper: {
        root: {
          background: dark ? '#13131A' : '#FAFAFB',
        },
      },
      MuiInput: {
        fullWidth: {
          borderBottom: `1px solid ${dark ? '#92929D' : '#44444F'}`,
          color: dark ? '#FAFAFB' : '#13131A',
        },
      },
      MuiInputLabel: {
        root: {
          background: dark ? 'inherit' : '#FAFAFB',
          color: dark ? '#FAFAFB!important' : `${colors.grey[700]}!important`,
          whiteSpace: 'nowrap',
          fontSize: '18px',
          fontWeight: 400,

          [breakpoints.down('sm')]: {
            fontSize: 20,
          },
        },
      },
      MuiInputBase: {
        root: {
          '&$disabled': {
            background: dark ? '#13131A!important' : '#FAFAFB!important',
            border: `1px solid ${dark ? '#44444F!important' : '#EFEFEF!important'}`,
            color: dark ? '#44444F!important' : '#92929D!important',
            padding: 2,
          },
          [breakpoints.down('sm')]: {
            fontSize: 30,
          },
        },
      },
      MuiInputAdornment: {
        root: { marginTop: '-1rem' }, // to counter the margin top on the p tag
      },
      MuiTable: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: '0px 0px',
        },
      },
      MuiTableRow: {
        root: {
          backgroundColor: dark ? '#13131A' : '#FFFFFF',
          borderRadius: 10,
          height: 68,
          // '&$hover:hover': { backgroundColor: '#FAFAFB', cursor: 'pointer' },
        },
      },
      MuiTableCell: {
        root: {
          padding: 0,
          paddingLeft: 15,
          borderBottom: '0px',
          marginBottom: 5,
        },
        head: {
          backgroundColor: dark ? '#000000!important' : '#F1F1F5!important',
          color: dark ? '#FAFAFB' : '#92929D',
          fontWeight: 900,
          padding: 0,
          paddingLeft: 15,
          borderBottom: '0px',
          marginBottom: 5,
        },
      },
      MuiListItem: {
        root: {
          padding: 0,
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: 'auto',
        },
      },
      MuiTabs: {
        flexContainer: {
          alignItems: 'flex-start',
          width: '100%',
        },
      },

      MuiMobileStepper: {
        progress: {
          width: '100%',
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: dark ? '#FAFAFB!important' : '#13131A!important',
          color: dark ? '#13131A!important' : '#FAFAFB!important',
        },
        arrow: {
          color: dark ? '#FAFAFB!important' : '#13131A!important',
        },
      },
      MuiSwitch: {
        track: {
          backgroundColor: dark ? '#FAFAFB!important' : '#13131A!important',
        },

        switchBase: {
          color: dark ? '#FFFFFF!important' : '#292932!important',
        },
      },
      MuiIcon: {
        fontSizeLarge: {
          fontSize: '2.19rem',
        },
      },
    },
    palette: {
      type: mode,
      text: {
        primary: dark ? '#FAFAFB' : '#13131A',
        secondary: dark ? '#92929D' : '#44444F',
      },
      neutral: {
        main: '#E5EFFF',
        light: '#166FFF',
        dark: dark ? '#92929D' : colors.grey[800],
      },
      common: {
        white: dark ? '#FAFAFB' : '#FFFFFF',
        black: dark ? '#292932' : '#0062FF',
      },
      primary: {
        main: dark ? '#1E75FF' : '#0062FF',
        light: '#50B5FF',
        dark: '#0039cb',
      },
      secondary: {
        main: dark ? '#fc5a5a' : '#c32130',
        light: '#ff8d87',
        dark: '#c32130',
      },
      success: {
        main: '#82C43C',
      },
      background: {
        default: dark ? '#13131A' : '#FAFAFB',
        paper: dark ? '#1C1C24' : '#FAFAFB',
        light: dark ? '#292932' : '#FFFFFF',
        actionContrast: dark ? '#92929D' : '#0062FF',
        hover: dark ? colors.grey.A700 : colors.grey['300'],
        active: dark ? colors.grey.A400 : colors.grey['200'],
      },
      action: {
        disabled: dark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.26)',
        disabledBackground: dark ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
      },
      colouredGrid: {
        main: dark ? '#13131A' : '#FAEFEF',
      },
    },
    typography: {
      h1: {
        fontFamily: ['inter semibold'].join(','),
        fontSize: '32px',
        color: dark ? '#FAFAFB' : colors.grey[900],
      },
      h2: {
        fontFamily: ['inter semibold'].join(','),
        fontSize: '17px',
        color: dark ? '#FAFAFB' : colors.grey[900],
        fontWeight: 600,
        lineHeight: '22px',
      },
      h3: {
        fontFamily: ['inter regular'].join(','),
        fontSize: '18px',
        lineHeight: '28px',
        color: dark ? '#B5B5BE' : colors.grey[800],
      },
      h4: {
        fontFamily: ['inter regular'].join(','),
      },
      h5: {
        fontFamily: ['inter regular'].join(','),
      },
      h6: {
        fontFamily: ['inter regular'].join(','),
      },
      body1: {
        fontFamily: ['inter regular'].join(','),
        fontSize: '15px',
        color: dark ? '#92929D' : colors.grey[900],
        '& p': {
          marginTop: '1rem',
        },
      },
      caption: {
        fontFamily: ['inter regular'].join(','),
        fontSize: '13px',
      },
      fontFamily: ['inter regular'].join(','),
    },
  });
};

export default theme;
