import { AdminCompany, AdminCompanies } from 'store/admin/types';
import { http } from '../index';

const SERVER_URL = process.env.REACT_APP_DOMAIN;

export const getAdminCompanies = async (
  registeredName = '',
  pagination: {
    limit?: number;
    offset?: number;
  },
): Promise<AdminCompanies> => {
  const { limit = 25, offset = 0 } = pagination;
  const { status, data } = await http.get(
    `${SERVER_URL}/company/all?registeredNameLike=${registeredName}&limit=${limit}&offset=${offset}`,
  );
  if (status === 200 && data) {
    return data;
  }
  throw new Error();
};

export const getAdminCompanyDetails = async (companyId = ''): Promise<AdminCompany> => {
  const { status, data } = await http.get(`${SERVER_URL}/company/${companyId}`);
  if (status === 200 && data) {
    return data;
  }
  throw new Error();
};

export const getAdminCompanyFinancialDetails = async (
  companyId = '',
  effectiveDate?: Date | number,
): Promise<AdminCompany> => {
  const { status, data } = await http.get(
    `${SERVER_URL}/company-financial-information/${companyId}?effectiveDate=${effectiveDate}`,
  );
  if (status === 200 && data) {
    return data;
  }
  throw new Error();
};

export const getAdminCompanyDocuments = async (companyId = ''): Promise<Document[]> => {
  const { status, data } = await http.get(`${SERVER_URL}/documents/${companyId}`);
  if (status === 200 && data) {
    return data;
  }
  throw new Error();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const downloadAdminCompanyDocument = async (companyId = '', filename: string): Promise<any> => {
  const res = await http.get(`${SERVER_URL}/documents/download/${companyId}/${filename}`, { responseType: 'blob' });
  if (res.status === 200 && res.data) {
    return res;
  }
  throw new Error();
};
