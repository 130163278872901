import { Avatar, Box, Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React from 'react';

export interface MediaCardProps {
  title: string;
  description: React.ReactNode;
  content?: React.ReactNode;
  media?: string;
  mediaClassName?: string;
  mediaContainerClassName?: string;
  mediaContainerStyle?: CSSProperties;
}

const MediaCard: React.SFC<MediaCardProps & React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const {
    title,
    description,
    // actions,
    media,
    content,
    className,
    mediaClassName,
    mediaContainerClassName,
    mediaContainerStyle,
  } = props;

  return (
    <Card style={{ height: '100%' }}>
      <CardContent style={{ height: '100%' }}>
        <div className={className}>
          <Box>
            {media && (
              <Avatar className={mediaContainerClassName} style={mediaContainerStyle}>
                <CardMedia component="img" alt={title} image={media} title={title} className={mediaClassName} />
              </Avatar>
            )}
            <Typography gutterBottom variant="h5" component="h2">
              <strong>{title}</strong>
            </Typography>
            <Typography variant="h6" component="p">
              {description}
            </Typography>
          </Box>
        </div>
        {content}
      </CardContent>
    </Card>
  );
};

export default MediaCard;
