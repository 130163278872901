import { Avatar, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { Close as DeleteFileIcon } from '@material-ui/icons';
import Modal from 'components/common/Modal';
import React from 'react';
import { Document } from 'store/documents/types';
import { useTranslation } from 'react-i18next';
import documentsListStyles from './DocumentsList.styles';

interface DocumentsListProps {
  documents: Document[];
  onDelete(doc: Document): void;
}

const DocumentsList: React.FC<DocumentsListProps> = ({ documents, onDelete }) => {
  const classes = documentsListStyles();
  const { t } = useTranslation();

  const [{ open, doc }, setOpenModal] = React.useState({
    open: false,
    doc: { id: '', name: '' },
  });

  const onDeleteConfirmation = (res: Document) => {
    setOpenModal({
      open: true,
      doc: res,
    });
  };

  const handleClose = React.useCallback((event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenModal({ open: false, doc: { id: '', name: '' } });
  }, []);

  return (
    <>
      <List>
        {documents.map((res: Document) => (
          <ListItem className={classes.demo} key={res.id}>
            <ListItemText primary={res.name} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => onDeleteConfirmation(res)}>
                <Avatar className={classes.deleteAvatar}>
                  <DeleteFileIcon className={classes.deleteIcon} />
                </Avatar>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <Modal
        open={open}
        handleClose={handleClose}
        title={t('pages.documents.validation.deleteWarningTitle', {
          name: doc.name,
        })}
        description={t('pages.documents.validation.deleteWarningDescription')}
        onClickPrimary={() => {
          onDelete(doc);
          handleClose();
        }}
      />
    </>
  );
};

export default React.memo(DocumentsList);
