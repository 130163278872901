import React, { FC, useState } from 'react';
import { Box, Grid, InputAdornment, TextField } from '@material-ui/core';
import { Form, Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import FormGroup from '@material-ui/core/FormGroup';
import UserAlert, { Status, UserAlertProps } from 'components/common/UserAlert';
import { Company } from 'store/dashboard/types';
import NumberFormatCustom from 'components/common/NumberFormatCustom';
import ToolTipTextField from 'components/common/ToolTipTextField';
import InputLabelComponent from 'components/common/ToolTipInputLabel';
import InputSlider from 'components/common/Slider';
import { useOnboardLoadingAndErrorState } from 'store/dashboard/selectors';
import FormBase from './FormBase';
import useOnboardingStyles from './form.styles';
import { noop } from 'utils';
import SubmitButtons from './SubmitButtons';

interface FormValues {
  debtBalance: number | null;
  inventoryBalance: number | null;
  receivableBalance: number | null;
  repeatCustomersRevenuePercentage: number | null;
  step?: number;
}

interface AdditionalDetailsFormProps {
  formValues: Partial<Company>;
  onClickPrimary?: () => void;
  primaryButtonTitle?: string;
}

const AdditionalDetailsForm: FC<AdditionalDetailsFormProps> = ({
  formValues,
  primaryButtonTitle,
  onClickPrimary = noop,
}) => {
  const [openAlert, setOpenAlert] = useState<UserAlertProps>({
    open: false,
    message: '',
    status: Status.ERROR,
  });
  const { t } = useTranslation();
  const classes = useOnboardingStyles();
  const { loading, patchCompany } = useOnboardLoadingAndErrorState(setOpenAlert, onClickPrimary);

  const additionalDetailsSchema = yup.object({
    debtBalance: yup
      .number()
      .min(0, t('pages.onBoarding.debitBalanceValidate'))
      .typeError(t('pages.onBoarding.debitBalanceValidate'))
      .nullable(),
    inventoryBalance: yup
      .number()
      .min(0, t('pages.onBoarding.inventoryBalanceValidate'))
      .typeError(t('pages.onBoarding.inventoryBalanceValidate'))
      .nullable(),
    receivableBalance: yup
      .number()
      .min(0, t('pages.onBoarding.receivableBalanceValidate'))
      .typeError(t('pages.onBoarding.receivableBalanceValidate'))
      .nullable(),
    repeatCustomersRevenuePercentage: yup
      .number()
      .min(0, t('pages.onBoarding.receivableBalanceValidate'))
      .typeError(t('pages.onBoarding.receivableBalanceValidate'))
      .nullable(),
  });

  const { open, message, status } = openAlert;

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert({ open: false });
  };

  const initialValues: FormValues = {
    debtBalance: formValues.debtBalance || null,
    inventoryBalance: formValues.inventoryBalance || null,
    receivableBalance: formValues.receivableBalance || null,
    repeatCustomersRevenuePercentage: Math.round((formValues.repeatCustomersRevenuePercentage || 0) * 100),
  };

  const onSubmit = (values: FormValues) => {
    const payload: Partial<FormValues> = { step: 5 };
    if (values.debtBalance) payload.debtBalance = Number(values.debtBalance);
    if (values.inventoryBalance) payload.inventoryBalance = Number(values.inventoryBalance);
    if (values.receivableBalance) payload.receivableBalance = Number(values.receivableBalance);
    if (values.repeatCustomersRevenuePercentage) {
      payload.repeatCustomersRevenuePercentage = Number(values.repeatCustomersRevenuePercentage) / 100;
    }

    patchCompany(payload);
  };

  return (
    <FormBase>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={additionalDetailsSchema}>
        {({ handleChange, values, errors, touched, setFieldValue }) => (
          <Form noValidate>
            <Grid container item direction="column" spacing={4}>
              <Grid item>
                <Field
                  fullWidth
                  name="debtBalance"
                  component={TextField}
                  value={values.debtBalance}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('debtBalance', e.target.value)}
                  label={t('pages.onBoarding.debtBalanceLabel')}
                  error={touched.debtBalance && !!errors.debtBalance}
                  helperText={touched.debtBalance && errors.debtBalance}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    inputComponent: NumberFormatCustom as any,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="4000"
                  className={classes.textInput}
                  margin="normal"
                />
              </Grid>

              <Grid item>
                <Field
                  fullWidth
                  name="inventoryBalance"
                  component={ToolTipTextField}
                  value={values.inventoryBalance}
                  onChange={handleChange}
                  title={t('pages.onBoarding.inventoryBalance')}
                  toolTipMessage={t('pages.onBoarding.inventoryBalanceToolTip')}
                  error={touched.inventoryBalance && !!errors.inventoryBalance}
                  helperText={touched.inventoryBalance && errors.inventoryBalance}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    inputComponent: NumberFormatCustom as any,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="4000"
                  className={classes.textInput}
                  margin="normal"
                />
              </Grid>

              <Grid item>
                <Field
                  fullWidth
                  name="receivableBalance"
                  component={ToolTipTextField}
                  value={values.receivableBalance}
                  onChange={handleChange}
                  title={t('pages.onBoarding.receivableBalance')}
                  toolTipMessage={t('pages.onBoarding.receivableBalanceToolTip')}
                  error={touched.receivableBalance && !!errors.receivableBalance}
                  helperText={touched.receivableBalance && errors.receivableBalance}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    inputComponent: NumberFormatCustom as any,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="4000"
                  className={classes.textInput}
                  margin="normal"
                />
              </Grid>

              <Grid item>
                <InputLabelComponent
                  htmlFor="repeatCustomersRevenuePercentage"
                  label={t('pages.onBoarding.repeatCustomersRevenuePercentageLabel')}
                  id="salesPlatformLabel"
                />

                <Box component="fieldset" className={classes.formControl} style={{ height: '100%' }}>
                  <FormGroup style={{ height: '100%' }}>
                    <InputSlider
                      max={100}
                      res={0}
                      value={{ repeatCustomersRevenuePercentage: values.repeatCustomersRevenuePercentage }}
                      inputChange={(e) => setFieldValue('repeatCustomersRevenuePercentage', Number(e.target.value))}
                      slideChange={(v) => setFieldValue('repeatCustomersRevenuePercentage', v)}
                      name="repeatCustomersRevenuePercentage"
                      state={{ repeatCustomersRevenuePercentage: true }}
                      step={1}
                    />
                  </FormGroup>
                </Box>

                {/* {touched.repeatCustomersRevenuePercentage && !!errors.repeatCustomersRevenuePercentage} */}
              </Grid>

              <SubmitButtons loading={loading} primaryButtonTitle={primaryButtonTitle} />
            </Grid>
          </Form>
        )}
      </Formik>

      <UserAlert open={open} status={status} message={message} handleClose={handleClose} />
    </FormBase>
  );
};

export default AdditionalDetailsForm;
