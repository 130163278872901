import {
  getAdminCompanies as getCompaniesApi,
  getAdminCompanyDetails as getAdminCompanyDetailsApi,
  getAdminCompanyDocuments as getAdminCompanyDocumentsApi,
  getAdminCompanyFinancialDetails as getAdminCompanyFinancialDetailsApi,
} from 'http/admin';
import { AppThunk } from 'store/types';
import {
  AdminCompanies,
  AdminCompany,
  GET_ADMIN_COMPANIES_ERROR,
  GET_ADMIN_COMPANIES_START,
  GET_ADMIN_COMPANIES_SUCCESS,
  GET_ADMIN_COMPANY_ACTION,
  GET_ADMIN_COMPANY_DOCUMENTS_SUCCESS,
  GET_ADMIN_COMPANY_ERROR,
  GET_ADMIN_COMPANY_FINANCIAL_SUCCESS,
  GET_ADMIN_COMPANY_SUCCESS,
  AdminActionTypes,
} from './types';

export * from './types';

export const getAdminCompaniesStart = (): AdminActionTypes => ({
  type: GET_ADMIN_COMPANIES_START,
});

export const getAdminCompaniesSuccess = (companies: AdminCompanies): AdminActionTypes => ({
  type: GET_ADMIN_COMPANIES_SUCCESS,
  payload: companies,
});

export const getAdminCompaniesError = (): AdminActionTypes => ({
  type: GET_ADMIN_COMPANIES_ERROR,
});

export const getAdminCompanies = (
  registeredName: string,
  pagination: {
    limit?: number;
    offset?: number;
  },
): AppThunk => async (dispatch) => {
  const { limit, offset } = pagination;
  dispatch(getAdminCompaniesStart());
  try {
    const companies = await getCompaniesApi(registeredName, {
      limit,
      offset,
    });
    dispatch(getAdminCompaniesSuccess(companies));
  } catch (error) {
    dispatch(getAdminCompaniesError());
    throw error;
  }
};

const getAdminCompanyStart = () => ({
  type: GET_ADMIN_COMPANY_ACTION,
});

const getAdminCompanySuccess = (company: AdminCompany) => ({
  type: GET_ADMIN_COMPANY_SUCCESS,
  payload: company,
});

const getAdminCompanyError = () => ({
  type: GET_ADMIN_COMPANY_ERROR,
});

export const getAdminCompany = (companyId: string): AppThunk => async (dispatch) => {
  dispatch(getAdminCompanyStart());
  try {
    const company = await getAdminCompanyDetailsApi(companyId);
    dispatch(getAdminCompanySuccess(company));
  } catch (error) {
    dispatch(getAdminCompanyError());
    throw error;
  }
};

const getAdminCompanyFinancialSuccess = (company: AdminCompany) => ({
  type: GET_ADMIN_COMPANY_FINANCIAL_SUCCESS,
  payload: company,
});

export const getAdminCompanyFinancial = (companyId: string, effectiveDate?: Date | number): AppThunk => async (
  dispatch,
) => {
  dispatch(getAdminCompanyStart());
  try {
    const companyFinancials = await getAdminCompanyFinancialDetailsApi(companyId, effectiveDate);
    dispatch(getAdminCompanyFinancialSuccess(companyFinancials));
  } catch (error) {
    dispatch(getAdminCompanyError());
    throw error;
  }
};

export const getAdminCompanyDocuments = (companyId: string): AppThunk => async (dispatch) => {
  dispatch(getAdminCompanyStart());

  try {
    const companyDocuments = await getAdminCompanyDocumentsApi(companyId);
    dispatch({
      type: GET_ADMIN_COMPANY_DOCUMENTS_SUCCESS,
      payload: companyDocuments,
    });
  } catch (error) {
    dispatch(getAdminCompanyError());

    throw error;
  }
};
