import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React, { useMemo } from 'react';

import theme from 'constants/theme';
import AppRouter from 'router';
import { useThemeMode } from 'store/user/selectors';

export default function App(): JSX.Element {
  const themeMode = useThemeMode();
  const appTheme = useMemo(() => theme(themeMode), [themeMode]);
  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <AppRouter />
    </ThemeProvider>
  );
}
