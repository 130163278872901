import { Button, Typography, Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirebase } from 'react-redux-firebase';
import UserAlert, { Status, UserAlertProps } from 'components/common/UserAlert';
import useStyles from './UserProfile.styles';

const SendEmailVerification: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const firebase = useFirebase();
  const [alertState, setAlertState] = React.useState<UserAlertProps>({
    open: false,
    message: '',
    status: Status.ERROR,
  });
  const { open, message, status } = alertState;

  const sendEmailVerification = useCallback(async () => {
    try {
      await firebase.auth().currentUser?.sendEmailVerification();
      setAlertState({
        open: true,
        message: `${t('pages.profile.verify.sendEmailSuccess')}`,
        status: Status.SUCCESS,
      });
    } catch (error) {
      setAlertState({
        open: true,
        message: `${t('pages.profile.verify.sendEmailFailure')}`,
        status: Status.ERROR,
      });
    }
  }, [firebase, t]);

  return (
    <>
      <Alert severity="warning" variant="outlined">
        <AlertTitle>{t('pages.profile.verify.title')}</AlertTitle>
        <Typography variant="caption" gutterBottom color="textPrimary">
          {t('pages.profile.verify.description')}
        </Typography>
        <Box style={{ marginTop: 10 }} />
        <Button
          variant="contained"
          color="primary"
          className={classes.accountListButton}
          onClick={() => sendEmailVerification()}
        >
          {t('pages.profile.verify.button')}
        </Button>
      </Alert>
      <UserAlert open={open} status={status} message={message} handleClose={() => setAlertState({ open: false })} />
    </>
  );
};

export default SendEmailVerification;
