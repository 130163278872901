import { LenderCategory, LenderCategoryApplication, LenderCategoryId } from 'entities/lender';
import { Provider } from 'entities/provider';
import { HttpError } from 'http/error';

export const FETCH_LENDERS_START = 'FETCH_LENDERS_START';
export const FETCH_LENDERS_SUCCESS = 'FETCH_LENDERS_SUCCESS';
export const FETCH_LENDERS_ERROR = 'FETCH_LENDERS_ERROR';

export const FETCH_PROVIDERS_START = 'FETCH_PROVIDERS_START';
export const FETCH_PROVIDERS_SUCCESS = 'FETCH_PROVIDERS_SUCCESS';
export const FETCH_PROVIDERS_ERROR = 'FETCH_PROVIDERS_ERROR';

export const SELECT_LENDER_POPUP = 'SELECT_LENDER_POPUP';
export const CLOSE_LENDER_POPUP = 'CLOSE_LENDER_POPUP';

export const LENDER_CATEGORY_APPLY_START = 'LENDER_CATEGORY_APPLY_START';
export const LENDER_CATEGORY_APPLY_SUCCESS = 'LENDER_CATEGORY_APPLY_SUCCESS';
export const LENDER_CATEGORY_APPLY_ERROR = 'LENDER_CATEGORY_APPLY_ERROR';

export const FETCH_LENDER_CATEGORY_APPLICATIONS_START = 'FETCH_LENDER_CATEGORY_APPLICATIONS_START';
export const FETCH_LENDER_CATEGORY_APPLICATIONS_SUCCESS = 'FETCH_LENDER_CATEGORY_APPLICATIONS_SUCCESS';
export const FETCH_LENDER_CATEGORY_APPLICATIONS_ERROR = 'FETCH_LENDER_CATEGORY_APPLICATIONS_ERROR';

export interface FetchLendersStart {
  type: typeof FETCH_LENDERS_START;
}

export interface FetchLendersSuccess {
  type: typeof FETCH_LENDERS_SUCCESS;
  payload: LenderCategory[];
}

export interface FetchLendersError {
  type: typeof FETCH_LENDERS_ERROR;
  payload: HttpError;
}

export interface FetchProvidersStart {
  type: typeof FETCH_PROVIDERS_START;
}

export interface FetchProvidersSuccess {
  type: typeof FETCH_PROVIDERS_SUCCESS;
  payload: Provider[];
}

export interface FetchProvidersError {
  type: typeof FETCH_PROVIDERS_ERROR;
  payload: HttpError;
}

export interface SelectLenderPopup {
  type: typeof SELECT_LENDER_POPUP;
  payload: LenderCategory;
}

export interface CloseLenderPopup {
  type: typeof CLOSE_LENDER_POPUP;
}

export interface LenderCategoryApplyStart {
  type: typeof LENDER_CATEGORY_APPLY_START;
}

export interface LenderCategoryApplySuccess {
  type: typeof LENDER_CATEGORY_APPLY_SUCCESS;
  payload: LenderCategoryId;
}

export interface LenderCategoryApplyError {
  type: typeof LENDER_CATEGORY_APPLY_ERROR;
  payload: HttpError;
}

export interface FetchLenderCategoryApplicationsStart {
  type: typeof FETCH_LENDER_CATEGORY_APPLICATIONS_START;
}

export interface FetchLenderCategoryApplicationsSuccess {
  type: typeof FETCH_LENDER_CATEGORY_APPLICATIONS_SUCCESS;
  payload: LenderCategoryApplication[];
}

export interface FetchLenderCategoryApplicationsError {
  type: typeof FETCH_LENDER_CATEGORY_APPLICATIONS_ERROR;
  payload: HttpError;
}

export type LendersActionTypes =
  | FetchLendersStart
  | FetchLendersSuccess
  | FetchLendersError
  | FetchProvidersStart
  | FetchProvidersSuccess
  | FetchProvidersError
  | SelectLenderPopup
  | CloseLenderPopup
  | LenderCategoryApplyStart
  | LenderCategoryApplySuccess
  | LenderCategoryApplyError
  | FetchLenderCategoryApplicationsStart
  | FetchLenderCategoryApplicationsSuccess
  | FetchLenderCategoryApplicationsError;

export interface LendersState {
  busy: boolean;
  lenderCategories: LenderCategory[];
  providers: Provider[];
  error?: HttpError;
  selectedLenderCategory?: LenderCategory;
  applying: boolean;
}
