import axios from 'axios';
import { Pagination } from 'entities/common';
import { LenderCategory, LenderCategoryId, LenderCategoryApplication, LenderCategoryIdMap } from 'entities/lender';
import { http } from '../index';

const SERVER_URL = process.env.REACT_APP_DOMAIN;

export const getLenderCategories = async (): Promise<LenderCategory[]> => {
  const res = await axios.get<LenderCategory[]>('/lenders/lenderCategories.json');
  return res.data;
};

export const applyToLenderCategory = async (categoryId: LenderCategoryId): Promise<unknown> => {
  const { data } = await http.post(`${SERVER_URL}/lender-application/${categoryId}`);
  return data;
};

export const getLenderCategoryApplications = async (active = true): Promise<Pagination<LenderCategoryApplication>> => {
  const limit = active ? Object.keys(LenderCategoryIdMap).length : 25; // fetch only what is required
  const { data } = await http.get(`${SERVER_URL}/lender-application?limit=${limit}`, {
    params: {
      active,
    },
  });
  return data;
};
