import React, { FC } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import { Snackbar } from '@material-ui/core';

export enum GlobalStatus {
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface GlobalAlertProps {
  status: GlobalStatus;
  title?: string;
  message: string;
  actionText?: string;
  onAction?: () => void;
}

const GlobalAlert: FC<GlobalAlertProps> = ({ status, title, message, actionText, onAction }) => {
  return (
    <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert
        severity={status}
        action={actionText && onAction ? <Button onClick={onAction}>{actionText}</Button> : null}
        variant="filled"
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    </Snackbar>
  );
};

export default GlobalAlert;
