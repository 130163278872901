import {
  Button,
  Card,
  CardContent,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBack, CloudDownload as DownloadIcon } from '@material-ui/icons';
import { TabPanels } from 'components/dashboard/CompanyProfile';
import CompanyCard from 'components/dashboard/CompanyProfile/CompanyCard';
// import StyledTabs from 'components/dashboard/CompanyProfile/StyledTab';
import { timeouts } from 'constants/theme';
import { downloadAdminCompanyDocument } from 'http/admin';
import fileDownload from 'js-file-download';
import React, { useEffect /* , useState */ } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { calculateBusinessMetrics } from 'services/businessMetrics';
import { GBPFormatter } from 'services/currencyFormatter';
import { getAdminCompany, getAdminCompanyDocuments, getAdminCompanyFinancial } from 'store/admin/actions';
// eslint-disable-next-line import/no-named-as-default
import useAdminState from 'store/admin/selectors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  documentsContainer: {
    marginTop: 30,
    background: theme.palette.background.light,
  },
}));

interface LocationState {
  companyId: string;
  effectiveDate: string;
}

// eslint-disable-next-line
const AdminCompanyProfile: React.FC<RouteComponentProps<{}, any, LocationState | any>> = ({
  location: {
    state: { companyId, effectiveDate },
  },
}) => {
  const history = useHistory();
  const {
    company: { details: companyDetails, documents },
  } = useAdminState();

  const { cashBalance, startYear, yearToDateRevenue, monthlyRevenue, website, registeredName } = companyDetails;

  const { businessValuation, businessCreditLimit, businessCreditScore } = calculateBusinessMetrics(
    startYear || 0,
    cashBalance || 0,
    yearToDateRevenue || 0,
    monthlyRevenue || 0,
  );

  const classes = useStyles();
  // const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();

  // const handleChange = (_: React.SyntheticEvent, newTab: number) => {
  //   setSelectedTab(newTab);
  // };

  const handleDocumentDownload = async (companyId: string, filename: string) => {
    try {
      const res = await downloadAdminCompanyDocument(companyId, filename);

      if (res) {
        fileDownload(res.data, filename);
      }
    } catch (e) {
      // console.log('download failed', e);
    }
  };

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      dispatch(getAdminCompany(companyId));
      dispatch(getAdminCompanyFinancial(companyId, effectiveDate));
      dispatch(getAdminCompanyDocuments(companyId));
    })();
  }, [dispatch, companyId, effectiveDate]);

  return (
    <Fade in timeout={timeouts.fade}>
      <div>
        <Grid container spacing={3}>
          <Grid item sm={12} md={8}>
            <Grid container spacing={3}>
              <Grid item sm={12} md={12}>
                <Button variant="outlined" onClick={() => history.goBack()}>
                  <ArrowBack /> Back
                </Button>
                <Typography variant="h6">Admin view for {registeredName}</Typography>
              </Grid>
              {/* <Grid item sm={12} md={4}>
                <StyledTabs
                  value={selectedTab}
                  handleChange={handleChange}
                  tabText={[
                    {
                      title: t('pages.companyDetails.tabs.title1'),
                      subtitle: t('pages.companyDetails.tabs.subtitle1'),
                    },
                    {
                      title: t('pages.companyDetails.tabs.title2'),
                      subtitle: t('pages.companyDetails.tabs.subtitle2'),
                    },
                    {
                      title: t('pages.companyDetails.tabs.title3'),
                      subtitle: t('pages.companyDetails.tabs.subtitle3'),
                    },
                  ]}
                />
              </Grid> */}
              <Grid item sm={12} md={12}>
                <TabPanels company={companyDetails} /* value={selectedTab} */ />
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12} md={4}>
            <CompanyCard
              name={registeredName || ''}
              img={`//logo.clearbit.com/${website}?size=80`}
              valuation={GBPFormatter.format(businessValuation)}
              creditLimit={GBPFormatter.format(businessCreditLimit)}
              businessScore={businessCreditScore.toString()}
            />
            <Card className={classes.documentsContainer}>
              <CardContent>
                <Typography variant="h6">{t('pages.documents.title')}</Typography>
                <List>
                  {documents && documents?.length > 0 ? (
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    documents.map((res: any) => {
                      const friendlyName = res?.name?.replace(res.prefix, '');
                      return (
                        <Grid item sm={12} md={12} key={res.id}>
                          <ListItem>
                            <ListItemAvatar>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleDocumentDownload(companyId, friendlyName)}
                              >
                                <DownloadIcon color="primary" />
                              </IconButton>
                            </ListItemAvatar>
                            <ListItemText primary={friendlyName} secondary={res.contentType} />
                          </ListItem>
                        </Grid>
                      );
                    })
                  ) : (
                    <Typography>No documents uploaded</Typography>
                  )}
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default AdminCompanyProfile;
