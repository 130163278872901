import React from 'react';
import { InputLabel, Box } from '@material-ui/core';
import { InfoOutlined as TooltipIcon } from '@material-ui/icons';
import Tooltip from 'components/common/Tooltip';

const InputLabelComponent: React.FC<{
  message?: string;
  label: string;
  htmlFor: string;
  id: string;
}> = ({ message, label, htmlFor, id }) => (
  <InputLabel htmlFor={htmlFor} shrink style={{ textAlign: 'left', height: '25px' }} id={id}>
    <Box display="flex">
      {label}
      {message && (
        <Box top="-1rem" position="relative">
          <Tooltip title={message} Icon={<TooltipIcon />}>
            <div />
          </Tooltip>
        </Box>
      )}
    </Box>
  </InputLabel>
);

export default InputLabelComponent;
