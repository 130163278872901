import { Box, Button, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { useAdmin } from 'store/firebase/selectors';
import AdminCompanyList from './admin-companies-list';
import AdminCompanyDetail from './admin-company-detail';

const Admin: FC = () => {
  const { path } = useRouteMatch();

  const history = useHistory();

  const isAdmin = useAdmin();

  return isAdmin ? (
    <Switch>
      <Route exact path={path} component={AdminCompanyList} />
      <Route path={`${path}/:id`} component={AdminCompanyDetail} />
      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  ) : (
    <Box>
      <Typography variant="h6">Page not found</Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          history.push({
            pathname: `/`,
          })
        }
      >
        Go home
      </Button>
    </Box>
  );
};

export default Admin;
