import { UploadDocumentResponse, DeleteDocumentResponse } from 'store/admin/types';
import { http } from '../index';

const SERVER_URL = process.env.REACT_APP_DOMAIN;

// eslint-disable-next-line
export const fetchDocuments = async () => {
  const { status, data } = await http.get(`${SERVER_URL}/documents`);
  if (status === 200 && data) {
    return data;
  }
  return [];
};

export const uploadDocument = async (formData: FormData): Promise<UploadDocumentResponse> => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const { status, data } = await http.post(`${SERVER_URL}/documents`, formData, config);

  if (status !== 200 && status !== 201) {
    throw new Error(data?.status || 'Something unexpected happened');
  }

  return data;
};

export const deleteDocument = async (name: string): Promise<DeleteDocumentResponse | null> => {
  const { status, data } = await http.delete(`${SERVER_URL}/documents/${name}`);
  if (status === 200 && data) {
    return data;
  }
  return null;
};
