import { Hidden, Paper } from '@material-ui/core';
import MobileStepper from '@material-ui/core/MobileStepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import ColorlibConnector from './ColorlibConnector';
import ColorlibStepIcon from './ColorlibStepIcon';

interface HorizontalStepperProps {
  steps: Array<string>;
  getStepContent: (stepIndex: number) => React.ReactNode;
  length: number;
  activeStep: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '80%',
      background: theme.palette.background.paper,
      justifyContent: 'center',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    mobile: {
      flexGrow: 1,
      marginTop: 'auto',
    },
    mobileTitle: {
      textAlign: 'center',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

const HorizontalStepper: React.FC<HorizontalStepperProps> = ({ steps, getStepContent, length, activeStep }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Hidden smDown>
        <>
          <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {getStepContent(activeStep)}
        </>
      </Hidden>

      <Hidden mdUp>
        <>
          <MobileStepper
            variant="progress"
            steps={length}
            position="static"
            activeStep={activeStep}
            className={classes.root}
            nextButton={<></>}
            backButton={<></>}
          />
          {activeStep === length ? (
            <div>
              <Typography className={classes.instructions}>All steps completed</Typography>
            </div>
          ) : (
            <>
              <Paper>
                <Typography variant="h4" className={classes.mobileTitle}>
                  {steps[activeStep]}
                </Typography>
                <div>{getStepContent(activeStep)}</div>
              </Paper>
            </>
          )}
        </>
      </Hidden>
    </div>
  );
};

export default HorizontalStepper;
