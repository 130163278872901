import { Fade, Grid, Typography } from '@material-ui/core';
import DocumentsList from 'components/common/DocumentsList';
import RejectedList from 'components/common/DocumentsList/RejectedList';
import Emoji from 'components/common/Emoji';
import UserAlert, { Status, UserAlertProps } from 'components/common/UserAlert';
import FileDrop from 'components/FileDrop';
import { timeouts } from 'constants/theme';
import { deleteDocument, fetchDocuments } from 'http/documents';
import React, { useCallback, useEffect, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addDocuments, deleteDocument as deleteDocumentAction, getDocuments } from 'store/documents/actions';
import { Document } from 'store/documents/types';
import { RootState } from 'store/types';
import allowedFileTypes from 'constants/data/allowedFileTypes';

const Documents: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const documents = useSelector((state: RootState) => state.documents.items);
  const [rejectedFiles, setRejections] = useState<FileRejection[]>([]);

  useEffect(() => {
    (async () => {
      const uploadedDocuments = await fetchDocuments();
      dispatch(getDocuments(uploadedDocuments));
    })();
  }, [dispatch]);

  const [{ open, message, status }, setOpenAlert] = React.useState<UserAlertProps>({
    open: false,
    message: '',
    status: Status.ERROR,
  });

  const handleDelete = useCallback(
    async (doc: Document) => {
      await deleteDocument(doc.name);
      dispatch(deleteDocumentAction(doc.id));
      setOpenAlert({
        open: true,
        message: t('pages.documents.validation.successDelete'),
        status: Status.SUCCESS,
      });
    },
    [dispatch, t],
  );

  const handleClose = useCallback((event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert({ open: false });
  }, []);

  const handleUpload = useCallback(
    (files: File[]) => {
      setOpenAlert({
        open: true,
        message: t('pages.documents.validation.success'),
        status: Status.SUCCESS,
      });
      dispatch(
        addDocuments(
          files.map((file) => ({
            id: file.name,
            name: file.name,
          })),
        ),
      );
    },
    [t, dispatch],
  );

  const handleRejection = useCallback((files: FileRejection[]) => {
    setRejections(files);
  }, []);

  return (
    <>
      <Fade in timeout={timeouts.fade}>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Grid container spacing={3}>
              <Grid item sm={12} md={8} lg={6}>
                <Typography gutterBottom component="div">
                  <Typography variant="h1" display="inline">
                    <Emoji variant="documents" />{' '}
                  </Typography>
                  <Typography variant="h1" display="inline">
                    {t('pages.documents.title')}
                  </Typography>
                </Typography>
                <Typography variant="h3" gutterBottom>
                  <Trans i18nKey="pages.documents.subtitle" />
                </Typography>
              </Grid>
              <Grid item xs={11} lg={8}>
                <FileDrop
                  allowedFileTypes={allowedFileTypes}
                  onUpload={handleUpload}
                  onReject={handleRejection}
                  uploadMessage={`${t('pages.documents.uploadMessage1')} ${t('pages.documents.uploadMessage2')}`}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Typography variant="h3" gutterBottom>
                  <Trans i18nKey="components.documentsList.uploadHistory" />
                </Typography>
                {documents?.length === 0 ? (
                  <Typography variant="body1">
                    <Trans i18nKey="components.documentsList.noDocuments" />
                  </Typography>
                ) : (
                  <DocumentsList documents={documents} onDelete={handleDelete} />
                )}
              </Grid>
              {rejectedFiles.length > 0 && (
                <Grid item xs={12} lg={6}>
                  <Typography variant="h3" gutterBottom>
                    <Trans i18nKey="components.documentsList.rejectedFiles" />
                  </Typography>
                  <RejectedList rejectedFiles={rejectedFiles} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Fade>
      <UserAlert open={open} status={status} message={message} handleClose={handleClose} />
    </>
  );
});

export default Documents;
