import { Grid, Typography, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { uploadDocument } from 'http/documents';
import React, { useCallback, useState } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import useFileDropStyles from './FileDrop.styles';

// 20MB -> in bytes
const maxFileSize = 20000000;

interface AllowedFiletype {
  name: string;
  mimeType: string;
  extension: string;
}

interface Props {
  allowedFileTypes: Array<AllowedFiletype>;
  onUpload(files: File[]): void;
  onReject(rejections: FileRejection[]): void;
  uploadMessage: string;
}

const FileDrop: React.FC<Props> = ({ allowedFileTypes, onUpload, onReject, uploadMessage }) => {
  const [loading, setLoading] = useState(false);
  const classes = useFileDropStyles();

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const Data = new FormData();
      const rejections = [...fileRejections];

      acceptedFiles.forEach((file) => {
        Data.append('file', file);
      });

      if (acceptedFiles.length > 0) {
        setLoading(true);
        try {
          await uploadDocument(Data);
        } catch (err) {
          acceptedFiles.forEach((file) => {
            rejections.push({
              file,
              errors: [
                {
                  code: 'too-many-files',
                  message: err.message,
                },
              ],
            });
          });
        }
        setLoading(false);
      }

      // If user chooses to upload multiple files, and one of them is rejected
      // we still need to display the ones that were accepted
      if (acceptedFiles.length > 0) {
        onUpload(acceptedFiles);
      }
      // Don't check if array has any element, we want to reset rejections on every upload op
      onReject(rejections);
    },
    [onReject, onUpload],
  );

  return (
    <Dropzone
      onDrop={(acceptedFiles, fileRejections) => onDrop(acceptedFiles, fileRejections)}
      maxSize={maxFileSize}
      accept={allowedFileTypes.map((fileType) => fileType.mimeType)}
      disabled={loading}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <Grid container spacing={3}>
            <Grid item sm={12} md={12}>
              <div {...getRootProps()} className={classes.outer}>
                <div className={loading ? clsx([classes.inner, classes.disabled]) : classes.inner}>
                  <input {...getInputProps()} />

                  <div className={classes.flex}>
                    <Typography gutterBottom component="div">
                      <Typography variant="h3" display="inline">
                        {uploadMessage}
                      </Typography>
                    </Typography>
                  </div>
                </div>

                {loading && <CircularProgress />}
              </div>
            </Grid>
          </Grid>
        </section>
      )}
    </Dropzone>
  );
};

export default FileDrop;
