import { Box, Typography } from '@material-ui/core';
import { AccountBalanceWalletTwoTone as AvatarIcon } from '@material-ui/icons';
import MetricCardBase from 'components/dashboard/MetricCardBase';
import { timeouts } from 'constants/theme';
import React from 'react';
import CountTo from 'react-count-to';
import { useTranslation } from 'react-i18next';
import { GBPFormatter } from 'services/currencyFormatter';

interface BusinessValuationProps {
  businessValuation: number;
  onBoardingComplete: boolean;
  small?: boolean;
}
const BusinessValuation: React.FC<BusinessValuationProps> = (props) => {
  const { businessValuation, onBoardingComplete, small } = props;
  const { t } = useTranslation();

  // const valuation = React.useMemo(
  //   () => GBPFormatter.format(businessValuation),
  //   [businessValuation],
  // );

  const valuation = (value: number) => <span>{GBPFormatter.format(value)}</span>;

  return (
    <MetricCardBase
      title={t('general.titles.valuation')}
      buttonText="Learn More"
      small={small}
      // tooltipTitle={t('general.titles.valuation')}
      tooltipMessage={t('pages.dashboard.valuation.caption2')}
      avatarIcon={<AvatarIcon />}
      onBoardingComplete={onBoardingComplete}
      append={
        <div>
          {onBoardingComplete ? (
            <>
              <Box height="50px" display="flex" alignItems="center">
                <Typography variant="h1">
                  <CountTo to={businessValuation} speed={timeouts.countTo}>
                    {valuation}
                  </CountTo>
                  {/* <Direction direction="up" percentage="2.5" /> */}
                </Typography>
              </Box>

              {/* <Typography variant="caption">
                {t('pages.dashboard.valuation.caption1')}
              </Typography> */}
            </>
          ) : (
            <Typography variant="caption">
              {t('pages.dashboard.valuation.caption2')} <br />
            </Typography>
          )}
        </div>
      }
    >
      <>{!onBoardingComplete && <></>}</>
    </MetricCardBase>
  );
};

export default BusinessValuation;
