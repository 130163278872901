import MuiDialogContent from '@material-ui/core/DialogContent';
import { Theme, withStyles } from '@material-ui/core/styles';

const ModalContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default ModalContent;
