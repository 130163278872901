/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fade, Grid, Link, Typography, Hidden } from '@material-ui/core';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { timeouts } from 'constants/theme';
import { useLenderCategories, useLendersBusy } from 'store/lenders/selectors';

import { openCalendly } from 'components/common/CalendlyPopup';
import Emoji from 'components/common/Emoji';
import Loader from 'components/common/Loader';
import { businessScore } from 'constants/businessScore';
import { LenderCategory } from 'entities/lender';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAuth } from 'store/firebase/selectors';
import { selectLenderPopup } from 'store/lenders/actions';
import { ReactComponent as LendingImage } from 'assets/icon/equity.svg';
import LenderCard from '../lender-card';

const LenderList: FC = () => {
  const categories = useLenderCategories();
  const busy = useLendersBusy();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const auth = useAuth();

  const onClickApply = (lender: LenderCategory) => dispatch(selectLenderPopup(lender));

  const bookCalendly = () =>
    openCalendly({
      url: businessScore.calendly.followUp,
      prefill: {
        name: auth.displayName || '',
        email: auth.email || '',
      },
    });

  return busy ? (
    <Loader />
  ) : (
    <Fade in timeout={timeouts.fade}>
      <Grid container spacing={3}>
        <Grid xl={9} lg={9} md={12} sm={12} xs={12}>
          <Typography variant="h1" gutterBottom>
            <Emoji variant="lenders" /> {t('pages.lendersList.header')}
          </Typography>{' '}
          <Typography variant="h3" gutterBottom>
            <Trans>pages.lendersList.details</Trans>
          </Typography>
          <Typography variant="h3" gutterBottom>
            <Trans>general.titles.undecided</Trans> <Trans>general.titles.getInTouch</Trans>
          </Typography>
          <Typography variant="h3" gutterBottom>
            <Emoji variant="call" /> Call us on <Link href="tel:+44 20 331 812 63">{businessScore.phoneNumber}</Link>
          </Typography>
          <Typography variant="h3" gutterBottom>
            <Emoji variant="calendar" />{' '}
            <Link href="#" onClick={() => bookCalendly()}>
              <Trans>general.titles.bookTime</Trans>
            </Link>{' '}
            <Trans>pages.lendersList.bookTimeCTA</Trans>
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xl={3} lg={3} style={{ textAlign: 'right' }}>
            <LendingImage height={250} />
          </Grid>
        </Hidden>

        <Grid item container spacing={3}>
          {categories.map((category) => (
            <Grid xl={3} lg={4} md={6} sm={6} xs={12} item key={category.id}>
              <LenderCard
                category={category}
                apply={() => {
                  onClickApply(category);
                }}
                select={() => push(`${url}/${category.id}`)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Fade>
  );
};

export default LenderList;
