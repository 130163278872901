export const LenderCategoryIdMap = {
  revenueBasedFiance: 'revenue-based-finance',
  businessLoan: 'business-loan',
  creditLine: 'line-of-credit',
  invoiceFinance: 'invoice-finance',
  stockFinance: 'stock-finance',
  creditCard: 'business-credit-card',
} as const;

type LenderCategoryIdMapType = typeof LenderCategoryIdMap;

export type LenderCategoryId = LenderCategoryIdMapType[keyof LenderCategoryIdMapType];

export interface LenderEligibility {
  eligible: boolean;
  missingCriterion: string[];
}

export interface LenderCategory {
  id: LenderCategoryId;
  name: string;
  alias: string;
  logo: string;
  logoColor: string;
  header: string;
  caseHeader: string;
  description: string;
  longDescription: string;
  guide: string;
  case: string;
  providers: string[];
  providersDescription: string;
  criteria: string[];
  /**
   * If there is a possibility to apply to this
   * category through the app
   */
  appliable: boolean;
  eligibility?: LenderEligibility;
  confirmation: {
    title: string;
    description: string;
  };
  symbol: string;
  applied?: boolean;
}

export interface LenderCategoryApplication {
  lenderCategory: LenderCategoryId;
  status: 'pending' | 'approved' | 'denied';
}

export const ProviderIDs = {
  fundingCircle: 'funding circle', // BL
  marketFinance: 'Market Finance', // BL //IF
  iwoca: 'iwoca', // BL
  fleximize: 'Fleximize', // BL
  youlend: 'YouLend', // RV
  forwardAdvances: 'Forward Advances', // RV
  uncapped: 'Uncapped', // RV
  outfund: 'Outfund', // RV
  seneca: 'Seneca Trade Partners', // SF
  catalyst: 'Catalyst', // IF
  paragon: 'Paragon Bank', // IF
  justCashflow: 'Just Cash Flow', // CL
} as const;

export const LenderCategoryCriteria = {
  age3months: 'age3months', // 0
  age6months: 'age6months', // 1
  age12months: 'age12months', // 2
  revenue20k: 'revenue20k', // 3
  monthlyRevenue5k: 'monthlyRevenue5k', // 4
  monthlyRevenue4k: 'monthlyRevenue4k', // 5
  monthlyRevenue3k: 'monthlyRevenue3k', // 6
  monthlyRevenue2k: 'monthlyRevenue2k', // 7
  monthlyRevenue500: 'monthlyRevenue500', // 8
  b2bSales5: 'b2bSales5', // 9
} as const;
