import React, { FC, useState } from 'react';

import { Box, Grid, InputAdornment, Typography } from '@material-ui/core';
import { Form, Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Company } from 'store/dashboard/actions';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import InputSlider from 'components/common/Slider';
import UserAlert, { Status, UserAlertProps } from 'components/common/UserAlert';
import NumberFormatCustom from 'components/common/NumberFormatCustom';
import ToolTipTextField from 'components/common/ToolTipTextField';
import { useOnboardLoadingAndErrorState } from 'store/dashboard/selectors';
import FormBase from './FormBase';
import useOnboardingStyles from './form.styles';
import InputLabelComponent from 'components/common/ToolTipInputLabel';
import SubmitButtons from './SubmitButtons';
import { noop } from 'utils';
import ShopifyLogo from 'assets/partners/shopify-logo.png';
import AmazonLogo from 'assets/partners/amazon-logo.png';
import WooCommerceLogo from 'assets/partners/woocommerce-logo.png';
import EbayLogo from 'assets/partners/ebay-logo.png';

const sliderMax = 100;

interface BusinessPerformanceFormProps {
  formValues: Partial<Company>;
  onClickPrimary?: () => void;
  onClickSecondary?: () => void;
  primaryButtonTitle?: string;
  finalStep?: boolean;
}

const BusinessPerformanceForm: FC<BusinessPerformanceFormProps> = ({
  formValues,
  onClickPrimary = noop,
  onClickSecondary,
  primaryButtonTitle,
  finalStep = false,
}) => {
  const [openAlert, setOpenAlert] = React.useState<UserAlertProps>({
    open: false,
    message: '',
    status: Status.ERROR,
  });

  const [state, setState] = useState({
    shopify: !!formValues.shopifySalesPercentage,
    amazon: !!formValues.amazonSalesPercentage,
    ebay: !!formValues.ebaySalesPercentage,
    wooCommerce: !!formValues.wooCommerceSalesPercentage,
    offline: !!formValues.offlineSalesPercentage,
    other: !!formValues.otherSalesPercentage,
  });

  const salesPlatformArray = ['shopify', 'amazon', 'ebay', 'wooCommerce', 'offline', 'other'];

  const [salesPlatform, setSalesPlatform] = useState({
    shopify: (formValues.shopifySalesPercentage || 0) * 100,
    amazon: (formValues.amazonSalesPercentage || 0) * 100,
    ebay: (formValues.ebaySalesPercentage || 0) * 100,
    wooCommerce: (formValues.wooCommerceSalesPercentage || 0) * 100,
    offline: (formValues.offlineSalesPercentage || 0) * 100,
    other: (formValues.otherSalesPercentage || 0) * 100,
  });

  const total = (salesPlatform: {
    shopify: number;
    amazon: number;
    ebay: number;
    wooCommerce: number;
    offline: number;
    other: number;
  }) => Object.values(salesPlatform).reduce((a, b) => a + b);

  const { t } = useTranslation();
  const classes = useOnboardingStyles();
  const onBoardingSchema = yup.object({
    monthlyRevenue: yup
      .number()
      .required(t('pages.onBoarding.monthlyRevenueValidate'))
      .min(0, t('pages.onBoarding.monthlyRevenueValidate'))
      .typeError(t('pages.onBoarding.monthlyRevenueValidate'))
      .nullable(),
    yearToDateRevenue: yup
      .number()
      .min(0, t('pages.onBoarding.revenueThisYearValidate'))
      .required(t('pages.onBoarding.revenueThisYearValidate'))
      .typeError(t('pages.onBoarding.revenueThisYearValidate'))
      .nullable(),
    grossMarginPercentage: yup
      .number()
      .min(0, t('pages.onBoarding.grossMarginValidate'))
      .required(t('pages.onBoarding.grossMarginValidate'))
      .max(100, t('pages.onBoarding.maxValidate'))
      .typeError(t('pages.onBoarding.grossMarginValidate'))
      .nullable(),
    cashBalance: yup
      .number()
      .required(t('pages.onBoarding.cashBalanceValidate'))
      .min(0, t('pages.onBoarding.cashBalanceValidate'))
      .typeError(t('pages.onBoarding.cashBalanceValidate'))
      .nullable(),
  });

  const { shopify, amazon, ebay, wooCommerce, offline, other } = state;

  const { loading, patchCompany } = useOnboardLoadingAndErrorState(setOpenAlert, onClickPrimary);

  const { open, message, status } = openAlert;

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert({ open: false });
  };

  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const sliderChange = (value: number | number[], name: string) => {
    setSalesPlatform({ ...salesPlatform, [name]: value });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSalesPlatform({
      ...salesPlatform,
      [event.target.name]: event.target.value === '' ? '' : Number(event.target.value),
    });
  };

  const initialValues = {
    monthlyRevenue: formValues.monthlyRevenue,
    yearToDateRevenue: formValues.yearToDateRevenue,
    grossMarginPercentage: (formValues.grossMarginPercentage || 0) * 100 || 0,
    cashBalance: formValues.cashBalance || '',
    step: 5,
  };

  const onSubmit = (value: typeof initialValues) => {
    patchCompany(
      {
        ...value,
        monthlyRevenue: Number(value.monthlyRevenue),
        yearToDateRevenue: Number(value.yearToDateRevenue),
        grossMarginPercentage: (value.grossMarginPercentage as number) / 100,
        cashBalance: Number(value.cashBalance),
        shopifySalesPercentage: salesPlatform.shopify / 100,
        amazonSalesPercentage: salesPlatform.amazon / 100,
        ebaySalesPercentage: salesPlatform.ebay / 100,
        wooCommerceSalesPercentage: salesPlatform.wooCommerce / 100,
        offlineSalesPercentage: salesPlatform.offline / 100,
        otherSalesPercentage: salesPlatform.other / 100,
      },
      finalStep,
    );
  };

  return (
    <FormBase>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={onBoardingSchema}>
        {({ handleChange, values, errors, touched, setFieldValue }) => (
          <Form noValidate>
            <Grid container item direction="column" spacing={4} className={classes.formGridContainer}>
              <Grid item>
                <Field
                  autoFocus
                  fullWidth
                  id="monthlyRevenue"
                  name="monthlyRevenue"
                  value={values.monthlyRevenue}
                  component={ToolTipTextField}
                  onChange={handleChange}
                  title={t('pages.onBoarding.monthlyRevenueLabel')}
                  toolTipMessage={t('pages.onBoarding.monthlyRevenueLabelToolTip')}
                  error={touched.monthlyRevenue && !!errors.monthlyRevenue}
                  helperText={touched.monthlyRevenue && errors.monthlyRevenue}
                  margin="normal"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    inputComponent: NumberFormatCustom as any,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="35000"
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <Field
                  component={ToolTipTextField}
                  fullWidth
                  id="yearToDateRevenue"
                  name="yearToDateRevenue"
                  value={values.yearToDateRevenue}
                  onChange={handleChange}
                  toolTipMessage={t('pages.onBoarding.revenueThisYearToolTip')}
                  title={t('pages.onBoarding.revenueThisYear')}
                  error={touched.yearToDateRevenue && !!errors.yearToDateRevenue}
                  helperText={touched.yearToDateRevenue && errors.yearToDateRevenue}
                  margin="normal"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    inputComponent: NumberFormatCustom as any,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="200000"
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <InputLabelComponent
                  htmlFor="grossMarginPercentage"
                  label={t('pages.onBoarding.grossMargin')}
                  id="salesPlatformLabel"
                  message={t('pages.onBoarding.grossMarginToolTip')}
                />

                <Box component="fieldset" className={classes.formControl} style={{ height: '100%' }}>
                  <FormGroup style={{ height: '100%' }}>
                    <InputSlider
                      max={100}
                      res={0}
                      value={{ grossMarginPercentage: values.grossMarginPercentage }}
                      inputChange={(e) => setFieldValue('grossMarginPercentage', Number(e.target.value))}
                      slideChange={(v) => setFieldValue('grossMarginPercentage', v)}
                      name="grossMarginPercentage"
                      state={{ grossMarginPercentage: true }}
                      step={1}
                    />
                  </FormGroup>
                </Box>

                {/* {touched.grossMarginPercentage && !!errors.grossMarginPercentage} */}
              </Grid>

              <Grid item>
                <Field
                  component={ToolTipTextField}
                  fullWidth
                  id="cashBalance"
                  name="cashBalance"
                  value={values.cashBalance}
                  onChange={handleChange}
                  toolTipMessage={t('pages.onBoarding.cashBalanceToolTip')}
                  title={t('pages.onBoarding.cashBalance')}
                  error={touched.cashBalance && !!errors.cashBalance}
                  helperText={touched.cashBalance && errors.cashBalance}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    inputComponent: NumberFormatCustom as any,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="4000"
                  className={classes.textInput}
                  margin="normal"
                />
              </Grid>

              <Grid item>
                <InputLabelComponent
                  htmlFor="salesPlatform"
                  label={t('pages.onBoarding.salesPlatformLabel')}
                  id="salesPlatformLabel"
                  message={t('pages.onBoarding.salesPlatformLabelToolTip')}
                />
                <Box width="100%" display="flex">
                  <FormControl component="fieldset" className={classes.formControl}>
                    <p style={{ textAlign: 'left' }}>{t('pages.onBoarding.salesPlatformTitle')}</p>
                    <FormGroup>
                      <FormControlLabel
                        className={classes.formControlLabel}
                        control={<Checkbox checked={shopify} onChange={handleChanges} name="shopify" />}
                        label={
                          <img src={ShopifyLogo} alt={t('pages.onBoarding.shopify')} className={classes.ecomLogos} />
                        }
                      />
                      <FormControlLabel
                        className={classes.formControlLabel}
                        control={<Checkbox checked={amazon} onChange={handleChanges} name="amazon" />}
                        label={
                          <img src={AmazonLogo} alt={t('pages.onBoarding.amazon')} className={classes.ecomLogos} />
                        }
                      />
                      <FormControlLabel
                        className={classes.formControlLabel}
                        control={<Checkbox checked={ebay} onChange={handleChanges} name="ebay" />}
                        label={<img src={EbayLogo} alt={t('pages.onBoarding.ebay')} className={classes.ecomLogos} />}
                      />
                      <FormControlLabel
                        className={classes.formControlLabel}
                        control={<Checkbox checked={wooCommerce} onChange={handleChanges} name="wooCommerce" />}
                        label={
                          <img
                            src={WooCommerceLogo}
                            alt={t('pages.onBoarding.wooCommerce')}
                            className={classes.ecomLogos}
                          />
                        }
                      />
                      <FormControlLabel
                        className={classes.formControlLabel}
                        control={<Checkbox checked={offline} onChange={handleChanges} name="offline" />}
                        label={t('pages.onBoarding.offline')}
                      />
                      <FormControlLabel
                        className={classes.formControlLabel}
                        control={<Checkbox checked={other} onChange={handleChanges} name="other" />}
                        label={t('pages.onBoarding.other')}
                      />
                    </FormGroup>
                  </FormControl>
                  <Box component="fieldset" className={classes.formControl} style={{ height: '100%' }}>
                    <p>{t('pages.onBoarding.salesPercentageTitle')}</p>
                    <FormGroup style={{ height: '100%' }}>
                      {salesPlatformArray.map((res, index) => (
                        <InputSlider
                          key={res}
                          max={total(salesPlatform)}
                          res={index}
                          value={salesPlatform}
                          inputChange={handleInputChange}
                          slideChange={sliderChange}
                          name={res}
                          state={state}
                        />
                      ))}
                    </FormGroup>
                  </Box>
                </Box>

                {total(salesPlatform) > sliderMax ? (
                  <Typography color="error" display="block" variant="caption" align="left">
                    {t('pages.onBoarding.sliderError')}
                  </Typography>
                ) : (
                  ''
                )}
              </Grid>

              <SubmitButtons
                onClickSecondary={onClickSecondary}
                loading={loading}
                primaryButtonTitle={primaryButtonTitle}
              />
            </Grid>
          </Form>
        )}
      </Formik>

      <UserAlert open={open} status={status} message={message} handleClose={handleClose} />
    </FormBase>
  );
};

export default BusinessPerformanceForm;
