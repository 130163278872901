import { List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import { HighlightOff as RejectedFileIcon } from '@material-ui/icons';
import React from 'react';
import { FileRejection } from 'react-dropzone';

interface DocumentsListProps {
  rejectedFiles: FileRejection[];
}

const DocumentsList: React.FC<DocumentsListProps> = ({ rejectedFiles }) => (
  <List>
    {rejectedFiles.map((res: FileRejection) => {
      const { file } = res;
      return (
        <ListItem key={res.file.name}>
          <ListItemText primary={file.name} />
          <ListItemSecondaryAction>
            <ListItemAvatar>
              <RejectedFileIcon color="error" />
            </ListItemAvatar>
          </ListItemSecondaryAction>
        </ListItem>
      );
    })}
  </List>
);

export default React.memo(DocumentsList);
